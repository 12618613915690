import {createActions, handleActions} from 'redux-actions';

const {
    getConfig,
    getConfigSuccess,
    getConfigFail,

} = createActions(
    'GET_CONFIG',
    'GET_CONFIG_SUCCESS',
    'GET_CONFIG_FAIL',
);

const defaultState = {
    item: null,
    errors: [],
    fetching: false
};

export const reducer = handleActions(
    {
        [getConfig]: state => ({
            ...state,
            errors: [],
        }),
        [getConfigSuccess]: (state, action) => ({
            ...state,
            item: action.payload,
        }),
        [getConfigFail]: (state, action) => ({
            ...state,
            errors: [action.payload],
        }),
    },
    defaultState
);

export const actions = {
    getConfig,
    getConfigSuccess,
    getConfigFail,
};
