import {createActions, handleActions} from 'redux-actions';

const {
    getEnabledDoctors,
    getEnabledDoctorsSuccess,
    getEnabledDoctorsFail,

} = createActions(
    'GET_ENABLED_DOCTORS',
    'GET_ENABLED_DOCTORS_SUCCESS',
    'GET_ENABLED_DOCTORS_FAIL',
);

const defaultState = {
    items: null,
    errors: [],
};

export const reducer = handleActions(
    {
        [getEnabledDoctors]: state => ({
            ...state,
            errors: [],
            items: null
        }),
        [getEnabledDoctorsSuccess]: (state, action) => ({
            ...state,
            items: action.payload,
        }),
        [getEnabledDoctorsFail]: (state, action) => ({
            ...state,
            items: null,
            errors: [action.payload],
        }),
    },
    defaultState
);

export const actions = {
    getEnabledDoctors,
    getEnabledDoctorsSuccess,
    getEnabledDoctorsFail,
};
