import React, {useEffect, useState} from 'react'
import MomentUtils from '@date-io/moment'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'

import {DatePicker} from "@material-ui/pickers"
import {Box, CircularProgress} from "@material-ui/core"
import {makeStyles} from "@material-ui/core/styles"
import moment from "moment"
import {Button, Typography} from "@material-ui/core"
import {EmptyError} from "../errors"

import {actions as scheduleActions} from "../../modules/schedule/duck"
import {useDispatch, useSelector} from "react-redux";
import {selectors as scheduleSelectors} from "../../modules/schedule/duck";
import {selectors as applicationSelectors} from "../../modules/application/duck";
import {actions as scheduleMapActions} from "../../modules/schedule-map/duck";
import {selectors as scheduleMapSelectors} from "../../modules/schedule-map/duck";

const useStyles = makeStyles((theme) => ({
    buttonsContainer: {
        paddingLeft: '80px',
        overflow: 'hidden'
    },
    calendarContainer: {
        float: 'left',
        width: '320px',
        borderRight: '1px solid #cdcdcd',
        paddingRight: '60px',

        '& .MuiPickersDay-daySelected:hover': {
            backgroundColor: theme.palette.primary.main + '!important'
        }
    },
    clear: {
        clear: 'both'
    },
    button: {
        margin: theme.spacing(0.5),
        background: theme.palette.background.paper,
        minWidth: '100px;'
    },
    selected: {
        margin: theme.spacing(0.5),
        backgroundColor: theme.palette.primary.main + '!important',
        color: theme.palette.primary.contrastText,
        minWidth: '100px;'
    }
}));

const DateTimeSchedule = ({disabled, id, onChange}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const service = useSelector(applicationSelectors.selectedService)
    const state = useSelector(applicationSelectors.state)
    const servicePlan = useSelector(applicationSelectors.selectedServicePlan)
    const schedule = useSelector(scheduleSelectors.schedule);
    const availableDays = useSelector(scheduleMapSelectors.availableDays);
    const to = useSelector(scheduleMapSelectors.to);
    const leadId = useSelector(applicationSelectors.leadId);

    const [date, setDate] = useState(moment());
    const [time, setTime] = useState();
    const [minDate, setMinDate] = useState();
    const [maxDate, setMaxDate] = useState();
    const [loadingSchedule, setLoadingSchedule] = useState(false);
    const [empty, setEmpty] = useState(undefined);
    const [scheduleTimes, setScheduleTimes] = useState([]);
    const [errorTitle, setErrorTitle] = useState('');
    const errorMessage = `Please feel free to contact our Care team via chat, email or phone in case you have
        additional questions or need more clarifications. Have a good one!`;

    useEffect(() => {
        loadScheduleMap();

        return () => {
            setScheduleTimes([]);
            dispatch(scheduleActions.scheduleReset());
            dispatch(scheduleMapActions.scheduleMapReset());
        };
    }, []);

    useEffect(() => {
        if (availableDays) {
            let scheduleDate;
            if (availableDays[0]) {
                scheduleDate = moment(availableDays[0]);
                setEmpty(false);
                setMinDate(moment(scheduleDate).startOf('day'));
            } else {
                setErrorTitle('There are no slots available for the next three month.')
                setEmpty(true);
            };

            setDate(scheduleDate);

            loadSchedule(scheduleDate, 'day');
        };
    }, [availableDays]);

    useEffect(() => {
        if (to) {
            setMaxDate(moment(to));
        };
    }, [to]);

    useEffect(() => {
        if (!schedule) {
            return;
        }
        setLoadingSchedule(false);
        if (schedule.length) {
            setScheduleTimes(schedule);
            setEmpty(false);
            return;
        }
        setScheduleTimes([]);
        setEmpty(true);
        setErrorTitle('There are no slots available on the selected day. Please choose another day');
    }, [schedule]);

    const loadScheduleMap = () => {
        setLoadingSchedule(true);

        dispatch(scheduleMapActions.getScheduleMap({
            serviceCode: service.code,
            stateCode: state.toLowerCase(),
            servicePlanId: servicePlan.id,
            leadId,
        }));
    };

    const loadSchedule = (scheduleDate, expand = 'day') => {
        if (!scheduleDate) {
            setLoadingSchedule(false);
            return;
        }
        setLoadingSchedule(true);
        dispatch(scheduleActions.getSchedule(
            {
                serviceCode: service.code,
                stateCode: state.toLowerCase(),
                servicePlanId: servicePlan.id,
                expandDay: moment(scheduleDate).startOf(expand).format(),
                leadId,
            }
        ));
    };

    const handleDayChange = (day) => {
        const dateToChange = moment(day);
        if (dateToChange.isSameOrAfter(minDate, 'day') && !dateToChange.isSame(date, 'day')) {
            setDate(dateToChange);
            loadSchedule(dateToChange, 'day');
        }
    }

    const handleSelect = (value) => {
        setLoadingSchedule(true);
        setTime(value);
        trackStep();
        onChange(id, value);
    };

    const trackStep = () => {
        /*global analytics, gtag*/
        if (typeof analytics !== 'undefined' && typeof gtag !== 'undefined' && process.env.REACT_APP_ENV === 'production') {

            analytics.track('Datetime selected');
            gtag('event', 'Datetime_selected', {'event_category': 'Datetime_selected'});
        }
    }

    const timeButtons = scheduleTimes ? scheduleTimes.map((item, index) => (
        <Button
            className={time === item ? classes.selected : classes.button}
            color={time === item.value ? 'primary' : 'default'}
            disabled={moment(item).isBefore(moment.now())} variant="outlined" key={index} onClick={() => handleSelect(item)}>
            {moment(item).format('hh:mm A')}
        </Button>
    )) : null;

    return (
        <Box>
            <Box className={classes.calendarContainer}>

                <Typography variant={'subtitle1'}>Preferred date: </Typography>
                {loadingSchedule && <div style={{textAlign: 'center'}}>
                    <br/><br/><CircularProgress />
                </div>}
                {!loadingSchedule && <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        autoOk
                        value={date}
                        minDate={minDate}
                        maxDate={maxDate}
                        orientation="landscape"
                        variant="static"
                        openTo="date"
                        disabled={disabled}
                        disablePast
                        disableToolbar
                        shouldDisableDate={(day) => {
                            if (loadingSchedule || !availableDays || !availableDays.length) return true;
                            if (availableDays.find((d) => moment(d).isSame(day, 'day'))) return false;
                            else return true;
                        }}
                        onChange={handleDayChange}
                    />
                </MuiPickersUtilsProvider>}
            </Box>
            <Box className={classes.buttonsContainer}>
                <Typography variant={'subtitle1'}>Preferred time: </Typography>
                {loadingSchedule && <div style={{textAlign: 'center'}}>
                    <br/><br/><CircularProgress />
                </div>}
                {!loadingSchedule && !empty && <Box>{timeButtons}</Box>}
                {!loadingSchedule && empty && <EmptyError errorTitle={errorTitle} errorMessage={errorMessage}/>}
            </Box>
            <Box className={classes.clear}></Box>
            <br/><br/>
            <Typography variant={'body2'}>
                A REPRESENTATIVE WILL CONTACT YOU SHORTLY DURING OUR NORMAL BUSINESS HOURS TO CONFIRM YOUR APPOINTMENT.
            </Typography>
        </Box>
    );
};


export default DateTimeSchedule;