import React, {useState} from 'react';

import {
    ButtonGroup,
    Button, Grid, Typography, Chip
} from '@material-ui/core';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    button: {
        display: 'inline-block',
        textAlign: 'left',
        textTransform: 'none',
        backgroundColor: theme.palette.background.paper
    },
    selected: {
        display: 'inline-block',
        textAlign: 'left',
        textTransform: 'none',
        borderBottomColor: 'inherit !important',
        backgroundColor: theme.palette.primary.main + '!important',
        color: theme.palette.primary.contrastText,
    },
    price: {
        float: 'right',
        marginTop: '5px'
    },
    heading: {
        marginTop: '5px'
    }
}));

const Radio = ({label, options, id, onChange, disabled}) => {
    const classes = useStyles();
    const [code, setCode] = useState();

    const handleSelect = (option) => {
        setCode(option.code);
        onChange(id, option);
    };

    return (
        <ButtonGroup
            orientation="vertical"
            color="default"
            disabled={disabled}
            aria-label="vertical outlined primary button group"
            fullWidth={true}>
            {options.map((option, index) => (
                option.price ?
                    <Button className={code === option.code ? classes.selected : classes.button}
                            color={code === option.code ? 'primary' : 'default'}
                            variant={"outlined"} key={index}
                            onClick={() => {handleSelect(option)}}>
                        <Grid container spacing={2}>
                            <Grid item xs={9}>
                                <Typography className={classes.heading} variant="body1" gutterBottom><b>{option.title}</b></Typography>
                                <Typography gutterBottom variant="body2">{option.description}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Chip className={classes.price} variant="outlined" color="primary" label={option.price} icon={<MonetizationOnIcon size="small" />}/>
                            </Grid>
                        </Grid>

                    </Button>
                    :
                    <Button className={code === option.code ? classes.selected : classes.button}
                            color={code === option.code ? 'primary' : 'default'}
                            variant={"outlined"} key={index}
                            onClick={() => {handleSelect(option)}}>
                        <Typography className={classes.heading} variant="body1" gutterBottom><b>{option.title}</b></Typography>
                        <Typography gutterBottom variant="body2">{option.description}</Typography>
                    </Button>

            ))}
        </ButtonGroup>
    );
};

Radio.propTypes = {};

export default Radio;