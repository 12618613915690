import React, {useEffect, useState} from 'react'
import MomentUtils from '@date-io/moment'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'

import {DatePicker} from "@material-ui/pickers"
import {Box, CircularProgress} from "@material-ui/core"
import {makeStyles} from "@material-ui/core/styles"
import moment from "moment"
import {Button} from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
    buttonsContainer: {
        paddingLeft: '80px',
        overflow: 'hidden'
    },
    calendarContainer: {
        float: 'left',
        width: '320px',
        borderRight: '1px solid #cdcdcd',
        paddingRight: '60px',
        lineHeight: 'normal',

        '& .MuiPickersDay-daySelected:hover': {
            backgroundColor: theme.palette.primary.main + '!important'
        }
    },
    clear: {
        clear: 'both'
    },
    button: {
        margin: theme.spacing(0.5),
        background: theme.palette.background.paper,
        minWidth: '100px;'
    },
    selected: {
        margin: theme.spacing(0.5),
        backgroundColor: theme.palette.primary.main + '!important',
        color: theme.palette.primary.contrastText,
        minWidth: '100px;'
    }
}));

const DateField = ({disabled, id, onChange, timeMin, timeMax}) => {
    const classes = useStyles();

    const [date, setDate] = useState(new Date());
    const [time, setTime] = useState();
    const [range, setRange] = useState([]);
    const [loadingTime, setLoadingTime] = useState(false);

    useEffect(() => {
        const opensAt = moment(date).set({hour: timeMin.split(':')[0], minute: timeMin.split(':')[1]});
        const closesAt = moment(date).set({hour: timeMax.split(':')[0], minute: timeMax.split(':')[1]});

        let timeArray = [];
        const now = moment();
        const iterator = opensAt;
        while (iterator <= closesAt) {
            if (iterator > now) timeArray.push({label: iterator.format('h:mm A'), value: iterator.format()});
            iterator.add(30, 'minutes');
        }
        setLoadingTime(true);
        setTimeout(() => {
            setRange(timeArray);
            setLoadingTime(false);
        }, 1000)

    }, [date]);


    const handleSelect = (value) => {
        setTime(value);
        onChange(id, value);
    };

    const timeButtons = range.map((item, index) => (
        <Button
            className={time === item.value ? classes.selected : classes.button}
            color={time === item.value ? 'primary' : 'default'}
            disabled={disabled} variant="outlined" key={index} onClick={() => {
            handleSelect(item.value)
        }}>
            {item.label}
        </Button>
    ));


    return (
        <Box>
            <Box className={classes.calendarContainer}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        autoOk
                        orientation="landscape"
                        variant="static"
                        openTo="date"
                        disabled={disabled}
                        disablePast
                        disableToolbar
                        value={date}

                        onChange={setDate}
                    />
                </MuiPickersUtilsProvider>
            </Box>
            <Box className={classes.buttonsContainer}>
                {loadingTime && <div style={{textAlign: 'center'}}>
                    <br/><br/><CircularProgress />
                </div>}
                {!loadingTime && <Box>{timeButtons}</Box>}
            </Box>
            <Box className={classes.clear}></Box>
        </Box>
    );
};


export default DateField;