import React, {useEffect, useState, useRef} from 'react'
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types'

import {Box} from '@material-ui/core'

import SquareForm from './square-form'

import {useSelector, useDispatch} from "react-redux"

import {selectors as applicationSelectors} from "../application/duck"

import {selectors as paymentSelectors} from "../payment/duck"
import {actions as paymentActions} from "../payment/duck"

import {CommonError, PaymentError} from "../../componets/errors"
import schema from "./square-form/schema"

const SquarePayment = ({onNext, onBack, id, step, name, promo, wallet}) => {
    const application = useSelector(applicationSelectors.applicationItem);
    const applicationId = useSelector(applicationSelectors.applicationId);
    const applicationErrors = useSelector(applicationSelectors.applicationErrors);

    const paymentErrors = useSelector(paymentSelectors.errors);
    const appliedCode = useSelector(paymentSelectors.code);

    const dispatch = useDispatch();

    const initialMount = useRef(true);
    const [updating, setUpdating] = useState(false);
    const [form, setForm] = useState(null);
    const [paymentForm, setPaymentForm] = useState(null);
    const [nonce, setNonce] = useState(null);
    const [squareErrors, setSquareErrors] = useState({});
    const squareErrorsRef = useRef(squareErrors);
    const setSquareErrorsWithRef = (object) => {
        squareErrorsRef.current = object;
        setSquareErrors(object);
    }

    const sqAppId = process.env['REACT_APP_SQUARE_APP_ID_' + wallet.toUpperCase()];
    const initPaymentForm = () => {
        /*global SqPaymentForm */
        const payForm = new SqPaymentForm({
            applicationId: sqAppId,
            inputClass: 'sq-input',
            inputStyles: [{
                fontSize: '16px',
                color: '#000',
                placeholderColor: '#a0a0a0',
                padding: '0px 0px',
                lineHeight: '20px',
                backgroundColor: 'transparent',
            }],
            cardNumber: {
                elementId: 'sq-card-number',
                placeholder: schema.cardNumber.placeholder
            },
            cvv: {
                elementId: 'sq-cvv',
                placeholder: schema.cvv.placeholder
            },
            expirationDate: {
                elementId: 'sq-expiration-date',
                placeholder: schema.expirationDate.placeholder
            },
            postalCode: {
                elementId: 'sq-postal-code',
                placeholder: schema.zip.placeholder
            },
            callbacks: {
                inputEventReceived: (inputEvent) => {
                    if (inputEvent.eventType === 'errorClassRemoved' && squareErrorsRef.current[inputEvent.field]) {
                        let object = Object.assign({}, squareErrorsRef.current);
                        delete object[inputEvent.field];
                        setSquareErrorsWithRef(object);
                    }
                },
                cardNonceResponseReceived: (errors, nonce, cardData) => {
                    if (nonce) {
                        setNonce(nonce);
                        setSquareErrors({});
                    } else if (errors) {
                        const object = {};
                        errors.forEach((item) => {
                            object[item.field] = item.message;
                        });
                        setSquareErrorsWithRef(object);
                        setUpdating(false);
                    }
                },
            }
        });

        setPaymentForm(payForm);
    }

    const checkForSquareForm = () => {
        if (!window.SqPaymentForm) {
            setTimeout(checkForSquareForm, 500);
        } else {
            initPaymentForm();
        }
    }

    useEffect(() => {

        return () => {
            dispatch(
                paymentActions.resetPaymentState()
            );
        };
    }, []);

    useEffect(() => {
        if (paymentForm) paymentForm.build();
    }, [paymentForm]);

    useEffect(() => {
        if (initialMount.current) {
            setTimeout(checkForSquareForm, 2000);
            initialMount.current = false;
        } else {
            setUpdating(false);
            onNext();
        }
    }, [application]);

    useEffect(() => {
        if (applicationErrors.length > 0 || paymentErrors.length > 0) {
            setUpdating(false);
            window.scrollTo(0, 0);
        }
    }, [applicationErrors, paymentErrors]);

    useEffect(() => {
        if (nonce) {
            setUpdating(true)
            dispatch(paymentActions.processPayment({
                paymentData: {
                    firstName: form.values.firstName.value,
                    lastName: form.values.lastName.value,
                    email: form.values.email.value,
                    phoneNumber: form.values.phoneNumber.value,
                },
                code: appliedCode ? appliedCode.code : null,
                applicationId: applicationId,
                nonce: nonce,
                wallet: wallet,
            }))
        }
    }, [nonce]);

    const handleSubmit = (submittedForm) => {
        paymentForm.requestCardNonce();
        setForm(submittedForm);
    };

    return (
        <Box>
            {applicationErrors.length > 0 && <CommonError/>}
            {paymentErrors.length > 0 && <PaymentError message={paymentErrors[0].message}/>}
            {application && application.plan &&
                        <SquareForm
                            promo={promo}
                            squareErrors={squareErrors}
                            name={name}
                            onBack={onBack}
                            onSubmit={handleSubmit}
                            step={step}
                            disabled={updating}
                            application={application}/>}

            {application && !application.plan && <Redirect to='/select-plan'/>}
        </Box>
    );
};

SquarePayment.propTypes = {
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
};

export default SquarePayment;