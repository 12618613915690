import Axios from "axios";

const api = Axios.create({
    baseURL: process.env.REACT_APP_API_URL + '/payment/',
});

const dispatchData = ({data}) => {
    return new Promise((resolve, reject) => {
        window.Accept.dispatchData(data, (response) => {
            if (response.messages.resultCode === 'Ok') resolve(response.opaqueData.dataValue);
            else reject(
                response?.messages?.message?.map((e) => ({
                    code: e.code,
                    message: e.text
                })) || 
                [{
                    code: 'internal_error',
                    message: 'Something went wrong',
                }]);
        });
    });
};

const createTransaction = async ({paymentData, applicationId, nonce, code, electronicSignatureInfo}) => {
    return await api.post('/transactions', {paymentData, applicationId, nonce, code, electronicSignatureInfo}, {
        validateStatus: (status) => {
            return (status === 200 || status === 400);
        }
    })
};

const processPayment = async ({paymentData, applicationId, nonce, wallet, code, electronicSignatureInfo}) => {
    return await api.post('/payments', {paymentData, applicationId, nonce, wallet, code, electronicSignatureInfo}, {
        validateStatus: (status) => {
            return (status === 200 || status === 400);
        }
    })
};

const checkCode = async ({ promoCode, applicationUuid, clientSecret }) => {
    return await api.post('/codes/check-promocode', { promoCode, applicationUuid, clientSecret });
};

const createIntent = async ({ applicationId, code }) => {
    return await api.post('/payments', { applicationId, code });
};

const skipPayment = async ({ leadId, code }) => {
    const promo = code ? `?promo=${code}` : ''
    return await api.get(`/pay-on-site/${leadId}${promo}`);
}

const confirmPayment = async ({stripe, elements}) => {
    return await stripe.confirmPayment({
        elements,
        confirmParams: {
            return_url: 'https://medvidi.com',
        },
        redirect: 'if_required'
    });
};

export const operations = {
    dispatchData,
    createTransaction,
    checkCode,
    processPayment,
    createIntent,
    confirmPayment,
    skipPayment
};
