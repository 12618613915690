export const LeadCreationType = {
    WEBSITE: 0,
    CALENDAR: 1,
    MOBILE: 2,
    FOLLOW_UP: 3,
    INCOMING_CALL: 4,
    SUBSCRIPTION: 5,
    USER_DASHBOARD: 6,
    BY_DOCTOR: 7
}
