import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {Button, CircularProgress, Grid, TextField} from '@material-ui/core';

import RedeemIcon from '@material-ui/icons/Redeem';
import CheckIcon from '@material-ui/icons/Check';

import {useDispatch, useSelector} from "react-redux";

import {actions as paymentActions, selectors as paymentSelectors} from "../duck";
import {selectors as applicationSelectors} from "../../application/duck";

const Promo = ({disabled, value, setValue}) => {
    const dispatch = useDispatch();
    const appliedCode = useSelector(paymentSelectors.code);
    const codeErrors = useSelector(paymentSelectors.codeErrors);
    const clientSecret = useSelector(paymentSelectors.clientSecret)

    const [promocode, setPromocode] = useState(localStorage.getItem('PROMOCODE'));
    const [isCodeApplied, setIsCodeApplied] = useState(false);
    const [checking, setChecking] = useState(false);
    const [error, setError] = useState(false);
    const errorMessage = 'Promo code is not found'
    const applicationUuid = useSelector(applicationSelectors.applicationId);

    useEffect(() => {
        setChecking(false);
        setError(false);
        setIsCodeApplied(appliedCode && (appliedCode.code === value))
    }, [appliedCode]);

    useEffect(() => {
        setChecking(false);
        if (codeErrors.length) setError(true);
    }, [codeErrors]);

    const handleCodeChange = ({ target }) => {
        setPromocode(target.value);
        setValue(target.value)
    };

    const handleCodeSubmit = () => {
        if (!value) return;

        setChecking(true);
        dispatch(paymentActions.checkCode({
            clientSecret: clientSecret ?? undefined,
            promoCode: promocode,
            applicationUuid: applicationUuid,
        }));
    };

    return (

        <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
                <TextField onChange={handleCodeChange}
                           value={promocode}
                           placeholder="Have promocode?"
                           fullWidth
                           id="code-input"
                           variant="outlined"
                           color="secondary"
                           disabled={disabled || isCodeApplied}
                           error={error}
                           helperText={error ? errorMessage : ''}
                           size="small"/>
            </Grid>
            <Grid item xs={12} sm={6}>
                {isCodeApplied ?
                    <Button fullWidth={true} onClick={handleCodeSubmit} disabled={true} color="secondary"
                            variant="contained" size="large">
                        <CheckIcon/>
                    </Button> :
                    <Button fullWidth={true} onClick={handleCodeSubmit} disabled={checking || disabled || promocode === ''}
                            color="secondary" variant="contained" size="large">
                        {checking ? <CircularProgress color="inherit" size={24}/> : <RedeemIcon/>}
                    </Button>
                }
            </Grid>
        </Grid>
    );
};

Promo.propTypes = {
    disabled: PropTypes.bool.isRequired
};

export default Promo;
