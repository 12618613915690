import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {
    FormControl,
    OutlinedInput,
    InputLabel,
    InputAdornment,
    FormHelperText,
} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import MaskedInput from 'react-text-mask';
import * as masks from '../../constants/masks';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '10px',

        '& .MuiInputLabel-outlined': {
            lineHeight: 1.4,
            fontSize: '1.2rem',
            position: 'relative',
            marginLeft: '-13px;',
        },

        '& .MuiFormHelperText-contained': {
            marginLeft: 0,
            marginTop: '5px'
        },

        '& .MuiFormLabel-root .MuiFormLabel-asterisk': {
            color: theme.palette.error.dark,
            fontSize: '1.2rem',
        },

        '& .MuiOutlinedInput-root': {
            marginTop: '-5px',
            background: theme.palette.background.paper,

            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.23)',
            },

            '&.Mui-error': {
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.error.main
                }
            },

            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.main,
            },
            '&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.error.main
            },
        },

    }
}));

const MaskedText = ({label, mask, id, required, errorMessage, onChange, disabled, placeholder, initialValue, adornment, onFocus,
                        hasIcon, iconPath }) => {
    const classes = useStyles();
    const getInitialValue = () => {
        if (!initialValue) {
            return '';
        }

        if (mask !== 'usPhone') {
            return initialValue;
        }

        if (typeof initialValue === 'string' && !initialValue.startsWith('+')) {
            return `+${initialValue}`;
        }

        return initialValue;
    }
    const [value, setValue] = useState(getInitialValue());

    const handleChange = (event) => {
        const value = event.target.value;
        const cleanedUpValue = value.replace("-", "" ).replace("(", "" ).replace(")", "" ).replace(/ /g, "");
        setValue(value);

        onChange(id, cleanedUpValue.trim());
    };

    const handleFieldFocus = () => {
        if (onFocus) {
            onFocus(id);
        }
    }

    const isRequired = required === "true";
    const isError = errorMessage !== '';
    const inputAdornment = adornment ? <InputAdornment position="start">{adornment}</InputAdornment> : false;

    return (
        <FormControl error={isError} variant="outlined" size="small" disabled={disabled} fullWidth classes={classes}>
            <InputLabel variant="outlined" shrink={true} required={isRequired} htmlFor={id}>{label}</InputLabel>
            <OutlinedInput
                id={id}
                startAdornment={inputAdornment}
                value={value}
                onChange={handleChange}
                onFocus={handleFieldFocus}
                inputComponent={TextMask}
                inputProps={{
                    mask:  masks[mask],
                    placeholder: placeholder,
                    guide: false
                }}
                endAdornment={
                    hasIcon ?
                    <InputAdornment position="end">
                        <img src={iconPath}/>
                    </InputAdornment> : null
                }
            />
            {isError && <FormHelperText>{errorMessage}</FormHelperText>}
        </FormControl>
    );
};

MaskedText.propTypes = {};

function TextMask(props) {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            placeholderChar={'\u2000'}
            showMask={true}
        />
    );
}

TextMask.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

export default MaskedText;