import {call, put, takeLatest, select} from 'redux-saga/effects';

import {operations} from './operations';
import {actions} from './modules';
import { selectors as paymentSelectors } from './selectors';
import { actions as applicationActions, selectors } from '../../application/duck';

function* watchApplicationsActions() {
    yield takeLatest(actions.dispatchData, dispatchData);
    yield takeLatest(actions.createTransaction, createTransaction);
    yield takeLatest(actions.checkCode, checkCode);
    yield takeLatest(actions.processPayment, processPayment);
    yield takeLatest(actions.createIntent, createIntent);
    yield takeLatest(actions.confirmPayment, confirmPayment);
    yield takeLatest(actions.skipPayment, skipPayment);
}

function* dispatchData({payload}) {
    const {data} = payload;

    try {
        const response = yield call(operations.dispatchData, {data});
        yield put(actions.dispatchDataSuccess(response));
    } catch (error) {
        yield put(actions.dispatchDataFail(error));
    }
}

function* createTransaction({payload}) {
    const {paymentData, applicationId, nonce, code} = payload;

    try {
        const response = yield call(operations.createTransaction, {paymentData, applicationId, nonce, code});
        if (response.data.data && response.data.data.transactionId) {
            yield put(actions.createTransactionSuccess(response.data.data.transactionId));
        } else {
            yield put(actions.createTransactionFail(response.data.errors));
        }
    } catch (error) {
        yield put(actions.createTransactionFail(error));
    }
}

function* processPayment({payload}) {
    const {paymentData, applicationId, nonce, wallet, code} = payload;

    try {
        const response = yield call(operations.processPayment, {paymentData, applicationId, nonce, wallet, code});
        if (response.data.data && response.data.data.payment) {
            const paymentId = response.data.data.payment.id;
            yield put(actions.processPaymentSuccess(paymentId));
        } else {
            yield put(actions.processPaymentFail(response.data.errors));
        }
    } catch (error) {
        yield put(actions.processPaymentFail(error));
    }
}

function* checkCode({payload}) {
    const { promoCode, applicationUuid, clientSecret } = payload;

    try {
        const response = yield call(operations.checkCode, {
            promoCode,
            applicationUuid,
            clientSecret,
        });
        yield put(actions.checkCodeSuccess(response.data.data));
    } catch (error) {
        yield put(actions.checkCodeFail(error));
    }
}

function* createIntent({payload}) {
    const { applicationId, code } = payload;

    try {
        const response = yield call(operations.createIntent, { applicationId, code });
        yield put(actions.createIntentSuccess(response.data.data.secret));
    } catch (error) {
        yield put(actions.createIntentFail(error));
    }
}

function* confirmPayment({payload}) {
    const { stripe, elements } = payload;
    try {
        const response = yield call(operations.confirmPayment, { stripe, elements });

        if (!response.error) {
            yield put(actions.confirmPaymentSuccess(response.paymentIntent));
        } else {
            yield put(actions.confirmPaymentFail([response.error]));
        }
    } catch (error) {
        console.log(error);
        yield put(actions.confirmPaymentFail(error));
    }
}

function* skipPayment({ payload }) {
    try {
        const application = yield select(selectors.applicationItem);
        const code = yield select(paymentSelectors.code);
        yield call(operations.skipPayment, {
            leadId: application.leadId,
            code: code?.code
        });
        yield put(applicationActions.updateApplication({
            id: application.refId,
            configId: application.configId,
            data: {
                value: {}
            },
            key: 'payment',
            step: -1
        }));
    } catch (error) {
        console.log(error);
        yield put(actions.dispatchDataFail(error));
    }
}

export const sagas = watchApplicationsActions;