import React, {useEffect, useState, useRef} from 'react'
import PropTypes from 'prop-types'
import {useSelector, useDispatch} from "react-redux"

import {Signature} from "../../componets/fields"
import {CommonError} from "../../componets/errors";
import {Box, Button, CircularProgress, Typography, Chip} from '@material-ui/core'

import * as rules from '../../constants/rules'
import * as messages from '../../constants/messages'

import useStyles from './styles'

import {selectors as applicationSelectors} from "../application/duck"
import {selectors as configSelectors} from "../config/duck"
import {actions as applicationActions} from "../application/duck"
import {actions as websiteActions} from "../website/duck"
import {selectors as websiteSelectors} from "../website/duck"

import schema from './schema'
import {createHashHistory} from 'history';

const history = createHashHistory({
    basename: '',
    hashType: 'noslash',
});

const Terms = ({onNext, onBack, id, step, name}) => {
    const classes = useStyles();

    const config = useSelector(configSelectors.configItem);
    const application = useSelector(applicationSelectors.applicationItem);
    const applicationId = useSelector(applicationSelectors.applicationId);
    const applicationErrors = useSelector(applicationSelectors.applicationErrors);
    const websiteTerms = useSelector(websiteSelectors.terms);
    const dispatch = useDispatch();

    const initialMount = useRef(true);
    const [updating, setUpdating] = useState(false);
    const [uploading, setUploading] = useState(false);

    const [form, setForm] = useState(null);
    const [errors, setErrors] = useState(null);
    const [initiallySubmitted, setInitiallySubmitted] = useState(false);
    const [valid, setValid] = useState(false);

    useEffect(() => {
        const nextStep = application  && application.nextStepId ? application.nextStepId : 0;
        if (nextStep < step) history.push(config.steps[nextStep].url);

        if (!websiteTerms) {
            dispatch(websiteActions.getWebsiteData({code: application.website.code}));
        }

        let initialValues = {};
        let initialErrors = {};
        Object.keys(schema).forEach((key, index) => {
            initialValues[key] = {value: schema[key].initialValue || "", name: schema[key].label, index};
            initialErrors[key] = "";
        });

        if (application && application.data[id]) {
            setForm(application.data[id]);
        } else {
            setForm({
                name: name, values: {
                    ...initialValues
                }
            });
        }

        setErrors(initialErrors);
    }, []);

    useEffect(() => {
        let err = {};
        let noErrors = true;

        if (form) {
            for (let key in schema) {
                if (schema[key].required === "true" &&
                    ((typeof form.values[key].value === 'string' && form.values[key].value.trim() === "") || form.values[key].value.length === 0)) {
                    err[key] = messages['required'];
                    noErrors = false;
                } else if (schema[key].rules && !rules[schema[key].rules].isValidSync(form.values[key].value)) {
                    noErrors = false;
                    err[key] = messages[schema[key].rules];
                } else {
                    err[key] = "";
                }
            }
            setValid(noErrors);
        }
        if (initiallySubmitted) {
            setErrors({...errors, ...err});
        }
    }, [form, initiallySubmitted]);

    useEffect(() => {
        if (initialMount.current) {
            initialMount.current = false;
        } else {
            setUpdating(false);
            onNext();
        }
    }, [application]);

    const handleSubmit = () => {
        if (!initiallySubmitted) setInitiallySubmitted(true);

        if (valid) {
            setUpdating(true);

            dispatch(applicationActions.updateApplicationData({
                id: applicationId,
                data: {
                    value: form
                },
                key: id,
                step: step
            }));
        }
    };

    const handleChange = (key, value) => {
        setForm({...form, values: {...form.values, [key]: {...form.values[key], value: value}}});
    };

    return (
        <Box>
            {applicationErrors.length > 0 && <CommonError/>}
            <div className={classes.terms} dangerouslySetInnerHTML={{__html: websiteTerms}}>
            </div>
            {form && <form>
                <Signature onChange={handleChange}
                           disableForm={() => {
                               setUploading(true)
                           }}
                           enableForm={() => {
                               setUploading(false)
                           }}
                           disabled={updating}
                           errorMessage={errors['signature']}
                           id="signature"
                           label={schema.signature.label}
                           placeholder={schema.signature.placeholder}
                           required={schema.signature.required}
                           initialValue={form.values.signature.value}/>

            </form>}
            <br/>
            {form?.values.signature.value && <Typography variant="body2">
                Uploaded file name:&nbsp;&nbsp;<Chip label={form.values.signature.value.split('/').slice(-1)} variant="outlined" />
            </Typography>}


            <Box className={classes.controls}>
                {step > 0 &&
                <Button disabled={updating || uploading} className={`${classes.button} ${classes.backButton}`}
                        variant="outlined"
                        onClick={onBack}>
                    Back
                </Button>}
                <Button disabled={updating || uploading} className={classes.button} variant="contained"
                        onClick={handleSubmit}
                        color="primary">
                    {updating || uploading ?
                        <>&nbsp;<CircularProgress color="inherit" size={16}/>&nbsp;&nbsp;Sending...</> :
                        <>Next</>}
                </Button>
            </Box>
        </Box>
    );
};

export default Terms;