import React, {useEffect} from 'react';
import {selectors as applicationSelectors} from "../application/duck";
import {selectors as configSelectors} from "../config/duck";
import {useSelector} from "react-redux";
import {Box, CircularProgress, Typography,} from "@material-ui/core";
import {useCookies} from "react-cookie";
import analytics from '../analytics';

const ThankYou = () => {
    const application = useSelector(applicationSelectors.applicationItem);
    const applicationId = useSelector(applicationSelectors.applicationId);
    const thankYouPage = useSelector(configSelectors.thankYouPage);
    const intakeUrl = useSelector(configSelectors.intakeUrl);
    const [cookies, setCookie, removeCookie] = useCookies(['dctrApplication', 'leadId', 'contactId']);

    useEffect(() => {
        /*global ga*/
        if (typeof ga !== 'undefined' && process.env.REACT_APP_ENV === 'production' && cookies.dctrApplication) {
            /**
             * getAll() function is some kind of a analytics plugin, so it can be not loaded yet when ga() function is ready.
             * To handle this we need to pass out function as a parameter of ga() function
             * https://developers.google.com/analytics/devguides/collection/analyticsjs/advanced#pushIt
             */

            ga(function() {
                const tracker = ga.getAll()[0].get('name');
                let arr = window.location.pathname.split('/');
                if (arr.length > 2) arr.pop();
                const str = arr.join('/');
                const url = str + window.location.hash;
                ga(tracker + '.send', 'pageview', url + window.location.hash);
            });
        }

        /*global fbq*/
        // https://app.asana.com/0/1203646198557494/1204594360516811
        // there is a conflict with API's events
        // if (typeof fbq !== 'undefined' && process.env.REACT_APP_ENV === 'production' && cookies.dctrApplication) {
        //     fbq('track', 'Purchase', {
        //             contents: [
        //                 {
        //                     id: application.plan.id,
        //                     quantity: 1
        //                 }
        //             ],
        //             content_name: application.service.name + ' - ' + application.plan.title,
        //             content_type: 'product',
        //             currency: 'USD',
        //             value: application.plan.price
        //         },
        //         {
        //             eventID: application.plan.id
        //         });
        // }

        /*global dataLayer*/

        if (['production', 'stage', 'stage3'].includes(process.env.REACT_APP_ENV) && cookies.dctrApplication) {
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
                'event': 'Transaction Complete',
                'transaction-revenue': application.plan.price,
                'transaction-id': applicationId,
                'transaction-product': `${application.service.name} - ${application.plan.title}`
            });

            dataLayer.push({
                'ecommerce': {
                    'currencyCode': 'USD',
                    'purchase': {
                        'actionField': {
                            'id': applicationId,
                            'affiliation': 'Online Store',
                            'tax':'0.00',
                            'shipping': '0.00',
                            'revenue': application.plan.price,
                        },
                        'products': [{
                            'name': `${application.service.name} - ${application.plan.title}`,
                            'id': application.plan.title,
                            'price': application.plan.price,
                            'quantity': 1
                        }]
                    }
                },
                'event': 'ee-event',
                'ee-event-category': 'Enhanced Ecommerce',
                'ee-event-action': 'Purchase',
                'ee-event-non-interaction': 'False'
            });
        }

        if (intakeUrl) {
            analytics.sendCustomEvent(
                'Legacy Form',
                `Step - Thank you`,
                'Open',
            );
        }

        /**
         * Cleanup application cookie
         */

        removeCookie('dctrApplication');
        removeCookie('leadId');
        removeCookie('contactId');

        setTimeout(() => {

            if (thankYouPage) {
                window.location.href = thankYouPage;
            } else if (intakeUrl) {
                window.location.href = intakeUrl + applicationId;
            }
        }, 1000);
    }, []);

    return (
        <Box>
            <br/>
            {(!thankYouPage && !intakeUrl) ?
                <Typography align="center" variant={"h4"}>Thank you!</Typography>
                 :
                <Box>
                    <div style={{textAlign: 'center'}}>
                        <br/><br/><br/><CircularProgress/>
                        <Box><br/><Typography variant="button">Please wait...</Typography></Box>
                    </div>
                </Box>
            }
        </Box>
    );
};

ThankYou.propTypes = {};


export default ThankYou;