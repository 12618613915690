import {createActions, handleActions} from 'redux-actions';

const {
    getServicePlans,
    getServicePlansSuccess,
    getServicePlansFail,

} = createActions(
    'GET_SERVICE_PLANS',
    'GET_SERVICE_PLANS_SUCCESS',
    'GET_SERVICE_PLANS_FAIL',
);

const defaultState = {
    items: null,
    errors: [],
};

export const reducer = handleActions(
    {
        [getServicePlans]: state => ({
            ...state,
            errors: [],
            items: null
        }),
        [getServicePlansSuccess]: (state, action) => ({
            ...state,
            items: action.payload,
        }),
        [getServicePlansFail]: (state, action) => ({
            ...state,
            items: [],
            errors: [action.payload],
        }),
    },
    defaultState
);

export const actions = {
    getServicePlans,
    getServicePlansSuccess,
    getServicePlansFail,
};
