import React from 'react'
import {Alert, AlertTitle} from '@material-ui/lab'

const EmptyError = ({ errorTitle, errorMessage }) => (
    <Alert severity="error">
        <AlertTitle>{errorTitle}</AlertTitle>
        {errorMessage}
    </Alert>
);

export default EmptyError
