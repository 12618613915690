import {createActions, handleActions} from 'redux-actions';

const {
    getScheduleMap,
    scheduleMapReset,
    getScheduleMapSuccess,
    getScheduleMapFail,
    scheduleMapResetSuccess,
} = createActions(
    'GET_SCHEDULE_MAP',
    'SCHEDULE_MAP_RESET',
    'GET_SCHEDULE_MAP_SUCCESS',
    'GET_SCHEDULE_MAP_FAIL',
    'SCHEDULE_MAP_RESET_SUCCESS',
);

const defaultState = {
    availableDays: null,
    from: null,
    to: null,
    errors: []
};

export const reducer = handleActions(
    {
        [getScheduleMap]: (state) => ({
            ...state,
            errors: []
        }),
        [scheduleMapReset]: (state) => ({
            ...state,
        }),
        [getScheduleMapSuccess]: (state, action) => ({
            ...state,
            availableDays: action.payload.availableDays,
            from: action.payload.from,
            to: action.payload.to,
        }),
        [getScheduleMapFail]: (state, action) => ({
            ...state,
            errors: [action.payload],
        }),
        [scheduleMapResetSuccess]: (state) => ({
            ...state,
            availableDays: null,
            from: null,
            to: null,
            errors: [],
        }),
    },
    defaultState
);

export const actions = {
    getScheduleMap,
    scheduleMapReset,
    getScheduleMapSuccess,
    getScheduleMapFail,
    scheduleMapResetSuccess,
};
