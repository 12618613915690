import React, {useEffect, useState, useRef} from 'react'
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types'

import {Box} from '@material-ui/core'

import AuthorizeForm from './authorize-form'

import {useSelector, useDispatch} from "react-redux"

import {selectors as applicationSelectors} from "../application/duck"

import {selectors as paymentSelectors} from "../payment/duck"
import {actions as paymentActions} from "../payment/duck"
import {useCookies} from 'react-cookie'

import {CommonError, PaymentError} from "../../componets/errors"

const AuthorizePayment = ({onNext, onBack, id, step, name, promo}) => {

    const application = useSelector(applicationSelectors.applicationItem);
    const applicationId = useSelector(applicationSelectors.applicationId);
    const applicationErrors = useSelector(applicationSelectors.applicationErrors);

    const nonce = useSelector(paymentSelectors.nonce);
    const paymentErrors = useSelector(paymentSelectors.errors);
    const appliedCode = useSelector(paymentSelectors.code);

    const dispatch = useDispatch();
    const initialMount = useRef(true);
    const [updating, setUpdating] = useState(false);
    const [blockButtonNext, setBlockButtonNext] = useState(false);
    const [paymentForm, setPaymentForm] = useState(null);
    const [cookies, setCookie, removeCookie] = useCookies(['dctrApplication']);

    useEffect(() => {
        return () => {
            dispatch(paymentActions.resetPaymentState());
        };
    }, []);

    useEffect(() => {
        if (initialMount.current) {
            initialMount.current = false;
        } else {
            removeCookie('dctrApplication');
            setUpdating(false);
            onNext();
        }
    }, [application]);

    useEffect(() => {
        if (applicationErrors.length > 0 || paymentErrors.length > 0) {
            setUpdating(false);
            setBlockButtonNext(true);
            window.scrollTo(0, 0);
        }
    }, [applicationErrors, paymentErrors]);

    useEffect(() => {
        if (nonce) {
            dispatch(paymentActions.createTransaction({
                paymentData: {
                    firstName: paymentForm.values.firstName.value,
                    lastName: paymentForm.values.lastName.value,
                    email: paymentForm.values.email.value,
                    phoneNumber: paymentForm.values.phoneNumber.value,
                },
                code: appliedCode ? appliedCode.code : null,
                applicationId: applicationId,
                nonce: nonce,
            }));
        }
    }, [nonce]);

    useEffect(() => {
        if (paymentForm) {
            dispatch(paymentActions.dispatchData({
                data: {
                    authData: {
                        clientKey: process.env.REACT_APP_AUTHORIZE_PUBLIC_CLIENT_KEY,
                        apiLoginID: process.env.REACT_APP_AUTHORIZE_API_LOGIN_KEY,
                    },
                    cardData: {
                        cardNumber: paymentForm.values.cardNumber.value.replace(/\s+/g, ''),
                        month: paymentForm.values.expirationDate.value.split('/')[0],
                        year: paymentForm.values.expirationDate.value.split('/')[1],
                        cardCode: paymentForm.values.cvv.value,
                        zip: paymentForm.values.zip.value,
                    }
                }

            }));
        }

    }, [paymentForm])

    const handleSubmit = (form) => {
        setUpdating(true);
        setPaymentForm({ ...form });
    };

    const changeForm = () => {
        if (blockButtonNext) {
            setBlockButtonNext(false);
        };
    };

    return (
        <Box>
            {applicationErrors.length > 0 && <CommonError/>}
            {paymentErrors.length > 0 && <PaymentError message={paymentErrors[0].message}/>}
            {application && application.plan &&
                <AuthorizeForm
                    promo={promo}
                    name={name}
                    onBack={onBack}
                    onSubmit={handleSubmit}
                    onChangeForm={changeForm}
                    step={step}
                    updating={updating}
                    blockButtonNext={blockButtonNext}
                    application={application}/>}

            {application && !application.plan && <Redirect to='/select-plan'/>}
        </Box>
    );
};

AuthorizePayment.propTypes = {
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
};

export default AuthorizePayment;
