import React from 'react'
import {Alert, AlertTitle} from '@material-ui/lab'

const CommonError = () => (
    <Alert severity="error">
        <AlertTitle>Error. </AlertTitle>
        You've already filled this form.
    </Alert>
);

export default CommonError
