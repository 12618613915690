import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const Timer = ({ start, milliseconds, onEnd }) => {
    const [end, setEnd] = useState(0);
    const [time, setTime] = useState(0);

    useEffect(() => {
        if (start && milliseconds) {
            setEnd(start + milliseconds)
        };
    }, [start, milliseconds]);

    useEffect(() => {
        if (end) {
            setTime(end - Date.now());
        };
    }, [end]);

    useEffect(() => {
        if (time > 0 && end) {
            setTimeout(() => setTime(end - Date.now()), 1000);
        };
    }, [time, end]);

    useEffect(() => {
        if (time <= 0 && onEnd && end) {
            onEnd();
        };
    }, [time, onEnd]);

    const geFormatNumber = (number) => {
        return number < 10 ? `0${number}` : number;
    };

    const getTime = () => {
        const duration = moment.duration((time > 0)
            ? time 
            : 0
        );
        let result = '';

        result += duration.days() ? duration.years() + 'y ' : '';
        result += duration.days() ? duration.months() + 'm ' : '';
        result += duration.days() ? duration.days() + 'd ' : '';
        result += duration.hours() ? geFormatNumber(duration.hours()) + ':' : '';
        result += duration.minutes() ? geFormatNumber(duration.minutes()) + ':' : '00:';
        result += duration.seconds() ? geFormatNumber(duration.seconds()) : '00';

        return result;
    };

    return (
        <span>{getTime()}</span>
    );
};

Timer.propTypes = {
    start: PropTypes.number.isRequired,
    milliseconds: PropTypes.number.isRequired,
    onEnd: PropTypes.func,
};

export default Timer;
