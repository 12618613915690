const schema = {
    firstName: {
        label: "First name",
        placeholder: "Please type your first name",
        required: "true",
    },
    lastName: {
        label: "Last name",
        placeholder: "Please type your last name",
        required: "true",
    },
    email: {
        label: "Email",
        placeholder: "Enter valid email address",
        required: "true",
        rules: "userEmail",
    },
    phoneNumber: {
        label: "Phone",
        placeholder: "Enter your phone number",
        mask: "usPhone",
        required: "true",
    },
    cardNumber: {
        label: "Card number",
        placeholder: "1234 1234 1234 1234",
        mask: "cardNumber",
        rules: "cardNumberWithoutSpace",
        required: "true",
    },
    expirationDate: {
        label: "Expiration date",
        placeholder: "MM / YY",
        mask: "expirationDate",
        rules: "expirationDate",
        required: "true",
    },
    cvv: {
        label: "CVC",
        placeholder: "CVC",
        mask: "cvv",
        rules: "cvv",
        required: "true",
    },
    zip: {
        label: "Postal code",
        placeholder: "Enter you zip code",
        required: "true",
        rules: "zip",
    }
};

export default schema;