import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    column: {
        display: 'flex',
        flexDirection: 'column', 
    },
    row: {
        display: 'flex',
        flexDirection: 'row', 
    },
    orderContainer: {
        border: '1px solid #3F51B5',
        borderRadius: '10px',

        '& p': {
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '20px',
            color: '#9E9E9E',
            padding: '14px 11px',
            margin: 0,
        },
        '& .serviceInfoContainer': {
            padding: '28px 14px',
            paddingTop: '17px',

            '& .point': {
                marginBottom: '20px',

                '& .iconContainer': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '16px',
                },
            },

            '& .point:last-child': {
                marginBottom: '0',
            },
        },
        '& .timerContainer': {
            padding: '20px 38px',
            background: '#FCE2C5',
            borderTop: '1px solid #3F51B5',
            color: '#821111',
            whiteSpace: 'nowrap',

            '& span': {
                marginLeft: '5px',
            },
        },
        '& .priceContainer': {
            display: 'flex',
            flexDirection: 'column',
            padding: '14px 11px',
            borderTop: '1px solid #3F51B5',

            '& b': {
                marginLeft: '5px',
                color: '#3F51B5',
            },
        },
    },
    bottomIconContainer: {
        flexWrap: 'wrap',
        marginTop: '10px',
        alignItems: 'center',

        '& img': {
            height: '50px',
        },

        '& :first-child': {
            marginRight: '5px',
        },

        '& :last-child': {
            marginLeft: '-5px',
        },
    },
}));

export default useStyles;
