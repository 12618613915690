import React, {useEffect, useState} from "react";

import { Box, Checkbox, Grid } from "@material-ui/core";

import {Text, MaskedText, Radio} from "../../../componets/fields";

import StepControls from '../../step-controls'

import schema from "./schema";
import analytics from '../../analytics';
import utils from '../../utils';

import * as messages from "../../../constants/messages";
import * as rules from "../../../constants/rules";

import {useCookies} from "react-cookie";
import DateSelect from "../../../componets/fields/DateSelect";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import TermsOfUse from '../../payment/termsOfUse';

const useStyles = makeStyles((theme) => ({
    termsContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
    },
    termsOfUse: {
        paddingLeft: '12px',
        marginBottom: '20px'
    },
    highlight: {
        color: '#70984A',
        fontWeight: '700'
    },
    termsMessage: {
        paddingTop: '9px',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '130%',
        letterSpacing: '0.15px',
        color: '#222222'
    },
    termsUrl: {
        fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
        color: theme.palette.primary.main,
    }
}));

const PersonalForm = ({ disabled, application, onBack, config, step, onSubmit, personalFormValidationErrors }) => {
    const classes = useStyles();
    const [values, setValues] = useState(null);
    const [errors, setErrors] = useState(null);
    const [valid, setValid] = useState(false);
    const [initiallySubmitted, setInitiallySubmitted] = useState(false);

    const agreement = application?.agreements?.find(elem => elem.type === 'receive_calls_and_messages');
    const [agreementStatus, setAgreementStatus] = useState(agreement?.status ?? false);
    const [termsOfUse, setTermsOfUse] = useState(false);

    const minDateOfBirth = moment().set('year', moment().year() - 100);
    const maxDateOfBirth = moment().set('year', moment().year() - 18);

    const [cookies, setCookie, removeCookie] = useCookies([
        '__utmzz', '_ga'
    ]);

    useEffect(() => {
        let initialValues = {};
        let initialErrors = {};
        Object.keys(schema).forEach((key, index) => {
            initialValues[key] = {value: schema[key].initialValue || "", name: schema[key].label, index};
            initialErrors[key] = "";
        });

        if (application && application.personalInfo) {
            setValues(application.personalInfo);
        } else {
            setValues(initialValues);
        }

        setErrors(initialErrors);
    }, []);

    useEffect(() => {
        let err = {};
        let noErrors = true;
        for (const prop in values) {
            if (prop === 'state') {
                continue;
            }
            if (schema[prop].required === "true" && (String(values[prop].value).trim() === "" || !values[prop].value)) {
                err[prop] = messages['required'];
                noErrors = false;
            } else if (schema[prop].rules && !rules[schema[prop].rules].isValidSync(values[prop].value)) {
                noErrors = false;
                err[prop] = messages[schema[prop].rules];
            } else if (values[prop].value === 'Invalid Date') {
                err[prop] = messages['invalidDate'];
                noErrors = false;
            } else {
                err[prop] = "";
            }
        }

        personalFormValidationErrors.forEach(error => {
            err[error.field] = messages[schema[error.field].rules];
        });
        setValid(noErrors);

        if (initiallySubmitted) {
            setErrors({...errors, ...err});
        }
    }, [values, initiallySubmitted, personalFormValidationErrors]);

    const handleSubmit = () => {
        const utmData = handleUtm();

        if (!initiallySubmitted) setInitiallySubmitted(true);

        analytics.sendCustomEvent('Legacy From Funnel Details', 'Click Next', 'Personal Info Screen');
        if (valid) {
            onSubmit(values, utmData, agreementStatus);
        } else {
            window.scrollTo(0, 0);
        }
    };

    const handleUtm = () => {
        const utmArray = cookies.__utmzz ? cookies.__utmzz.split('|') : null;
        const utmObject = {};
        if (utmArray) {
            for (let item of utmArray) {
                const itemArray = item.split('=');
                utmObject[itemArray[0]] = itemArray[1];
            }
        }
        const clientIdArray = cookies._ga ? cookies._ga.split('.') : null;
        return {
            utmSource: utmObject.utmcsr || null,
            utmMedium: utmObject.utmcmd || null,
            utmCampaign: utmObject.utmccn || null,
            utmContent: utmObject.utmcct || null,
            gClientId: clientIdArray ? clientIdArray[2] + '.' + clientIdArray[3] : null
        };
    }

    const handlePhoneFromBackend = (phone) => {
        return (String(phone).length > 10 && phone.startsWith('+1')) ? phone.substring(2) : phone;
    }

    const handleChange = (key, value) => {
        if (['gender', 'state'].includes(key)) {
            const field = utils.capitalizeFirstLetter(key);
            analytics.sendCustomEvent('Legacy From Funnel Details', `${field} selected`, 'Personal Info Screen');
        }
        setValues({...values, [key]: {...values[key], value: value}});
    };

    const handleFieldClick = (fieldName) => {
        analytics.sendCustomEvent('Legacy From Funnel Details', `Field Clicks: ${fieldName}`, 'Personal Info Screen');
    }

    return (
        <Box>
            {values && <form autoComplete="on" className="hf-form">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Text onChange={handleChange}
                              onFocus={handleFieldClick}
                              disabled={disabled}
                              errorMessage={errors['firstName']}
                              id="firstName"
                              name="firstName"
                              initialValue={values.firstName.value}
                              label={schema.firstName.label}
                              placeholder={schema.firstName.placeholder}
                              required={schema.firstName.required}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Text onChange={handleChange}
                              onFocus={handleFieldClick}
                              disabled={disabled}
                              errorMessage={errors['lastName']}
                              id="lastName"
                              name="lastName"
                              initialValue={values.lastName.value}
                              label={schema.lastName.label}
                              placeholder={schema.lastName.placeholder}
                              required={schema.lastName.required}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DateSelect
                            disabled={disabled}
                            id="dateOfBirth"
                            onChange={handleChange}
                            errorMessage={errors['dateOfBirth']}
                            initialValue={values.dateOfBirth.value}
                            label={schema.dateOfBirth.label}
                            placeholder={schema.dateOfBirth.placeholder}
                            required={schema.dateOfBirth.required}
                            format={schema.dateOfBirth.format}
                            minDate={minDateOfBirth}
                            maxDate={maxDateOfBirth}
                        ></DateSelect>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Text onChange={handleChange}
                              onFocus={handleFieldClick}
                              disabled={disabled}
                              errorMessage={errors['zip']}
                              id="zip"
                              name="zip"
                              initialValue={values.zip.value}
                              label={schema.zip.label}
                              placeholder={schema.zip.placeholder}
                              required={schema.zip.required}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Text onChange={handleChange}
                              onFocus={handleFieldClick}
                              disabled={disabled}
                              errorMessage={errors['email']}
                              id="email"
                              name="email"
                              initialValue={values.email.value}
                              label={schema.email.label}
                              placeholder={schema.email.placeholder}
                              required={schema.email.required}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MaskedText onChange={handleChange}
                                    onFocus={handleFieldClick}
                                    disabled={disabled}
                                    errorMessage={errors['phoneNumber']}
                                    mask={schema.phoneNumber.mask}
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    initialValue={handlePhoneFromBackend(values.phoneNumber?.value)}
                                    label={schema.phoneNumber.label}
                                    placeholder={schema.phoneNumber.placeholder}
                                    adornment="+1"
                                    required={schema.phoneNumber.required}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Radio onChange={handleChange}
                               disabled={disabled}
                               errorMessage={errors['gender']}
                               id="gender"
                               label={schema.gender.label}
                               options={schema.gender.options}
                               required={schema.gender.required}
                               initialValue={values.gender.value || schema.gender.initialValue}/>
                    </Grid>
                    <div className={classes.termsContainer}>
                        <Checkbox
                            color="primary"
                            checked={agreementStatus}
                            onChange={(event) => setAgreementStatus(event.target.checked)}
                        />
                        <div className={classes.termsMessage}>
                            <span className={classes.highlight}>Don't miss your appointment!</span> By clicking the checkbox, <span className={classes.highlight}>you agree to receive information
                            about your appointments</span> from
                            {config.website === 'ri-get-ezcare' ? ' EZCareClinic ' : ' MEDvidi '}
                            by text messages and calls to your phone number and
                            email listed above. You also agree to receive offers and promotions. Text STOP to opt out
                            or text HELP for help. Message frequency may vary. Message and data rates may apply.
                            Read our <a className={classes.termsUrl} href={config?.termsURLs?.termsUrl || '/'} target="_blank">Terms of Use</a> for more info.
                        </div>
                    </div>
                    <div className={classes.termsOfUse}>
                        <TermsOfUse config={config} value={termsOfUse} onChange={setTermsOfUse} />
                    </div>
                </Grid>
            </form>}

            <StepControls step={step} disabled={disabled} nextButtonDisabled={!termsOfUse} onBack={onBack} onNext={handleSubmit}/>
        </Box>
    );

}

export default PersonalForm;