import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {Box, Typography,} from "@material-ui/core";

import Form from "../form";
import Choice from "../choice";
import {PaymentWrapper} from "../payment/version-without-conditions";
import SquarePayment from "../payment/SquarePayment";
import StripePayment from "../payment/StripePayment";
import AuthorizePayment from "../payment/AuthorizePayment";
import ExtendServicePlanComponent from "../payment/extend-service-plan";
import Personal from "../personal";
import Terms from "../terms";
import Documents from "../id-document";
import MedicalRecords from "../medical-records";
import General from "../general";

import {actions as applicationActions, selectors as applicationSelectors} from "../application/duck";
import {selectors as paymentSelectors} from "../payment/duck";
import {selectors as configSelectors} from '../config/duck';
import customAnalytics from '../analytics';
import AgreementAndRelease from "../agreement-and-release";
import ControlledSubstances from "../controlled-substances";

const FormStep = ({step, index, onNext, onBack}) => {
    const dispatch = useDispatch();

    const intakeUrl = useSelector(configSelectors.intakeUrl);
    const application = useSelector(applicationSelectors.applicationItem);
    const applicationId = useSelector(applicationSelectors.applicationId);
    const configId = useSelector(applicationSelectors.configId);
    const transactionId = useSelector(paymentSelectors.transactionId);
    const paymentId = useSelector(paymentSelectors.paymentId);

    useEffect(() => {
        dispatch(applicationActions.setStep({index: index}));

        /*global ga*/
        if (typeof ga !== 'undefined') {
            ga(function() {
                const trackerName = ga.getAll()[0].get('name');
                const action = trackerName + '.send';
                let arr = window.location.pathname.split('/');
                if (arr.length > 2) arr.pop();
                const str = arr.join('/');
                const url = str + window.location.hash;
                ga(action, 'pageview', url);
            });
        }
    }, []);

    useEffect(() => {
        if (intakeUrl) {
            customAnalytics.sendCustomEvent(
                'Legacy Form',
                `Step - ${step.id}`,
                'Open',
            );
        };
    }, []);

    useEffect(() => {
        if (transactionId || paymentId) {
            // trackStep();
            dispatch(applicationActions.updateApplication({
                id: applicationId,
                configId: configId,
                data: {
                    value: {}
                },
                key: step.id,
                step: -1  // Last step
            }));
        };
    }, [transactionId, paymentId]);

    const trackStep = () => {
        /*global analytics, gtag*/
        if (typeof analytics !== 'undefined' && typeof gtag !== 'undefined' && process.env.REACT_APP_ENV === 'production') {
            analytics.track('Paid',
                {
                    Value: application.plan.price,
                    Service: application.service.name,
                    Plan: application.plan.title,
                    State: application.personalInfo.state.value
                }
            );
            gtag('event', 'Payment_selected', {'event_category': 'Payment_selected'});
        }
    };

    return (
        <Box>
            {(typeof step.displayHeader !== 'boolean' || step.displayHeader) &&
                <Typography
                    gutterBottom
                    variant={"h5"}
                >
                    {step.header}
                </Typography>
            }
            {step.description &&
                <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                >
                    {step.description}
                </Typography>
            }
            {step.type === 'form' && <Form onNext={onNext}
                                           onBack={onBack}
                                           fields={step.fields}
                                           id={step.id}
                                           name={step.header}
                                           step={index}/>}
            {step.type === 'choice' && <Choice onNext={onNext}
                                               onBack={onBack}
                                               field={step.field}
                                               id={step.id}
                                               step={index}/>}
            {step.type === 'personal' && <Personal onNext={onNext}
                                                   onBack={onBack}
                                                   id={step.id}
                                                   step={index}/>}
            {step.type === 'payment-authorize' && <AuthorizePayment onNext={onNext}
                                                          onBack={onBack}
                                                          id={step.id}
                                                          name={step.header}
                                                          promo={step.promo}
                                                          step={index}/>}
            {step.type === 'payment-square' && <SquarePayment onNext={onNext}
                                                       onBack={onBack}
                                                       id={step.id}
                                                       name={step.header}
                                                       promo={step.promo}
                                                       wallet={step.wallet || 'mango'}
                                                       step={index}/>}
            {step.type === 'payment-stripe' && <StripePayment onNext={onNext}
                                                              onBack={onBack}
                                                              id={step.id}
                                                              name={step.header}
                                                              promo={step.promo}
                                                             step={index}/>}
            {step.type === 'payment-without-conditions' &&
                <PaymentWrapper
                    onNext={onNext}
                    onBack={onBack}
                    id={step.id}
                    name={step.header}
                    promo={step.promo}
                    step={index}
                    providerData={step.providerData}
                />
            }
            {step.type === 'terms' && <Terms onNext={onNext}
                                             onBack={onBack}
                                             id={step.id}
                                             name={step.header}
                                             step={index}/>}
            {step.type === 'substances' && <ControlledSubstances onNext={onNext}
                                                                onBack={onBack}
                                                                id={step.id}
                                                                name={step.header}
                                                                step={index}/>}
            {step.type === 'acknowledgment' && <AgreementAndRelease onNext={onNext}
                                                                    onBack={onBack}
                                                                    id={step.id}
                                                                    name={step.header}
                                                                    step={index}/>}
            {step.type === 'idDocument' && <Documents onNext={onNext}
                                                      onBack={onBack}
                                                      id={step.id}
                                                      name={step.header}
                                                      step={index}/>}
            {step.type === 'medicalRecords' && <MedicalRecords onNext={onNext}
                                                               onBack={onBack}
                                                               id={step.id}
                                                               name={step.header}
                                                               step={index}/>}
            {step.type === 'medicalRecordsWithConfirmTerms' && <MedicalRecords onNext={onNext}
                                                               onBack={onBack}
                                                               id={step.id}
                                                               name={step.header}
                                                               step={index}
                                                               confirmTerms={true}/>}
            {step.type === 'general' && <General onNext={onNext}
                                                 onBack={onBack}
                                                 fields={step.fields}
                                                 id={step.id}
                                                 name={step.header}
                                                 step={index}/>}
            {step.type === 'extendServicePlan' &&
                <ExtendServicePlanComponent
                    onNext={onNext}
                    id={step.id}
                    name={step.header}
                    promo={step.promo}
                    step={index}
                    wallet={step.wallet || 'mango'}
                />
            }
        </Box>
    );
};

FormStep.propTypes = {};


export default FormStep;
