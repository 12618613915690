import React from 'react'
import {Alert, AlertTitle} from '@material-ui/lab'

const BadFileError = () => (
    <Alert severity="error">
        <AlertTitle>Error. </AlertTitle>
        File can't be uploaded, try the following ways to resolve the problem:
        Open the file locally and re-save it as a different file type (JPEG, PNG, PDF),
        then try to upload it again.
    </Alert>
);

export default BadFileError;