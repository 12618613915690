import {createActions, handleActions} from 'redux-actions';

const {
    getWebsiteData,
    getWebsiteDataSuccess,
    getWebsiteDataFail,
} = createActions(
    'GET_WEBSITE_DATA',
    'GET_WEBSITE_DATA_SUCCESS',
    'GET_WEBSITE_DATA_FAIL',
);

const defaultState = {
    terms: null,
    refundPolicy: null,
    errors: [],
};

export const reducer = handleActions(
    {
        [getWebsiteData]: state => ({
            ...state,
            errors: [],
        }),
        [getWebsiteDataSuccess]: (state, action) => ({
            ...state,
            terms: action.payload.terms,
            refundPolicy: action.payload.refundPolicy,
        }),
        [getWebsiteDataFail]: (state, action) => ({
            ...state,
            errors: [action.payload],
        }),
    },
    defaultState
);

export const actions = {
    getWebsiteData,
    getWebsiteDataSuccess,
    getWebsiteDataFail,
};
