import Axios from 'axios';

const api = Axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL + '/doctor/',
});

const getEnabledDoctors = async (planId) =>  {
  return await api.get(`/enabledDoctors/${planId ?? ''}`, {
      headers: {'dctrapp-api-privatetoken': process.env.REACT_APP_PUBLIC_API_TOKEN},
  });
};

export const operations = {
    getEnabledDoctors
};
