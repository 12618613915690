import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

import useStyles from './styles';

import {
    actions as paymentActions,
    selectors as paymentSelectors
} from '../../../payment/duck';
import { selectors as applicationSelectors } from '../../../application/duck';

const StripeFormWrapper = ({ submitClicks, onGetClientSecret, onDisabled, onPaymentProcessing }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const applicationId = useSelector(applicationSelectors.applicationId);
    const clientSecret = useSelector(paymentSelectors.clientSecret);

    const [stripePromise, setStripePromise] = useState(null);
    const [stripeOptions, setStripeOptions] = useState(null);

    // hook => load stripe-form, when the component is initialized
    useEffect(() => {
        const promise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
        setStripePromise(promise);
    }, []);

    // hook => create payment intent, when the component is initialized
    useEffect(() => {
        dispatch(paymentActions.createIntent({ applicationId }));
    }, [applicationId]);

    // hook => set options for payment form, after create payment intent
    useEffect(() => {
        if (clientSecret) {
            const options = {
                clientSecret: clientSecret,
                locale: 'en-GB',
                loader: 'always',
                appearance: {
                    theme: 'stripe',
                    variables: {
                        colorText: '#000000',
                        borderRadius: '10px',
                    }
                },
                fonts: [
                    {
                    family: 'Roboto',
                    weight: '400',
                }],
            };

            onGetClientSecret(clientSecret);
            setStripeOptions(options);
        }
    }, [clientSecret]);

    return (
        <div className={classes.container}>
            {stripePromise && stripeOptions && <Elements stripe={stripePromise} options={stripeOptions}>
                <StripeForm
                    submitClicks={submitClicks}
                    onDisabled={onDisabled}
                    onPaymentProcessing={onPaymentProcessing}
                />
            </Elements>}
            {(!stripePromise || !stripeOptions) && <CircularProgress color="inherit" size={60} style={{ margin: 'auto' }} />}
        </div>
    );
};

StripeFormWrapper.propTypes = {
    submitClicks: PropTypes.number.isRequired,
    onGetClientSecret: PropTypes.func.isRequired,
    onDisabled: PropTypes.func.isRequired,
    onPaymentProcessing: PropTypes.func.isRequired,
};

export default StripeFormWrapper;

const StripeForm = ({ submitClicks, onDisabled, onPaymentProcessing }) => {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();

    const [completeStripeForm, setCompleteStripeForm] = useState(false);

    // hook => change state in payment wrapper, after update stripe-form's data
    useEffect(() => {
        onDisabled(!completeStripeForm);
    }, [completeStripeForm]);

    // hook => payment request, after click "Next" in payment wrapper component
    useEffect(() => {
        if (submitClicks && completeStripeForm && stripe && elements) {
            onPaymentProcessing(true);
            dispatch(paymentActions.confirmPayment({ stripe, elements }));
        }
    }, [submitClicks]);

    const onChangeStripeForm = (event) => {
        setCompleteStripeForm(event?.complete || false);
    };

    return (
        <PaymentElement onChange={onChangeStripeForm} />
    );
};

StripeForm.propTypes = {
    submitClicks: PropTypes.number.isRequired,
    onDisabled: PropTypes.func.isRequired,
    onPaymentProcessing: PropTypes.func.isRequired,
};
