import React from 'react'
import {Alert, AlertTitle} from '@material-ui/lab'

const PaymentError = (message) => {
    console.log(message);
    return (
        <Alert severity="error">
            <AlertTitle>Payment error. </AlertTitle>
            {message.message}
        </Alert>
    );
};


export default PaymentError;
