import {call, put, takeLatest} from 'redux-saga/effects';

import {operations} from './operations';
import {actions} from './modules';

function* watch() {
    yield takeLatest(actions.uploadFile, uploadFile);
    yield takeLatest(actions.uploadSignatureFile, uploadSignatureFile);
}

function* uploadFile({payload}) {
    const {form} = payload;

    try {
        const response = yield call(operations.uploadFile, {form});
        yield put(actions.uploadFileSuccess(response));
    } catch (error) {
        const err = error.response ? {
            status: error.response.status,
            statusText: error.response.statusText,
            message: error.response.data.errors[0].message
        } : error;
        yield put(actions.uploadFileFail([err]));
    }
}

function* uploadSignatureFile({payload}) {
    const {form} = payload;

    try {
        const response = yield call(operations.uploadFile, {form});
        yield put(actions.uploadSignatureFileSuccess(response));
    } catch (error) {
        yield put(actions.uploadSignatureFileFail(error));
    }
}

export const sagas = watch;