const configItem = ({config}) => config.item;
const configErrors = ({config}) => config.errors;
const amoConsts = ({config}) => config.item.amoConsts;
const thankYouPage = ({config}) => config.item.thankYouPage;
const intakeUrl = ({config}) => config.item.intakeUrl;
const returningIntakeUrl = ({config}) => config.item.returningIntakeUrl;
const extendServicePlanPage = ({config}) => config.item.extendServicePlanPage;

export const selectors = {
    configItem,
    configErrors,
    amoConsts,
    thankYouPage,
    intakeUrl,
    returningIntakeUrl,
    extendServicePlanPage,
};
