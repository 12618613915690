import React, {useEffect, useState} from "react"

import {Box, Chip, Divider, Grid, Typography} from "@material-ui/core"
import {
    Text,
    MaskedText,
    Signature,
    File,
    Select,
    Radio,
    Checkbox,
} from "../../../componets/fields";
import StepControls from '../../step-controls'

import useStyles from './styles'

import * as messages from "../../../constants/messages"
import * as rules from "../../../constants/rules"
import { Alert } from '@material-ui/lab';

const FormForm = ({disabled, fields, application, onBack, step, onSubmit, name, id}) => {
    const classes = useStyles();
    const [form, setForm] = useState(null);
    const [errors, setErrors] = useState(null);
    const [initiallySubmitted, setInitiallySubmitted] = useState(false);
    const [valid, setValid] = useState(false);

    useEffect(() => {
        let initialValues = {};
        let initialErrors = {};

        fields.forEach((field, index) => {
            initialValues[field.id] = {value: field.initialValue || "", name: field.label, index: index};
            initialErrors[field.id] = "";
        });

        if (application && application.data[id]) {
            setForm(application.data[id]);
        } else {
            setForm({name: name, values: initialValues});
        }

        setErrors(initialErrors);
    }, []);


    useEffect(() => {
        let err = {};
        let noErrors = true;
        if (form) {
            for (const field of fields) {
                if (field.required === "true" &&
                    ((typeof form.values[field.id].value === 'string' && form.values[field.id].value.trim() === "") || form.values[field.id].value.length === 0)) {
                    err[field.id] = messages['required'];
                    noErrors = false;
                } else if (field.rules && !rules[field.rules].isValidSync(form.values[field.id].value)) {
                    noErrors = false;
                    err[field.id] = messages[field.rules];
                } else {
                    err[field.id] = "";
                }
            }
            setValid(noErrors);
        }
        if (initiallySubmitted) {
            setErrors({...errors, ...err});
        }
    }, [form, initiallySubmitted]);

    const handleSubmit = () => {
        if (!initiallySubmitted) setInitiallySubmitted(true);

        if (valid) {
            onSubmit(form);
        } else {
            window.scrollTo(0, 0);
        }
    };

    const handleChange = (key, value) => {
        setForm({...form, values: {...form.values, [key]: {...form.values[key], value: value}}});
    };

    const getNotificationOfAdderallShortage = () => {
        return (
            <Alert severity="info" style={{ marginBottom: '20px' }}>
                <span>Due to the shortage of Adderall in the United States, we do not guarantee its availability in
                    pharmacies. If you have been taking this medicine, consult your doctor to find an alternative medicine suitable for you.
                </span>
            </Alert>
        );
    }
    
    return (
        <Box>
            {form && <form>
                <Grid container spacing={3}>
                    {fields.map((field, index) => (
                        <Grid key={index} item xs={12}>
                            <Box>
                                {field.description &&
                                <Box>
                                    <br/>
                                    <Typography color="textSecondary" variant="body2">{field.description}</Typography>
                                </Box>}
                                {field.header &&
                                <Box>
                                    <Divider variant="fullWidth" className={classes.divider}/>
                                    <Typography gutterBottom color="textSecondary"
                                                variant="button">{field.header}</Typography>
                                </Box>}
                                {field.id === 'condition9' && getNotificationOfAdderallShortage()}
                                {field.type === 'signature' && <Signature onChange={handleChange}
                                                                          disabled={disabled}
                                                                          errorMessage={errors[field.id]}
                                                                          id={field.id}
                                                                          label={field.label}
                                                                          initialValue={form.values[field.id].value}
                                                                          placeholder={field.placeholder}
                                                                          required={field.required}/>}
                                {field.type === 'file' && <File onChange={handleChange}
                                                                disabled={disabled}
                                                                errorMessage={errors[field.id]}
                                                                id={field.id}
                                                                label={field.label}
                                                                disableForm={() => {}}
                                                                enableForm={() => {}}
                                                                initialValue={form.values[field.id].value}
                                                                placeholder={field.placeholder}
                                                                required={field.required}/>}

                                {field.type === 'file' && form.values.file?.value && <Typography variant="body2">
                                    Uploaded file name:&nbsp;&nbsp;
                                    <Chip label={form.values.file.value.split('/').slice(-1)} variant="outlined" />
                                    </Typography>
                                }
                                {field.type === 'masked-text' && <MaskedText onChange={handleChange}
                                                                             disabled={disabled}
                                                                             errorMessage={errors[field.id]}
                                                                             id={field.id}
                                                                             mask={field.mask}
                                                                             label={field.label}
                                                                             initialValue={form.values[field.id].value}
                                                                             placeholder={field.placeholder}
                                                                             required={field.required}/>}
                                {field.type === 'text' && <Text onChange={handleChange}
                                                                disabled={disabled}
                                                                errorMessage={errors[field.id]}
                                                                id={field.id}
                                                                label={field.label}
                                                                initialValue={form.values[field.id].value}
                                                                placeholder={field.placeholder}
                                                                required={field.required}/>}
                                {field.type === 'select' && <Select onChange={handleChange}
                                                                    disabled={disabled}
                                                                    errorMessage={errors[field.id]}
                                                                    id={field.id}
                                                                    label={field.label}
                                                                    options={field.options}
                                                                    initialValue={form.values[field.id].value}
                                                                    required={field.required}/>}
                                {field.type === 'radio' && <Radio onChange={handleChange}
                                                                  disabled={disabled}
                                                                  id={field.id}
                                                                  label={field.label}
                                                                  options={field.options}
                                                                  required={field.required}
                                                                  errorMessage={errors[field.id]}
                                                                  initialValue={form.values[field.id].value || ""}/>}
                                {field.type === 'checkbox' && <Checkbox cols={2} onChange={handleChange}
                                                                        disabled={disabled}
                                                                        errorMessage={errors[field.id]}
                                                                        id={field.id}
                                                                        label={field.label}
                                                                        options={field.options}
                                                                        initialValue={form.values[field.id].value}
                                                                        required={field.required}/>}
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </form>}
            <StepControls step={step} disabled={disabled} onBack={onBack} onNext={handleSubmit}/>
        </Box>
    );
}

export default FormForm;