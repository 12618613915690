import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';

import useStyles from './styles';

import { selectors as applicationSelectors } from '../../../application/duck';
import { selectors as paymentSelectors } from '../../duck';

import { Timer } from '../';

const MIN_AMOUNT = 0.50;

const Order = ({ withTimer, timerStart, timerMilliseconds, onTimerEnd }) => {
    const classes = useStyles();

    const appliedCode = useSelector(paymentSelectors.code);
    const application = useSelector(applicationSelectors.applicationItem);

    const getTotalAmount = () => {
        const price = application.plan.price;

        if (appliedCode?.id) {
            let amount;

            if (appliedCode.type === 1) {
                amount = price - appliedCode.rate;
            } else if (appliedCode.type === 0) {
                const priceInCents = price * 100;
                const priceWithPromo = Math.round(priceInCents - (appliedCode.rate / 100 * priceInCents))
                amount = priceWithPromo / 100;
            };

            return amount >= MIN_AMOUNT ? amount : MIN_AMOUNT;
        } else {
            return price;
        };
    };

    return (
        <div className={classes.column}>
            <div className={`${classes.column} ${classes.orderContainer}`}>
                <p>Your order</p>
                <div className={'serviceInfoContainer'}>
                    <div className={`${classes.row} point`}>
                        <div className={'iconContainer'}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19 3H5C3.9 3 3.01 3.9 3.01 5L3 19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM18 14H14V18H10V14H6V10H10V6H14V10H18V14Z" fill="#3F51B5"/>
                            </svg>
                        </div>
                        <div className={classes.column}>
                            Service:
                            <b>{application?.service?.name || ''}</b>
                        </div>
                    </div>
                    <div className={`${classes.row} point`}>
                        <div className={'iconContainer'}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13.41 18.09V20H10.74V18.07C9.03 17.71 7.58 16.61 7.47 14.67H9.43C9.53 15.72 10.25 16.54 12.08 16.54C14.04 16.54 14.48 15.56 14.48 14.95C14.48 14.12 14.04 13.34 11.81 12.81C9.33 12.21 7.63 11.19 7.63 9.14C7.63 7.42 9.02 6.3 10.74 5.93V4H13.41V5.95C15.27 6.4 16.2 7.81 16.26 9.34H14.3C14.25 8.23 13.66 7.47 12.08 7.47C10.58 7.47 9.68 8.15 9.68 9.11C9.68 9.95 10.33 10.5 12.35 11.02C14.37 11.54 16.53 12.41 16.53 14.93C16.52 16.76 15.15 17.76 13.41 18.09Z" fill="#3F51B5"/>
                            </svg>
                        </div>
                        <div className={classes.column}>
                            Plan:
                            <b>{application?.plan?.description || ''}</b>
                        </div>
                    </div>
                    <div className={`${classes.row} point`}>
                        <div className={'iconContainer'}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM16.2 16.2L11 13V7H12.5V12.2L17 14.9L16.2 16.2Z" fill="#3F51B5"/>
                            </svg>
                        </div>
                        <div className={classes.column}>
                            Time:
                            <b>{moment(application?.date).format('MM/DD/YYYY h:mm A')}</b>
                        </div>
                    </div>
                </div>
                {withTimer && <div className={`${classes.row} timerContainer`}>
                    Your appointment booking is valid for
                    <Timer
                        start={timerStart}
                        milliseconds={timerMilliseconds}
                        onEnd={onTimerEnd}
                    />
                </div>}
                <div className={'priceContainer'}>
                    {appliedCode?.id && <div className={classes.row} style={{ marginBottom: '24px' }}>
                        Original price:
                        <b>${application?.plan?.price?.toFixed(2)}</b>
                    </div>}
                    {appliedCode?.type === 0 && <div className={classes.row} style={{ marginBottom: '7px' }}>
                        Coupon discount ({appliedCode.code}):
                        <b>-{appliedCode.rate}%</b>
                    </div>}
                    {appliedCode?.type === 1 && <div className={classes.row} style={{ marginBottom: '7px' }}>
                        Coupon discount ({appliedCode.code}):
                        <b>-${appliedCode.rate.toFixed(2)}</b>
                    </div>}
                    <div className={classes.row}>
                        Total Amount:
                        <b>${getTotalAmount()?.toFixed(2)}</b>
                    </div>
                </div>
            </div>
            <div className={`${classes.row} ${classes.bottomIconContainer}`}>
                <img src={process.env.REACT_APP_URL + '/secure-checkout.svg'} alt="secure checkout"></img>
                <img src={process.env.REACT_APP_URL + '/satisfaction-guaranteed.svg'} alt="satisfaction-guaranteed"></img>
            </div>
        </div>

    );
};

Order.propTypes = {
    withTimer: PropTypes.bool.isRequired,
    timerStart: PropTypes.number,
    timerMilliseconds: PropTypes.number,
};

export default Order;
