import React from 'react';
import PropTypes from 'prop-types';

import {Box, Button, CircularProgress} from "@material-ui/core";

import useStyles from './styles'

const StepControls = ({step, blockButtonNext, disabled, nextButtonDisabled, onNext, onBack, hideNext}) => {
    const classes = useStyles();

    return (
        <Box className={classes.controls}>
            {step > 0 && onBack && <Button disabled={disabled} className={`${classes.button} ${classes.backButton}`} variant="outlined" onClick={onBack}>
                Back
            </Button>}
            {!hideNext && <Button disabled={disabled || blockButtonNext || nextButtonDisabled} className={classes.button} variant="contained" onClick={onNext}
                    color="primary">
                {disabled && !nextButtonDisabled ?
                    <>&nbsp;<CircularProgress color="inherit" size={16}/>&nbsp;&nbsp;Sending...</> :
                    <>Next</>}
            </Button>}
        </Box>
    );
};

StepControls.propTypes = {
    step: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
};

export default StepControls;