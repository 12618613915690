import React, {useEffect, useState} from 'react';

import {
    FormGroup,
    FormControl,
    FormControlLabel,
    Checkbox as CheckboxField,
    FormLabel,
    FormHelperText
} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    group: {
        flexDirection: 'row'
    },
    item: {
        width: '32%',
        alignItems: 'flex-start',

        [theme.breakpoints.down('sm')]: {
            width: '48%',
        },
    },
    fullItem: {
        width: '100%',
        alignItems: 'flex-start',
    },
    checkbox: {
        paddingTop: 0,

        '& .MuiIconButton-label': {
            color: 'inherit'
        }
    },
    label: {
        fontSize: '0.9rem',
        lineHeight: '1.5em',
        marginBottom: theme.spacing(1),

        '& .MuiFormLabel-asterisk': {
            color: theme.palette.error.dark,
            fontSize: '0.9rem',
        }
    }
}));

const Checkbox = ({label, options, id, onChange, required, disabled, initialValue, errorMessage, cols}) => {
    const classes = useStyles();
    const [checked, setChecked] = useState(initialValue === "" ? [] : initialValue);
    const handleChange = (event) => {
        if (event.target.checked) {
            setChecked([...checked, event.target.value]);
        } else {
            const array = Array.from(checked);
            const index = array.indexOf(event.target.value);
            array.splice(index, 1);
            setChecked(array);
        }
    };

    useEffect(() => {
        onChange(id, checked);
    }, [checked]);

    const isRequired = required === "true";
    const isError = errorMessage !== '';

    return (
        <FormControl error={isError} component="fieldset" className={classes.root}>
            <FormLabel required={isRequired} className={classes.label} focused={false}
                       component="legend">{label}</FormLabel>
            <FormGroup className={classes.group}>
                {options.map((option, index) => (
                    <FormControlLabel className={cols === 1 ? classes.fullItem : classes.item}
                        key={index}
                        label={option}
                        control={
                            <CheckboxField
                                className={classes.checkbox}
                                checked={checked.indexOf(option) !== -1}
                                disabled={disabled}
                                onChange={handleChange}
                                value={option}/>}
                    />
                ))}
            </FormGroup>
            {isError && <FormHelperText>{errorMessage}</FormHelperText>}
        </FormControl>
    );
};

Checkbox.propTypes = {};

export default Checkbox;