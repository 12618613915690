import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        '& .MuiButton-containedPrimary:hover': {
            background: '#1A2980',
            borderColor: '#1A2980',
            color: '#FFFFFF',
        },

        '& .MuiButton-contained.Mui-disabled ': {
            background: '#9E9E9E',
            borderColor: '#9E9E9E',
            color: '#535353',
            boxShadow: 'none',
        },
    },

    container: {
        display: 'flex',
        gap: '10px'
    },
    button: {
        height: '52px',
        borderRadius: '10px',
        fontWeight: '700',
    },
    submitButton: {
        whiteSpace: 'nowrap'
    },
    backButton: {
        maxWidth: '170px',
        width: '100%',
        background: theme.palette.background.paper,
    },
    nextButton: {
        maxWidth: '210px',
        width: '100%',
    },

    // style for mobile

    mobileContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    },
    mobileBackButton: {
        width: '100%',
        background: theme.palette.background.paper,
        marginRight: '30px',
    },
    mobileNextButton: {
        width: '100%',
    }
}));

export default useStyles;
