import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import Steps from './modules/steps';
import { HashRouter, useParams, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { actions as applicationActions } from './modules/application/duck';
import { selectors as applicationSelectors } from './modules/application/duck';
import { actions as servicePlanActions } from './modules/service-plan/duck';
import { selectors as servicePlanSelectors } from './modules/service-plan/duck';
import { actions as enabledDoctorActions } from './modules/enabled-doctors/duck';
import { selectors as enabledDoctorSelectors } from './modules/enabled-doctors/duck';

import './App.css';

import ThankYou from './modules/thank-you';
import { createBrowserHistory } from 'history';
import {
    UnrecognizedError,
    ApplicationNotFound,
    AlreadyPaidError,
    AlreadyFilledError,
    IntakeBeforePaymentError,
    HaveSubscriptionError,
} from './componets/errors';
import { actions as configActions, selectors as configSelectors } from './modules/config/duck';

const history = createBrowserHistory({
    basename: '',
    hashType: 'noslash',
});

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const App = (props) => {
    const [cookies, setCookie, removeCookie] = useCookies([
        'dctrApplication',
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'leadId',
        'contactId',
    ]);
    const query = useQuery();

    /**
     * Attempting to get predefined service from query or props and saved service from partly filled form
     */
    let predefinedService = query.get('service') || props.serviceCode;
    const serviceFromCookie = cookies.dctrApplication ? cookies.dctrApplication.service : null;

    /**
     * Getting promo code from query
     */
    let promoCode = query.get('promocode') || null;

    /**
     * Attempting to get saved application ID form partly filled form or application ID from URL
     */
    const applicationIdFromCookie = cookies.dctrApplication ? cookies.dctrApplication.id : null;
    const applicationIdFromUrl = useParams().applicationId;
    const pageUrl = useParams().pageUrl;

    const applicationItem = useSelector(applicationSelectors.applicationItem);
    const configItem = useSelector(configSelectors.configItem);
    const applicationId = useSelector(applicationSelectors.applicationId);
    const configId = useSelector(applicationSelectors.configId);
    const applicationErrors = useSelector(applicationSelectors.applicationErrors);
    const configErrors = useSelector(configSelectors.configErrors);
    const servicePlans = useSelector(servicePlanSelectors.servicePlans);
    const enabledDoctors = useSelector(enabledDoctorSelectors.enabledDoctors);

    const dispatch = useDispatch();

    const [initial, setInitial] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingPaymentScript, setLoadingPaymentScript] = useState(true);
    const [loadPaymentScriptError, setLoadPaymentScriptError] = useState(false);
    const [ready, setReady] = useState(false);
    const [intakeBeforePayment, setIntakeBeforePayment] = useState(false);
    const [waitingForPaymentResult, setWaitingForPaymentResult] = useState(false);

    // hook => turn off FB chat, when the component is initialized
    useEffect(() => {
        const elementFB = document.getElementById('fb-root');

        if (elementFB) {
            elementFB.style.display = 'none';
        }

        return () => {
            const elementFB = document.getElementById('fb-root');

            if (elementFB) {
                elementFB.style.display = 'block';
            }
        };
    }, []);

    useEffect(() => {
        setLoading(true);

        /**
         * First we need to set up out applicationId, if present, or to mark application as initial
         */

        if (typeof applicationIdFromUrl !== 'undefined') {
            dispatch(applicationActions.setApplicationId({ id: applicationIdFromUrl }));
        } else if (applicationIdFromCookie) {
            if (predefinedService) {
                if (predefinedService === serviceFromCookie) {
                    dispatch(applicationActions.setApplicationId({ id: applicationIdFromCookie }));
                } else {
                    setInitial(true);
                }
            } else {
                if (!serviceFromCookie) {
                    dispatch(applicationActions.setApplicationId({ id: applicationIdFromCookie }));
                } else {
                    setInitial(true);
                }
            }
        } else {
            setInitial(true);
        }

        /**
         * If we have configId in props we can set it up
         */

        if (props.configId) {
            dispatch(applicationActions.setConfigId({ configId: props.configId }));
        }

        /**
         * If we have promoCode from query we can set it up
         */

        if (promoCode) {
            localStorage.setItem('PROMOCODE', promoCode);
        }
    }, []);

    useEffect(() => {
        /**
         * We have an application ID from cookie, but do not have it in URL.
         * So we redirect user to the same page with application ID.
         */
        if (applicationId && !applicationIdFromUrl) {
            history.push(applicationId);
            history.push({
                pathname: applicationId,
                search: promoCode ? '?promocode=' + promoCode : null,
            });
        }
        /**
         * We have an application ID (in URL) but do not have an application item.
         * So we can load both config and application.
         * Config will be null on intake form
         */
        if (applicationId && !applicationItem) {
            dispatch(applicationActions.getApplication({ id: applicationId }));
        }
    }, [applicationId]);

    useEffect(() => {
        /**
         * On intake forms we have an application object but do not have config id in props
         * But first we need to check if payment is present
         */
        if (applicationItem && !props.configId) {
            if (!applicationItem.waitingForIntake && !applicationItem.intakeAllowedBeforePayment) {
                setIntakeBeforePayment(true);
                setLoading(false);
            } else if (!applicationItem.payment) {
                setWaitingForPaymentResult(true);
                setLoading(false);
                setTimeout(() => {
                    dispatch(applicationActions.getApplication({id: applicationId}));
                }, 6000);
            } else {
                setWaitingForPaymentResult(false);
                dispatch(applicationActions.setConfigId({configId: applicationItem.intakeConfigId}));
            };
        }
    }, [applicationItem]);

    useEffect(() => {
        if (configId && !configItem && applicationItem) {
            dispatch(configActions.getConfig({ id: configId }));
        } else if (configId && !configItem && initial) {
            dispatch(configActions.getConfig({ id: configId }));
        }
    }, [configId, applicationItem, initial]);

    useEffect(() => {
        /**
         * check config and loading desired script for payments
         */

        if (configItem) {
            const paymentStep = configItem?.steps?.find((step) => step.id === 'payment');
            const paymentType = paymentStep?.providerData?.type || paymentStep?.type;
            let paymentScript;

            switch (paymentType) {
                case 'payment-authorize':
                    paymentScript = document.createElement('script');
                    paymentScript.src = process.env.REACT_APP_AUTHORIZE_SCRIPT_URL;
                    break;
                case 'payment-square':
                    paymentScript = document.createElement('script');
                    paymentScript.src = process.env.REACT_APP_SQUARE_APP_SCRIPT_URL;
                    break;
            }

            if (paymentScript) {
                paymentScript.type = 'application/javascript';
                paymentScript.async = true;
                document.getElementsByTagName('head')[0].appendChild(paymentScript);
                paymentScript.onload = () => {
                    setLoadingPaymentScript(false);
                };
                paymentScript.onerror = () => {
                    setLoadingPaymentScript(false);
                    setLoadPaymentScriptError(true);
                };
            } else {
                setLoadingPaymentScript(false);
            }
        }

        /**
         * When we have both application and config objects we can request service plans from server
         * Or just do nothing if application is marked as initial
         */
        if (applicationItem && configItem) {
            dispatch(
                servicePlanActions.getServicePlans({
                    stateCode: applicationItem.personalInfo.state.value.toLowerCase(),
                    websiteCode: configItem.website,
                })
            );
            dispatch(enabledDoctorActions.getEnabledDoctors(applicationItem.plan?.id));
        } else if (configItem && initial) {
            setLoading(false);
            setReady(true);
        }
    }, [configItem]);

    useEffect(() => {
        /**
         * After getting all service plans from server all set ups done
         * We can proceed loading form
         */

        if (servicePlans || enabledDoctors) {
            setReady(true);
            setLoading(false);
        }
    }, [servicePlans, enabledDoctors]);

    useEffect(() => {
        if (applicationErrors.length > 0 || configErrors.length > 0) {
            setLoading(false);

            if (applicationErrors[0]?.code) {
                switch (applicationErrors[0].code) {
                    case 'not_available_for_filling':
                        setTimeout(() => goToNewApplication(), 3000);
                        break;
                }
            }
        }
    }, [applicationErrors, configErrors]);

    const goToNewApplication = () => {
        if (!props.configId) {
            return;
        }

        setLoading(true);
        removeCookie('dctrApplication');
        removeCookie('leadId');
        removeCookie('contactId');
        window.location.pathname = pageUrl;
    };

    const loadingIndicator = (text = 'Loading your application') => (
        <div style={{ textAlign: 'center' }}>
            <br />
            <br />
            <br />
            <CircularProgress />
            <Box>
                <br />
                <Typography variant="button">{text}</Typography>
            </Box>
        </div>
    );

    let content = <></>;
    if (applicationErrors?.length) {
        if (applicationErrors[0]?.code) {
            switch (applicationErrors[0].code) {
                case 'service_already_paid':
                    content = <AlreadyPaidError />;
                    break;
                case 'have_subscription':
                    content = <HaveSubscriptionError />;
                    break;
                case 'not_available_for_filling':
                    content = props.configId ? (
                        <>
                            <AlreadyFilledError />
                            <br />
                            {loadingIndicator('Redirection in progress')}
                        </>
                    ) : (
                        <AlreadyFilledError />
                    );
                    break;
                default:
                    content = <ApplicationNotFound />;
                    break;
            }
        } else {
            content = <UnrecognizedError />;
        }
    } else if (configErrors.length > 0) {
        content = <UnrecognizedError />;
    } else if (intakeBeforePayment) {
        content = <IntakeBeforePaymentError />;
    } else if (waitingForPaymentResult) {
        content = loadingIndicator('Please wait, the payment is being processed. You will be redirected after the result.');
    } else if (loadPaymentScriptError) {
        content = <UnrecognizedError />;
    } else if (ready) {
        if (props.configId && applicationItem?.payment) {
            content = (<ThankYou/>);
        } else {
            content = <Steps predefinedService={predefinedService} />;
        }
    }

    return (
        <div className="App" elementtiming="application-form">
            <HashRouter hashType="noslash">
                {loading || (props.configId && loadingPaymentScript) ? loadingIndicator() : content}
            </HashRouter>
        </div>
    );
};

export default App;
