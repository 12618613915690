import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress } from '@material-ui/core';

import useStyles from './styles';

const StepControls = ({
                          disabled,
                          processing,
                          showSkipButton,
                          isMobileMood,
                          onNext,
                          onBack,
                          onSkipPayment,
                          isBackButtonAvailable = true
}) => {
    const classes = useStyles();

    return (
        <Box>
            {!isMobileMood && <Box className={classes.container}>
                {isBackButtonAvailable && <Button
                    className={`${classes.button} ${classes.backButton}`}
                    disabled={processing}
                    variant="outlined"
                    onClick={onBack}
                >
                    Back
                </Button>}
                <Button
                    className={`${classes.button} ${classes.nextButton} ${classes.submitButton}`}
                    disabled={disabled || processing}
                    variant="contained"
                    color="primary"
                    onClick={onNext}
                >
                    {(processing) ?
                        <>&nbsp;<CircularProgress color="inherit" size={16}/>&nbsp;&nbsp;Sending...</> :
                        <>SUBMIT SECURE PAYMENT</>}
                </Button>
                {showSkipButton && <Button
                    className={`${classes.button} ${classes.nextButton} ${classes.submitButton}`}
                    variant="contained"
                    color="primary"
                    onClick={onSkipPayment}
                >
                   I WILL PAY ONSITE
                </Button>}
            </Box>}
            {isMobileMood && <Box className={classes.mobileContainer}>
                <Button
                    className={`${classes.button} ${classes.mobileNextButton}`}
                    disabled={disabled || processing}
                    variant="contained"
                    color="primary"
                    onClick={onNext}
                >
                    {(processing) ?
                        <>&nbsp;<CircularProgress color="inherit" size={16}/>&nbsp;&nbsp;Sending...</> :
                        <>SUBMIT SECURE PAYMENT</>}
                </Button>
                {showSkipButton && <Button
                    className={`${classes.button} ${classes.mobileNextButton}`}
                    variant="contained"
                    color="primary"
                    onClick={onSkipPayment}
                >
                    I WILL PAY ONSITE
                </Button>}
                {isBackButtonAvailable && <Button
                    className={`${classes.button} ${classes.mobileBackButton}`}
                    disabled={processing}
                    variant="outlined"
                    onClick={onBack}
                >
                    Back
                </Button>}
            </Box>}
        </Box>
    );
};

StepControls.propTypes = {
    processing: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    isMobileMood: PropTypes.bool,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
};

export default StepControls;
