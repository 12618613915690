import {Button, Dialog, DialogActions, DialogContent, makeStyles, Typography} from "@material-ui/core";
import React from "react";

const useModalStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTypography-root': {
            textAlign: 'center',
            color: theme.palette.text.primary,
        },
        '& .MuiDialogActions-root': {
            justifyContent: 'center',
            padding: '25px',
            flexDirection: 'column',
            gap: '10px',
            maxWidth: '300px',
            margin: '0 auto',
        },
        '& .MuiButton-root': {
            width: '100%',
            margin: '0'
        },
    }
}));

export const PromocodeDialog =
    ({isShown, setIsShown, submitClicks = 0, setSubmitClicks = () => {}, continueWithoutPromo = () => {}}) => {
        const modalClasses = useModalStyles();

        const continueWithoutPromocode = () => {
            setSubmitClicks(submitClicks + 1);
            setIsShown(false);
            continueWithoutPromo()
        }

        return (
            <Dialog
                classes={modalClasses}
                open={isShown}
                onClose={() => setIsShown(false)}
            >
                <DialogContent dividers>
                    <Typography variant="h6" component="h2">
                        You entered invalid promo code. Would you like to continue without it?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => continueWithoutPromocode()}
                        variant="contained"
                        color="primary"
                    >
                        Continue Without Promo Code
                    </Button>
                    <Button onClick={() => setIsShown(false)} variant="outlined">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
