const from = ({scheduleMap}) => scheduleMap.from;
const to = ({scheduleMap}) => scheduleMap.to;
const availableDays = ({scheduleMap}) => scheduleMap.availableDays;


export const selectors = {
    from,
    to,
    availableDays
};
