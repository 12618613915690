import React, {useState} from 'react';

import {TextField, MenuItem} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '10px',

        '& .MuiInputLabel-outlined': {
            lineHeight: 1.4,
            fontSize: '1.2rem',
            position: 'relative',
            marginLeft: '-13px;',
        },

        '& .MuiFormHelperText-contained': {
            marginLeft: 0,
            marginTop: '5px'
        },

        '& .MuiFormLabel-root .MuiFormLabel-asterisk': {
            color: theme.palette.error.dark,
            fontSize: '1.2rem',
        },

        '& .MuiOutlinedInput-root': {
            marginTop: '-5px',
            background: theme.palette.background.paper,
            
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.23)',
            },

            '&.Mui-error': {
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.error.main
                }
            },

            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.main,
            },
            '&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.error.main
            },
        },
    },

}));


const Select = ({label, options, id, onChange, required, errorMessage, disabled, initialValue}) => {
    const classes = useStyles();
    const initialPlaceholder = typeof options[0] === 'object' ? options[0].code : options[0];
    const [value, setValue] = useState(initialValue || initialPlaceholder);

    const handleChange = (event) => {
        const value = event.target.value;
        setValue(value);
        onChange(id, value);
    };

    const isRequired = required === "true";
    const isError = errorMessage !== '';

    const objectOption = (option, index) => (<MenuItem key={index} value={option.code} disabled={index === 0}>{option.name}</MenuItem>)
    const stringOption = (option, index) => (<MenuItem key={index} value={option} disabled={index === 0}>{option}</MenuItem>)

    const htmlElement = document.querySelector('html');

    const handleHtmlOverflow = (isClosed) => {
        htmlElement.style.overflowY = isClosed ? 'hidden' : 'auto';
    }

    return (
        <TextField select
                   classes={classes}
                   fullWidth
                   size="small"
                   id={id}
                   variant="outlined"
                   disabled={disabled}
                   value={value}
                   onChange={handleChange}
                   InputLabelProps={{ shrink: true, required: isRequired }}
                   InputProps={{notched: false}}
                   SelectProps={{
                       onClose: () => handleHtmlOverflow(false),
                       onOpen: () => handleHtmlOverflow(true)
                   }}
                   error={isError}
                   helperText={isError ? errorMessage : ''}
                   label={label}>
            {options.map((option, index) => {
                return (typeof option === 'object') ? objectOption(option, index) : stringOption(option, index)

            })}
        </TextField>
    );
};

Select.propTypes = {

};

export default Select;