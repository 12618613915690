import React, { useState } from 'react';
import { Checkbox, FormControlLabel } from "@material-ui/core";
import useStyles from './styles';

const TermsOfUse = ({ config, value, onChange }) => {
    const classes = useStyles();

    const [termsOfUse, setTermsOfUse] = useState(value);

    const getTermsOfUseLabel = () => {
        const webSites = {
            'ezcareclinic': 'Ezcare',
            'medvidi': 'MEDvidi',
            'mangoclinic': 'Mango'
        };

        return <p className={classes.confirmText}>
            Yes, I understand and agree to the
            <a className={classes.termsUrl} href={config?.termsURLs?.termsUrl || '/'} target="_blank"> Website Terms of Use</a>,
            <a className={classes.termsUrl} href={config?.termsURLs?.privacyUrl || '/'} target="_blank"> Privacy Policy</a>,
            <a className={classes.termsUrl} href={config?.termsURLs?.healthTermsAndConditionsUrl || '/'} target="_blank"> {webSites[config.websiteCode]} Health Terms and Conditions</a> and
            <a className={classes.termsUrl} href={config?.termsURLs?.refundPolicyUrl || '/'} target="_blank"> Refund Policy</a>.
        </p>;
    };

    const termsOfUseChanged = (value) => {
        setTermsOfUse(value);

        if (onChange) {
            onChange(value);
        }
    }

    return  <FormControlLabel
        label={getTermsOfUseLabel()}
        control={
            <Checkbox
                color="primary"
                checked={termsOfUse}
                onChange={(event) => termsOfUseChanged(event.target.checked)}
            />}
    />;
};

export default TermsOfUse;