import React, {useEffect, useState, useRef} from 'react'
import PropTypes from 'prop-types'
import {useSelector, useDispatch} from "react-redux"

import {Alert, AlertTitle} from "@material-ui/lab"
import {CommonError} from "../../componets/errors";
import GeneralForm from "./general-form";
import {Box} from '@material-ui/core'

import {selectors as applicationSelectors} from "../application/duck"
import {selectors as configSelectors} from "../config/duck"
import {actions as applicationActions} from "../application/duck"

import {createHashHistory} from 'history';
const history = createHashHistory({
    basename: '',
    hashType: 'noslash',
});

const General = ({onNext, fields, onBack, id, step, name}) => {
    const config = useSelector(configSelectors.configItem);
    const application = useSelector(applicationSelectors.applicationItem);
    const applicationId = useSelector(applicationSelectors.applicationId);
    const applicationErrors = useSelector(applicationSelectors.applicationErrors);
    const dispatch = useDispatch();

    const initialMount = useRef(true);
    const [updating, setUpdating] = useState(false);

    useEffect(() => {
        const nextStep = application && application.nextStepId ? application.nextStepId : 0;
        if (nextStep < step) history.push(config.steps[nextStep].url);
    });

    useEffect(() => {
        if (initialMount.current) {
            initialMount.current = false;
        } else {
            setUpdating(false);
            onNext();
        }
    }, [application]);

    const onSubmit = (form) => {
        setUpdating(true);

        dispatch(applicationActions.updateApplicationData({
            id: applicationId,
            data: {
                value: form,
            },
            key: id,
            step: step
        }));
    };

    const renderPageInfoSection = () => {
        if (config?.steps[step]?.pageInfo) {
            return (
                <Alert severity="info">
                    <AlertTitle>Important!</AlertTitle>
                    {config.steps[step].pageInfo.split('\n').map((line, index) => (
                        <div key={index}>{line}</div>
                    ))}
                </Alert>
            );
        }

        return (
            <Alert severity="info">
                <AlertTitle>Thank you for your payment.</AlertTitle>
                <p>Important!</p>
                <p>Please note, you must answer all questions on the application below before your appointment with a healthcare provider.</p>
            </Alert>
        );
    }

    return (
        <Box>
            {applicationErrors.length > 0 && <CommonError/>}
            <br/>
            { renderPageInfoSection() }
            <br/>
            <GeneralForm fields={fields} name={name} id={id} onBack={onBack} step={step} onSubmit={onSubmit} disabled={updating} application={application}/>
        </Box>
    );
};

General.propTypes = {
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
};

export default General;