import React, {useEffect, useState} from 'react'

import {Box, Grid} from "@material-ui/core"
import moment from "moment"
import Select from "./Select";

const InlineDateField = ({disabled, id, onChange, required, errorMessage, initialValue}) => {
    const [month, setMonth] = useState('');
    const [day, setDay] = useState('');
    const [year, setYear] = useState('');
    const [date, setDate] = useState('');

    const months = ['Select month', ...moment.months()];

    const days = ['Select day'];
    for (let i = 1; i <= 31; i++) {
        days.push(`${i}`);
    };

    const years = ['Select year'];
    for (let i = moment().year() - 16; i >= 1930; i--) {
        years.push(`${i}`);
    };

    useEffect(() => {
        if (initialValue) {
            const date = moment(initialValue);
            setMonth(date.format('MMMM'));
            setYear(date.format('YYYY'));
            setDay(date.format('D'));
        }
    }, []);

    useEffect(() => {
        onChange('dateOfBirth', date);
    }, [date]);

    useEffect(() => {
        if (month !== '' && day !== '' && year !== '') {
            const monthNumber = moment().month(month).format('M');
            const momentDate = moment(`${monthNumber}/${day}/${year}`, 'M/D/YYYY');
            if (momentDate.isValid()) {
                setDate(momentDate.format('L'));
            }
        }
    }, [month, day, year]);

    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Select onChange={(id, value) => {setMonth(value)}}
                            disabled={disabled}
                            errorMessage={month === '' ? errorMessage : ''}
                            id={id + '-month'}
                            label={'Month'}
                            options={months}
                            initialValue={initialValue ? moment(initialValue).format('MMMM') : ''}
                            required={required}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Select onChange={(id, value) => {setDay(value)}}
                            disabled={disabled}
                            errorMessage={day === '' ? errorMessage : ''}
                            id={id + '-day'}
                            label={'Day'}
                            options={days}
                            initialValue={initialValue ? moment(initialValue).format('D') : ''}
                            required={required}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Select onChange={(id, value) => {setYear(value)}}
                            disabled={disabled}
                            errorMessage={year === '' ? errorMessage : ''}
                            id={id + '-year'}
                            label={'Year'}
                            options={years}
                            initialValue={initialValue ? moment(initialValue).format('YYYY') : ''}
                            required={required}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default InlineDateField;