import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

const AlreadyPaidError = () => {
    return (
        <Alert severity="error">
            <AlertTitle>It seems you have an active subscription.</AlertTitle>
            Please follow the link <a href="https://start.medvidi.com">StartMedvidi.com</a> to
            manage your subscription or proceed with the current appointment booking.
            <br />
            <br />
            For any support and services please contact <b>844-939-1267</b>
            <br />
        </Alert>
    );
};

export default AlreadyPaidError;
