import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Provider } from 'react-redux'
import store from './redux/store'
import {BrowserRouter, Route} from "react-router-dom"

import { create } from 'jss';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import increaseSpecificity from 'jss-increase-specificity';

const jss = create({
    plugins: [...jssPreset().plugins, increaseSpecificity()],
});

let elementId;
if (process.env.REACT_APP_ENV === 'development') {
    const pathName = window.location.pathname;
    if (pathName.match(/application\/?.?/i)) {
        elementId = 'dctr-form-application';
    } else if (pathName.match(/intake\/?.?/i)) {
        elementId = 'dctr-form-intake';
    } else {
        elementId = 'dctr-form';
    }
} else {
    elementId = 'dctr-form';
}

const element = document.getElementById(elementId);
const service = element.getAttribute('data-service') || null;
const configId = element.getAttribute('data-config') || null;

ReactDOM.render(
    (
        <Provider store={store}>
            <BrowserRouter>
                <Route path="/:pageUrl/:applicationId?">
                    <StylesProvider jss={jss}>
                        <App serviceCode={service} configId={configId} />
                    </StylesProvider>
                </Route>
            </BrowserRouter>
        </Provider>
    ), element);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
