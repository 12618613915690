import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const useStylesAutocomplete = makeStyles((theme) => ({
    root: {
        marginTop: '10px',

        '& .MuiAutocomplete-inputFocused': {
            borderColor: 'inherit',
            webkitBoxShadow: 'none',
            boxShadow: 'none'
        },

        '& .MuiFormLabel-root': {
            '& .MuiFormLabel-asterisk': {
                color: theme.palette.error.dark
            }
        },

        '& .MuiOutlinedInput-root': {
            background: theme.palette.background.paper,
        },
        '& .MuiFormHelperText-contained': {
            marginLeft: 0,
            marginTop: '5px'
        },
    }
}));

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: '0.9rem',
        lineHeight: '1.5em',
        marginBottom: '8px'
    },
    asterisk: {
        color: theme.palette.error.dark
    }
}));

export default function AutoComplete({ id, label, warning, placeholder, setCustomValue, onChange, options, required, errorMessage, disabled, initialValue}) {
    const classes = useStyles();
    const autocompleteStyles = useStylesAutocomplete();
    const [value, setValue] = useState(initialValue || '');
    const [freeSoloValue, setFreeSoloValue] = useState(false);

    useEffect(() => {
        setValue(initialValue);
        onChange(id, initialValue);
    }, [initialValue]);

    const isError = errorMessage !== '';
    const isRequired = required === "true";
    const handleChange = (eventValue) => {
        const value = eventValue || '';
        setValue(value);
        onChange(id, value);
    };

    const getHint = () => {
        const showHint = !isError && value.length > 32 && window.screen.width < 768;
        return showHint ? value : '';
    }

    return (<>
        <legend className={`MuiFormLabel-root Mui-required ${(isError ? 'Mui-error' : '')} ${classes.label}`}>{label}
            {isRequired && <span aria-hidden="true" className={`MuiFormLabel-asterisk ${(isError ? 'Mui-error' : '')} ${classes.asterisk}`}>&thinsp;*</span>}
        </legend>
        {warning}
        <Autocomplete
            id={id}
            classes={autocompleteStyles}
            options={options}
            disabled={disabled}
            freeSolo={freeSoloValue}
            value={value}
            fullWidth
            size="small"
            renderInput={(params) => <TextField
                {...params}
                variant="outlined"
                InputLabelProps={{ required: isRequired }}
                error={isError}
                placeholder={placeholder}
                helperText={isError ? errorMessage : getHint()}
            />}
            renderOption={(option) => {
                if (option.title) {
                    return <>
                        <AddCircleOutlineIcon style={{ marginRight: '5px' }}/>
                        {option.title}
                    </>
                }

                return option
            }}
            filterOptions={(options, params) => {
                const filtered = options.filter(elem => elem.toLowerCase().includes((params.inputValue || value).toLowerCase()));

                if (params.inputValue !== '' && setCustomValue === 'true' && !freeSoloValue) {
                    filtered.push({
                        inputValue: params.inputValue,
                        title: 'Use a pharmacy address that is not on the list',
                    });
                }

                return filtered;
            }}
            onChange={(event, newValue) => {
               if (newValue?.inputValue) {
                    setFreeSoloValue(true);
                    handleChange(newValue.inputValue);
               } else {
                    setFreeSoloValue(false);
                    handleChange(newValue);
                }
            }}
            onInputChange={(event, value, reason) => {
                if (reason === 'input' && freeSoloValue) {
                    handleChange(value);
                }
            }}
            onBlur={() => setFreeSoloValue(false)}
            getOptionLabel={(option) => {
                if (option?.title) {
                    return option.title;
                }

                return option;
            }}
            ListboxProps={
                {
                    style:{
                        maxHeight: '170px',
                    }
                }
            }
        />
    </>);
}