import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',

        '& .leftBlock': {
            flex: '2',
            marginRight: '20px',

            '& .errorText': {
                display: 'flex',
                flexDirection: 'row',
                marginTop: '8px',
                color: '#FF0000',
            },

            '& .successfulText': {
                display: 'flex',
                flexDirection: 'row',
                marginTop: '8px',
                color: '#1AAC0D',
            }
        },

        '& .rightBlock': {
            flex: '1',
        },

        '& button': {
            height: '52px',
            width: '100%',
            borderRadius: '10px',
            border: '1px solid #3F51B5',
            background: '#ffffff',
            color: '#3F51B5',
            fontWeight: '400',
            cursor: 'pointer',
        },

        '& button:hover': {
            background: '#3F51B5',
            borderColor: '#3F51B5',
            color: '#FFFFFF',
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        },

        '& button:disabled': {
            background: '#9E9E9E',
            borderColor: '#9E9E9E',
            color: '#535353',
            boxShadow: 'none',
            cursor: 'default',
        },

        '& .MuiOutlinedInput-root': {
            borderRadius: '10px',

            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.23)',
            },

            '&.Mui-error': {
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.error.main
                }
            },

            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.main,
            },
            '&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.error.main
            },
        },
    },
}));

export default useStyles;
