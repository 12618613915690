import Axios from 'axios';

const api = Axios.create({
  baseURL: process.env.REACT_APP_API_URL + '/configs/',
});

const getConfig = async ({id}) =>  {
  return await api.get(`${id}`);
};

export const operations = {
  getConfig
};
