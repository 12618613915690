import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1)
    },
    backButton: {
        background: theme.palette.background.paper,
    },
    controls: {
        marginTop: theme.spacing(3),
    },
    divider: {
        marginTop: theme.spacing(1)
    },
    card: {
        marginTop: theme.spacing(5)
    },
    refundPolicy: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '1.5em',
        color: theme.palette.text.secondary
    },
    electronicSignature: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
    },
    checkboxRequired: {
        border: '1px solid red',
        borderRadius: 3,
    },
    promocode: {
        marginTop: '32px',
        marginBottom: '25px'
    },
    container: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '10px'
        }
    }
}));

export default useStyles;