const schema = {
    firstName: {
        label: "First name",
        placeholder: "Please type your first name",
        required: "true",
    },
    lastName: {
        label: "Last name",
        placeholder: "Please type your last name",
        required: "true",
    },
    email: {
        label: "Email",
        placeholder: "Enter valid email address",
        required: "true",
        rules: "userEmail",
    },
    phoneNumber: {
        label: "Phone",
        placeholder: "Enter your phone number",
        mask: "usPhone",
        required: "true",
        rules: "usPhone",
    },
};

export default schema;
