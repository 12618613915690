import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';

import {Box} from '@material-ui/core';
import StepControls from "../step-controls";
import {
    CustomRadio,
    RadioPlans,
    DateTimeSchedule,
    DateTime,
} from "../../componets/fields";
import analytics from '../analytics';
import utils from '../utils';
import {CommonError, ScheduleNotAvailable} from "../../componets/errors";

import {useSelector, useDispatch} from "react-redux";
import {selectors as applicationSelectors} from "../application/duck";
import {selectors as servicePlanSelectors} from "../service-plan/duck";
import {actions as applicationActions} from "../application/duck";

import {createHashHistory} from 'history';
import {selectors as configSelectors} from "../config/duck";
import {ContactUs} from "../contact-us/ContactUs";

const history = createHashHistory({
    basename: '',
    hashType: 'noslash',
});

const Choice = ({field, onNext, onBack, id, step}) => {
    const initialApplicationMount = useRef(true);
    const application = useSelector(applicationSelectors.applicationItem);
    const applicationId = useSelector(applicationSelectors.applicationId);
    const applicationErrors = useSelector(applicationSelectors.applicationErrors);
    const selectedService = useSelector(applicationSelectors.selectedService);
    const predefinedService = useSelector(applicationSelectors.predefinedService);
    const servicePlans = useSelector(servicePlanSelectors.servicePlans);
    const servicePlansErrors = useSelector(servicePlanSelectors.servicePlansErrors);
    const configId = useSelector(applicationSelectors.configId);
    const config = useSelector(configSelectors.configItem);
    const dispatch = useDispatch();

    const [updating, setUpdating] = useState(false);
    const [value, setValue] = useState(null);

    const [mappedServices, setMappedServices] = useState(null);
    const [mappedPlans, setMappedPlans] = useState(null);

    const mapPlans = (servicePlans) => {
        const userService = selectedService || predefinedService;
        const foundService = servicePlans.find(service => service.code === userService.code);

        let mappedServicePlans = foundService ? foundService.servicePlans : [];

        const servicePlanType = application.servicePlanType;

        if (servicePlanType && foundService) {

            let filteredServicePlans = mappedServicePlans.filter(sp => sp.type === servicePlanType);

            if(filteredServicePlans.length) {
                mappedServicePlans = filteredServicePlans;
            }
        }

        return mappedServicePlans;
    }

    const mapServices = (servicesFromConfig, servicesFromDB) => {
        const mappedServices = []

        for (const serviceFromConfig of servicesFromConfig) {
            const mappedService = servicesFromDB.find(serviceFromDB => serviceFromConfig.code === serviceFromDB.code);

            if (mappedService) {
                serviceFromConfig.title = mappedService.name;
                mappedServices.push(serviceFromConfig);
            }
        }

        return mappedServices;
    }

    const ifThereAreServicePlans = (servicePlans && servicePlans.length > 0)
    const isCustomRadioServicesType = field.type === 'custom-radio-services'
    const isCustomRadioPlans = field.type === 'custom-radio-plans'
    const isContactUs = application.servicePlanType && ifThereAreServicePlans && isCustomRadioPlans


    useEffect(() => {
        if (ifThereAreServicePlans) {

            if (isCustomRadioServicesType) {
                const services = mapServices(field.options, servicePlans);
                setMappedServices(services);
            }
            if (isCustomRadioPlans) {
                const plans = mapPlans(servicePlans);
                setMappedPlans(plans);
            }
        }
    }, [servicePlans]);

    useEffect(() => {
        const nextStep = application && application.nextStepId ? application.nextStepId : 0;

        if (nextStep < step) history.push(config.steps[nextStep].url);

        if (value) {
            setUpdating(true);

            dispatch(applicationActions.updateApplication({
                id: applicationId,
                configId: configId,
                data: {
                    value: value,
                },
                key: id,
                step: step
            }))
        }

    }, [value]);

    useEffect(() => {
        setUpdating(false);

        if (initialApplicationMount.current) {
            initialApplicationMount.current = false;
        } else {
            onNext();
        }
    }, [application]);

    useEffect(() => {
        if (applicationErrors.length > 0) {
            setUpdating(false);
            window.scrollTo(0, 0);
        }
    }, [applicationErrors]);

    const handleChange = (key, value) => {
        if (['service', 'plan'].includes(field.id)) {
            const fieldName = utils.capitalizeFirstLetter(field.id);
            analytics.sendCustomEvent('Legacy From Funnel Details', `${fieldName}  Selected`, `${fieldName} Screen`);
        }
        setValue(value);
    };

    const handleBackNavigation = () => {
        if (['service', 'plan'].includes(field.id)) {
            const screen = utils.capitalizeFirstLetter(field.id);
            analytics.sendCustomEvent('Legacy From Funnel Details', 'Click Back', `${screen} Screen`);
        }
        onBack();
    }

    return (
        <Box>
            {applicationErrors.length > 0 && <Box><CommonError/><br/><br/></Box>}
            {servicePlansErrors.length > 0 && <ScheduleNotAvailable/>}
            {application && <Box>
                {field.type === 'date-time-schedule' && <DateTimeSchedule onChange={handleChange}
                                                                          disabled={updating}
                                                                          id={field.id}
                                                                          label={field.label}/>}
                {field.type === 'date-time' && <DateTime onChange={handleChange}
                                                         timeMin={field.timeMin}
                                                         timeMax={field.timeMax}
                                                         disabled={updating}
                                                         id={field.id}
                                                         label={field.label}/>}
                {field.type === 'custom-radio' && <CustomRadio onChange={handleChange}
                                                               disabled={updating}
                                                               id={field.id}
                                                               label={field.label}
                                                               options={field.options}/>}
                {isCustomRadioServicesType && mappedServices && mappedServices.length === 0 && <ScheduleNotAvailable/>}
                {isCustomRadioServicesType && mappedServices && mappedServices.length > 0 &&
                    <CustomRadio onChange={handleChange}
                                 disabled={updating}
                                 id={field.id}
                                 label={field.label}
                                 options={mappedServices}/>}
                {isCustomRadioPlans && mappedPlans && mappedPlans.length === 0 && <ScheduleNotAvailable/>}
                {isCustomRadioPlans && mappedPlans && mappedPlans.length > 0 && <CustomRadio onChange={handleChange}
                                                                                             disabled={updating}
                                                                                             id={field.id}
                                                                                             label={field.label}
                                                                                             options={mappedPlans}/>}
                {field.type === 'radio-plans' && <RadioPlans onChange={handleChange}
                                                             disabled={updating}
                                                             id={field.id}
                                                             label={field.label}/>}
                <br/><br/>
            </Box>}
            {isContactUs && <ContactUs/>}
            <StepControls step={step} onBack={handleBackNavigation} onNext={onNext} hideNext={true} disabled={updating}/>
        </Box>
    );
};

Choice.propTypes = {
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    field: PropTypes.object.isRequired
};

export default Choice;