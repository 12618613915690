import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1)
    },
    backButton: {
        background: theme.palette.background.paper,
    },
    controls: {
        marginTop: theme.spacing(3),
    },
    confirmTerms: {
        marginTop: theme.spacing(6),
    },
    link: {
        color: '#3F51B5',
        textDecoration: 'none',
        cursor: 'pointer',

        '&:visited': {
            color: '#3F51B5',
        }
    }
}));

export default useStyles;