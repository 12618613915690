import React from 'react'
import {Alert, AlertTitle} from '@material-ui/lab'

const ApplicationNotFound = () => (
    <Alert severity="error">
        <AlertTitle>Application not found</AlertTitle>
        Please check your application ID
    </Alert>
);

export default ApplicationNotFound
