import Axios from 'axios';

const api = Axios.create({
    baseURL: process.env.REACT_APP_API_URL + '/files',
});

const uploadFile = async ({form}) => {
    return await api.post('/upload', form)
};

export const operations = {
    uploadFile
};
