import {call, put, takeLatest} from 'redux-saga/effects';

import {operations} from './operations';
import {actions} from './modules';

function* watchSceduleMapActions() {
    yield takeLatest(actions.getScheduleMap, getScheduleMap);
    yield takeLatest(actions.scheduleMapReset, scheduleMapReset);
}

function* getScheduleMap({payload}) {
    const {serviceCode, stateCode, servicePlanId, leadId} = payload;

    try {
        const response = yield call(operations.getScheduleMap, {serviceCode, stateCode, servicePlanId, leadId});
        yield put(actions.getScheduleMapSuccess(response.data));
    } catch (error) {
        const response = {
            data: error.response.data,
            status: error.response.status,
            statusText: error.response.statusText,
        };
        yield put(actions.getScheduleMapFail(response));
    }
}

function* scheduleMapReset() {
    yield put(actions.scheduleMapResetSuccess());
}

export const sagas = watchSceduleMapActions;