import React, {useEffect, useState, useRef} from 'react';
import {Box} from '@material-ui/core';
import PropTypes from 'prop-types';

import {useSelector, useDispatch} from "react-redux";

import {selectors as applicationSelectors} from "../application/duck";
import {actions as applicationActions} from "../application/duck";

import {selectors as configSelectors} from "../config/duck";

import {CommonError} from "../../componets/errors";
import FormForm from "./form-form";

import {createHashHistory} from 'history';
const history = createHashHistory({
    basename: '',
    hashType: 'noslash',
});

const Form = ({fields, onNext, onBack, id, step, name}) => {
    const application = useSelector(applicationSelectors.applicationItem);
    const applicationId = useSelector(applicationSelectors.applicationId);
    const applicationErrors = useSelector(applicationSelectors.applicationErrors);
    const config = useSelector(configSelectors.configItem);
    const dispatch = useDispatch();

    const initialMount = useRef(true);
    const [updating, setUpdating] = useState(false);

    useEffect(() => {
        const nextStep = application && application.nextStepId ? application.nextStepId : 0;
        if (nextStep < step) history.push(config.steps[nextStep].url);
    });

    useEffect(() => {

        if (initialMount.current) {
            initialMount.current = false;
        } else {
            onNext();
        }
        setUpdating(false);
    }, [application]);

    useEffect(() => {
        if (applicationErrors.length > 0) {
            setUpdating(false);
            window.scrollTo(0, 0);
        }
    }, [applicationErrors]);

    const onSubmit = (form) => {
        setUpdating(true);

        dispatch(applicationActions.updateApplicationData({
            id: applicationId,
            data: {
                value: form,
            },
            key: id,
            step: step
        }));
    };

    return (
        <Box>
            {applicationErrors.length > 0 && <CommonError/>}
            <FormForm fields={fields} name={name} id={id} onBack={onBack} step={step} onSubmit={onSubmit}
                      disabled={updating} application={application}/>
        </Box>
    );
};

Form.propTypes = {
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    fields: PropTypes.array.isRequired
};

export default Form;