import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1)
    },
    backButton: {
        background: theme.palette.background.paper,
    },
    controls: {
        marginTop: theme.spacing(3),
    }
}));

export default useStyles;