import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    wrapper: {
        display: "flex",
        alignItems: "center",
        border: "1px solid rgba(0, 0, 0, 0.23)",
        background: "white",
        padding: "16px 18px",
        borderRadius: "4px",
        fontSize: "14px",
        color: "#303131"
    },
    attention: {
        fontSize: "17px",
        marginRight: "7px"
    },
    phone: {
        marginLeft: '5px',
        color: "#4242f4",
        fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
        textDecoration: 'underline'

    },
}));