import React, {useEffect, useState} from 'react';

import {
    RadioGroup,
    FormControl,
    FormLabel,
    FormControlLabel,
    Radio as RadioField,
    FormHelperText
} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '5px',
    },
    label: {
        fontSize: '0.9rem',
        lineHeight: '1.5em',

        '& .MuiFormLabel-asterisk': {
            color: theme.palette.error.dark,
            fontSize: '0.9rem',
        }
    },
    row: {
        flexDirection: 'row'
    },
    column: {
        flexDirection: 'column'
    },
    item: {
        alignItems: 'flex-start',

        '& .MuiFormControlLabel-label': {
            paddingTop: '10px'
        }
    },
    radio: {

        '& .MuiIconButton-label': {
            color: 'inherit'
        },
    },
}));

const Radio = ({label, required, warning, options, id, onChange, disabled, errorMessage, initialValue, direction}) => {
    const classes = useStyles();
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
        onChange(id, initialValue);
    }, [initialValue]);

    const handleChange = (event) => {
        const val = event.target.value;

        setValue(val);
        onChange(id, val);
    };

    const isRequired = required === "true";
    const isError = !!errorMessage;

    return (
        <FormControl error={isError}  className={classes.root} component="fieldset">
            <FormLabel required={isRequired} className={classes.label} focused={false} component="legend">{label}</FormLabel>
            {warning}
            <RadioGroup aria-label={id} name={id} value={value} onChange={handleChange} className={
                direction === 'column' ? classes.column : classes.row
            }>
                {options.map((option, index) => (
                    <FormControlLabel className={classes.item} disabled={disabled} key={index} value={option} control={<RadioField className={classes.radio} />} label={option} />
                ))}
            </RadioGroup>
            {isError && <FormHelperText>{errorMessage}</FormHelperText>}
        </FormControl>
    );
};

Radio.propTypes = {

};

export default Radio;