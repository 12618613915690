import React from 'react';
import { useSelector } from "react-redux";
import { selectors as applicationSelectors } from "../../application/duck";
import AuthorizePayment from "../AuthorizePayment";
import SquarePayment from "../SquarePayment";

const paymentTypes = {
    square: 'SquareUp',
    authorize: 'Authorize',
};

const ExtendServicePlanComponent = (props) => {
    const application = useSelector(applicationSelectors.applicationItem);

    const renderPaymentComponent = (type) => {
        let component;
        switch (type) {
            case paymentTypes.square:
                component = <SquarePayment {...props} />;
                break;
            case paymentTypes.authorize:
                component = <AuthorizePayment {...props} />;
                break;
            default:
                component = <div>Not found.</div>;
                break;
        }

        return component;
    }

    return application ? (
        <>
            {application.plan?.title && <div>Upgrade to {application.plan.title}</div>}
            {renderPaymentComponent(application.extendServicePlanData.type)}
        </>
    ) : null;
};

export default ExtendServicePlanComponent;
