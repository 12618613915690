import { combineReducers } from 'redux';

import {reducer as configReducer} from '../modules/config/duck';
import {reducer as applicationReducer} from '../modules/application/duck';
import {reducer as paymentReducer} from '../modules/payment/duck';
import {reducer as filesReducer} from '../modules/files/duck';
import {reducer as scheduleReducer} from '../modules/schedule/duck';
import {reducer as websiteReducer} from '../modules/website/duck';
import {reducer as servicePlanReducer} from '../modules/service-plan/duck';
import {reducer as scheduleMapReducer} from '../modules/schedule-map/duck';
import {reducer as enabledDoctorReducer} from '../modules/enabled-doctors/duck';

const reducer = combineReducers({
  config: configReducer,
  application: applicationReducer,
  payment: paymentReducer,
  files: filesReducer,
  schedule: scheduleReducer,
  website: websiteReducer,
  servicePlan: servicePlanReducer,
  scheduleMap: scheduleMapReducer,
  doctor: enabledDoctorReducer,
});

export default reducer;
