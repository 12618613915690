import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

const AlreadyPaidError = () => {
    return (
        <Alert severity="error">
            <AlertTitle>You've already paid for this service. </AlertTitle>
            Please, call to our Call center to confirm your appointment <b>844-939-1267</b>
            <br />
        </Alert>
    );
};

export default AlreadyPaidError;
