import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {CircularProgress, makeStyles} from '@material-ui/core';
import PropTypes from 'prop-types';

import schema from './schema';
import useStyles from './styles';

import * as rules from '../../../../constants/rules';
import * as messages from '../../../../constants/messages';

import {actions as applicationActions, selectors as applicationSelectors,} from '../../../application/duck';
import {actions as paymentActions, selectors as paymentSelectors} from '../../duck';

import {MaskedText, Text} from '../../../../componets/fields';
import {CommonError, PaymentError} from '../../../../componets/errors';
import {Order, Promo, SquareForm, StepControls, StripeForm, Timer} from '../';
import {PromocodeDialog} from "../../promocode-dialog/PromocodeDialog";
import { LeadCreationType } from '../../../../constants/lead-creation-type';

const TIMER_MILLISECONDS = 15 * 60 * 1000;

const useModalStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTypography-root': {
            textAlign: 'center'
        },
        '& .MuiDialogActions-root': {
            justifyContent: 'center',
            padding: '25px'
        }
    }
}));

const PaymentWrapper = ({ onNext, onBack, id, name, promo, providerData }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const applicationId = useSelector(applicationSelectors.applicationId);
    const configId = useSelector(applicationSelectors.configId);
    const application = useSelector(applicationSelectors.applicationItem);
    const applicationUuid = useSelector(applicationSelectors.applicationId);
    const applicationErrors = useSelector(applicationSelectors.applicationErrors);
    const clientSecret = useSelector(paymentSelectors.clientSecret);
    const paymentId = useSelector(paymentSelectors.paymentId);
    const paymentIntent = useSelector(paymentSelectors.paymentIntent);
    const paymentErrors = useSelector(paymentSelectors.errors);
    const appliedCode = useSelector(paymentSelectors.code);

    const [screenWidth, setScreenWidth] = useState(null);
    const [form, setForm] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(null);
    const [promoCode, setPromoCode] = useState('');
    const [errors, setErrors] = useState(null);
    const [valid, setValid] = useState(true);

    const [disabledFromPaymentForm, setDisabledFromPaymentForm] = useState(false);
    const [paymentProcessing, setPaymentProcessing] = useState(false);
    const [isPromoCodeModalShowing, setIsPromoCodeModalShowing]  = useState(false);
    const [stripeClientSecret, setStripeClientSecret] = useState(null);

    const [submitClicks, setSubmitClicks] = useState(0);

    const [timerStart, setTimerStart] = useState(Date.now());

    // hook => detect size screen, when the component is initialized
    useEffect(() => {
        setScreenWidth(window.innerWidth);

        window.addEventListener('resize', () => {
            setScreenWidth(window.innerWidth);
        });

        return () => {
            dispatch(paymentActions.codeReset({}));
        };
    }, []);

    // hook => set form, when the component is initialized
    useEffect(() => {
        if (!application || !name) {
            return;
        };

        let initialValues = {};
        let initialErrors = {};

        Object.keys(schema).forEach((key, index) => {
            initialValues[key] = {
                value: schema[key].initialValue || '',
                name: schema[key].label,
                index
            };
            initialErrors[key] = '';
        });

        setForm({
            name: name,
            values: {
                ...initialValues,
                email: {
                    value: application.personalInfo.email.value,
                    name: 'Email'
                },
                firstName: {
                    value: application.personalInfo.firstName.value,
                    name: 'First name'
                },
                lastName: {
                    value: application.personalInfo.lastName.value,
                    name: 'Last name'
                },
                phoneNumber: {
                    value: application.personalInfo.phoneNumber.value,
                    name: 'Phone'
                },
            }
        });
        setErrors(initialErrors);
    }, [application, name]);

    // hook => analysis of the form for errors, when the form is changed
    useEffect(() => {
        let err = {};
        let noErrors = true;

        if (form) {
            for (let key in schema) {
                if (schema[key].required === "true" &&
                    ((typeof form.values[key].value === 'string' && form.values[key].value.trim() === "") || form.values[key].value.length === 0)) {
                    err[key] = messages['required'];
                    noErrors = false;
                } else if (schema[key].rules && !rules[schema[key].rules].isValidSync(form.values[key].value)) {
                    noErrors = false;
                    err[key] = messages[schema[key].rules];
                } else {
                    err[key] = "";
                }
            }
            setValid(noErrors);
        }

        setErrors({...errors, ...err});

    }, [form, submitClicks]);

    // hook => after successful payment
    useEffect(() => {
        if (paymentId || paymentIntent?.status === 'succeeded') {
            trackStep();
            dispatch(applicationActions.updateApplication({
                id: applicationId,
                configId: configId,
                data: {
                    value: {}
                },
                key: id,
                step: -1  // Last step
            }));
            onNext();
            localStorage.removeItem('PROMOCODE');
        }
    }, [paymentId, paymentIntent]);

    // hook => change state, after unsuccessful payment
    useEffect(() => {
        if (applicationErrors.length > 0 || paymentErrors.length > 0) {
            setPaymentProcessing(false);
            window.scrollTo(0, 0);
        }
    }, [applicationErrors, paymentErrors]);

    useEffect(() => {
        if (!formSubmitted) return;

        const isCodeApplied = !!appliedCode && (appliedCode.code === promoCode)

        if (!isCodeApplied) {
            setIsPromoCodeModalShowing(true)
            return
        }

        handleSubmit()
    }, [appliedCode])

    const handleChange = (key, value) => {
        setForm({
            ...form,
            values: {
                ...form.values,
                [key]: {
                    ...form.values[key],
                    value: value
                }
            }
        });
    };

    const handleSubmit = () => {
        setFormSubmitted(false);

        if (valid && !disabledFromPaymentForm && !paymentProcessing) {
            setFormSubmitted(true);

            if (!promoCode || appliedCode?.code) {
                setSubmitClicks(submitClicks + 1);
                return;
            }

            checkPromo();
        };
    };

    const checkPromo = () => {
        return dispatch(paymentActions.checkCode({
            clientSecret: clientSecret ?? undefined,
            promoCode: promoCode,
            applicationUuid: applicationUuid,
        }));
    };

    const isMobileMood = (value = 800) => {
        return screenWidth <= value;
    };

    const onSkipPayment = () => {
        dispatch(paymentActions.skipPayment());
    }

    const onTimerEnd = () => {
        setTimerStart(Date.now());
    };

    const isBackButtonAvailable = () => {
        return application.creationType !== LeadCreationType.BY_DOCTOR;
    }

    // analytics
    const trackStep = () => {
        /*global analytics, gtag*/
        if (typeof analytics !== 'undefined' && typeof gtag !== 'undefined' && process.env.REACT_APP_ENV === 'production') {
            analytics.track('Paid',
                {
                    Value: application.plan.price,
                    Service: application.service.name,
                    Plan: application.plan.title,
                    State: application.personalInfo.state.value
                }
            );
            gtag('event', 'Payment_selected', {'event_category': 'Payment_selected'});
        }
    };

    const getPaymentForm = () => {
        switch (providerData?.type) {
            case 'payment-square':
                if (form && providerData?.wallet) {
                    return (
                        <SquareForm
                            form={form}
                            wallet={providerData.wallet}
                            submitClicks={submitClicks}
                            isMobileMood={isMobileMood()}
                            onDisabled={(value) => setDisabledFromPaymentForm(value)}
                            onPaymentProcessing={(value) => setPaymentProcessing(value)}
                        />
                    );
                } else {
                    return (<></>);
                };
            case 'payment-stripe':
            default:
                return (
                    <StripeForm
                        submitClicks={submitClicks}
                        onGetClientSecret={(value) => setStripeClientSecret(value)}
                        onDisabled={(value) => setDisabledFromPaymentForm(value)}
                        onPaymentProcessing={(value) => setPaymentProcessing(value)}
                    />
                );
        };
    };

    const isSkipButtonAvailable = () => {
        return application.plan.appointmentMethod === 2;
    }

    return (
        <div className={classes.wrapper}>
            {(application && !application.plan) && <Redirect to='/select-plan' />}

            {(applicationErrors.length > 0) && <div className={classes.errorContainer}>
                <CommonError />
            </div>}

            {(paymentErrors.length > 0) && <div className={classes.errorContainer}>
                <PaymentError message={paymentErrors[0].message} />
            </div>}

            {(!application || !form || !screenWidth) && <CircularProgress color="inherit" size={60} style={{ margin: 'auto' }} />}

            {(application && application.plan && form && screenWidth) && <div classes={classes}>
                {!isMobileMood() && <div className={classes.container}>
                    <div className={`${classes.column} left-block`}>
                        <div className={classes.formRow}>
                            <Text
                                onChange={handleChange}
                                disabled={paymentProcessing}
                                errorMessage={errors['firstName']}
                                id="firstName"
                                initialValue={form.values.firstName.value}
                                label={schema.firstName.label}
                                placeholder={schema.firstName.placeholder}
                                required={schema.firstName.required}
                            />
                            <Text
                                onChange={handleChange}
                                disabled={paymentProcessing}
                                errorMessage={errors['lastName']}
                                id="lastName"
                                initialValue={form.values.lastName.value}
                                label={schema.lastName.label}
                                placeholder={schema.lastName.placeholder}
                                required={schema.lastName.required}
                            />
                        </div>
                        <div className={classes.formRow}>
                            <Text
                                onChange={handleChange}
                                disabled={paymentProcessing}
                                errorMessage={errors['email']}
                                id="email"
                                initialValue={form.values.email.value}
                                label={schema.email.label}
                                placeholder={schema.email.placeholder}
                                required={schema.email.required}
                            />
                            <MaskedText
                                onChange={handleChange}
                                disabled={paymentProcessing}
                                errorMessage={errors['phoneNumber']}
                                mask={schema.phoneNumber.mask}
                                id="phoneNumber"
                                initialValue={form.values.phoneNumber.value}
                                label={schema.phoneNumber.label}
                                placeholder={schema.phoneNumber.placeholder}
                                required={schema.phoneNumber.required}
                            />
                        </div>
                        <div className={classes.line}></div>
                        <div className={classes.paymentFormContainer}>
                            {getPaymentForm()}
                        </div>
                        <div className={classes.stepControlsContainer}>
                            <StepControls
                                disabled={(!valid || disabledFromPaymentForm)}
                                processing={paymentProcessing}
                                isMobileMood={isMobileMood(990)}
                                isBackButtonAvailable={isBackButtonAvailable()}
                                showSkipButton={isSkipButtonAvailable()}
                                onNext={handleSubmit}
                                onSkipPayment={onSkipPayment}
                                onBack={onBack}
                            />
                        </div>
                    </div>
                    <div className={`${classes.column} right-block`}>
                        {promo && <div className={classes.promoCodeContainer}>
                            <Promo
                                value={promoCode}
                                setValue={setPromoCode}
                                clientSecret={stripeClientSecret}
                                disabled={paymentProcessing}
                                onPromoProcessing={(value) => setPaymentProcessing(value)}
                            />
                        </div>}
                        <div
                            className={classes.orderContainer}
                            style={(!promo) ? { marginTop: '32px' } : {}}
                        >
                            <Order
                                withTimer={true}
                                timerStart={timerStart}
                                timerMilliseconds={TIMER_MILLISECONDS}
                                onTimerEnd={onTimerEnd}
                            />
                        </div>
                    </div>
                </div>}

                {isMobileMood() && <div className={classes.mobileContainer}>
                    <div className={`${classes.row} ${classes.mobileTimerContainer}`}>
                        Your appointment booking is valid for
                        <Timer
                            start={timerStart}
                            milliseconds={TIMER_MILLISECONDS}
                            onEnd={onTimerEnd}
                        />
                    </div>
                    {promo && <div className={classes.mobilePromoCodeContainer}>
                        <Promo
                            value={promoCode}
                            setValue={setPromoCode}
                            clientSecret={stripeClientSecret}
                            disabled={paymentProcessing}
                            onPromoProcessing={(value) => setPaymentProcessing(value)}
                        />
                    </div>}
                    <div className={classes.mobileOrderContainer}>
                        <Order
                            withTimer={false}
                        />
                    </div>
                    <div className={classes.mobileFormContainer}>
                        <Text
                            onChange={handleChange}
                            disabled={paymentProcessing}
                            errorMessage={errors['firstName']}
                            id="firstName"
                            initialValue={form.values.firstName.value}
                            label={schema.firstName.label}
                            placeholder={schema.firstName.placeholder}
                            required={schema.firstName.required}
                        />
                        <Text
                            onChange={handleChange}
                            disabled={paymentProcessing}
                            errorMessage={errors['lastName']}
                            id="lastName"
                            initialValue={form.values.lastName.value}
                            label={schema.lastName.label}
                            placeholder={schema.lastName.placeholder}
                            required={schema.lastName.required}
                        />
                        <Text
                            onChange={handleChange}
                            disabled={paymentProcessing}
                            errorMessage={errors['email']}
                            id="email"
                            initialValue={form.values.email.value}
                            label={schema.email.label}
                            placeholder={schema.email.placeholder}
                            required={schema.email.required}
                        />
                        <MaskedText
                            onChange={handleChange}
                            disabled={paymentProcessing}
                            errorMessage={errors['phoneNumber']}
                            mask={schema.phoneNumber.mask}
                            id="phoneNumber"
                            initialValue={form.values.phoneNumber.value}
                            label={schema.phoneNumber.label}
                            placeholder={schema.phoneNumber.placeholder}
                            required={schema.phoneNumber.required}
                        />
                    </div>
                    <div className={classes.mobileLine}></div>
                    <div className={classes.mobilePaymentFormContainer}>
                        {getPaymentForm()}
                    </div>
                    <div className={classes.mobileStepControlsContainer}>
                        <StepControls
                            disabled={!valid || disabledFromPaymentForm}
                            processing={paymentProcessing}
                            isMobileMood={isMobileMood()}
                            isBackButtonAvailable={isBackButtonAvailable()}
                            showSkipButton={isSkipButtonAvailable()}
                            onSkipPayment={onSkipPayment}
                            onNext={handleSubmit}
                            onBack={onBack}
                        />
                    </div>
                </div>}
            </div>}
            <PromocodeDialog
                isShown={isPromoCodeModalShowing}
                setIsShown={(res) => {
                    setFormSubmitted(false);
                    setIsPromoCodeModalShowing(res);
                }}
                continueWithoutPromo={() => {
                    setSubmitClicks(submitClicks + 1);
                    setIsPromoCodeModalShowing(false)
                }}
            ></PromocodeDialog>
        </div>
    );
};

PaymentWrapper.propTypes = {
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    promo: PropTypes.any,
    providerData: PropTypes.shape({
        type: PropTypes.oneOf(['payment-stripe', 'payment-square', 'payment-authorize']).isRequired,
        wallet: PropTypes.oneOf(['mango', 'ezcare', 'medvidi', 'ezcare_medvidi']),
    }).isRequired,
};

export default PaymentWrapper;
