import React from 'react'
import {Alert, AlertTitle} from '@material-ui/lab'

const TooBigFile = () => (
    <Alert severity="error">
        <AlertTitle>Uploaded file is too large. </AlertTitle>
        Please upload the file less than 10mb
    </Alert>
);

export default TooBigFile
