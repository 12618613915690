import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1)
    },
    backButton: {
        background: theme.palette.background.paper,
    },
    controls: {
        marginTop: theme.spacing(3),
    },
    terms: {
       fontFamily: theme.typography.fontFamily,
       fontSize: theme.typography.fontSize,
       fontWeight: theme.typography.fontWeightRegular,
       lineHeight: '1.5em',
       color: theme.palette.text.secondary
    }
}));

export default useStyles;