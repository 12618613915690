import React, {useEffect, useState} from 'react';

import {Box, Grid, Divider} from '@material-ui/core';

import Promo from '../../payment/version-without-conditions/promo';
import Order from '../../payment/version-without-conditions/order';
import StepControls from '../../payment/version-without-conditions/step-controls';

import {MaskedText, Text} from "../../../componets/fields";

import {useDispatch, useSelector} from "react-redux";

import * as rules from '../../../constants/rules';
import * as messages from '../../../constants/messages';

import {selectors as applicationSelectors} from "../../application/duck";
import {actions as paymentActions, selectors as paymentSelectors} from "../../payment/duck";
import {actions as websiteActions, selectors as websiteSelectors} from "../../website/duck";

import schema from './schema'
import useStyles from '../styles'
import { LeadCreationType } from '../../../constants/lead-creation-type';
import {PromocodeDialog} from "../promocode-dialog/PromocodeDialog";


const AuthorizeForm = ({updating, blockButtonNext, onBack, onSubmit, onChangeForm, step, name, promo}) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const application = useSelector(applicationSelectors.applicationItem);
    const appliedCode = useSelector(paymentSelectors.code);
    const applicationUuid = useSelector(applicationSelectors.applicationId);
    const websiteRefundPolicy = useSelector(websiteSelectors.refundPolicy);
    const clientSecret = useSelector(paymentSelectors.clientSecret)

    const [promoCode, setPromoCode] = useState('');
    const [form, setForm] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [errors, setErrors] = useState(null);
    const [initiallySubmitted, setInitiallySubmitted] = useState(false);
    const [valid, setValid] = useState(false);
    const [screenWidth, setScreenWidth] = useState(null);
    const [isPromoCodeModalShowing, setIsPromoCodeModalShowing] = useState(false);

    useEffect(() => {
        let initialValues = {};
        let initialErrors = {};

        Object.keys(schema).forEach((key, index) => {
            initialValues[key] = {value: schema[key].initialValue || "", name: schema[key].label, index};
            initialErrors[key] = "";
        });

            setForm({
                name: name, values: {
                    ...initialValues,
                    email: {
                        value: application.personalInfo.email?.value || application.personalInfo.email,
                        name: "Email"
                    },
                    firstName: {
                        value: application.personalInfo.firstName?.value || application.personalInfo.firstName,
                        name: "First name"
                    },
                    lastName: {
                        value: application.personalInfo.lastName?.value || application.personalInfo.lastName,
                        name: "Last name"
                    },
                    phoneNumber: {
                        value: application.personalInfo.phoneNumber?.value || application.personalInfo.phoneNumber,
                        name: "Phone"
                    },
                }
            });

        setErrors(initialErrors);

        if (!websiteRefundPolicy) {
            dispatch(websiteActions.getWebsiteData({ code: application.websiteCode }));
        }

        setScreenWidth(window.innerWidth);

        window.addEventListener('resize', () => {
            setScreenWidth(window.innerWidth);
        });
    }, []);

    useEffect(() => {
        let err = {};
        let noErrors = true;

        if (form) {
            for (let key in schema) {
                if (schema[key].required === "true" &&
                    ((typeof form.values[key].value === 'string' && form.values[key].value.trim() === "") || form.values[key].value.length === 0)) {
                    err[key] = messages['required'];
                    noErrors = false;
                } else if (schema[key].rules && !rules[schema[key].rules].isValidSync(form.values[key].value)) {
                    noErrors = false;
                    err[key] = messages[schema[key].rules];
                } else {
                    err[key] = "";
                }
            }
            setValid(noErrors);
        }

        if (initiallySubmitted) {
            setErrors({...errors, ...err});
        }
    }, [form, initiallySubmitted]);

    useEffect(() => {
        if (!formSubmitted) return;

        const isCodeApplied = !!appliedCode && (appliedCode.code === promoCode)

        if (!isCodeApplied) {
            setIsPromoCodeModalShowing(true)
            return
        }

        onSubmit(form);
    }, [appliedCode])

    const handleSubmit = () => {
        if (!initiallySubmitted) setInitiallySubmitted(true);

        setFormSubmitted(false);

        if (valid) {
            setFormSubmitted(true);
            (promo && promoCode) ? checkPromo() : onSubmit(form);
        } else {
            window.scrollTo(0, 0);
        }
    };

    const checkPromo = () => {
        return dispatch(paymentActions.checkCode({
            clientSecret: clientSecret ?? undefined,
            promoCode: promoCode,
            applicationUuid: applicationUuid,
        }));
    };

    const handleChange = (key, value) => {
        const _form = {...form, values: {...form.values, [key]: {...form.values[key], value: value}}};
        setForm(_form);
        onChangeForm(_form);
    };

    const isMobileMood = (value = 800) => {
        return screenWidth <= value;
    };

    const isBackButtonAvailable = () => {
        return application.creationType !== LeadCreationType.BY_DOCTOR;
    }

    const onSkipPayment = () => {
        dispatch(paymentActions.skipPayment());
    }

    const isSkipButtonAvailable = () => {
        return application.plan.appointmentMethod === 2;
    }

    return (
        <Box>
            <Grid container spacing={3} className={classes.container}>
                <Grid item xs={12} sm={8}>
                    { websiteRefundPolicy && (
                        <div
                            className={classes.refundPolicy}
                            dangerouslySetInnerHTML={{__html: websiteRefundPolicy}}
                        ></div>
                    )}
                    {form && <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Text onChange={handleChange}
                                  disabled={updating}
                                  errorMessage={errors['firstName']}
                                  id="firstName"
                                  initialValue={form.values.firstName.value}
                                  label={schema.firstName.label}
                                  placeholder={schema.firstName.placeholder}
                                  required={schema.firstName.required}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Text onChange={handleChange}
                                  disabled={updating}
                                  errorMessage={errors['lastName']}
                                  id="lastName"
                                  initialValue={form.values.lastName.value}
                                  label={schema.lastName.label}
                                  placeholder={schema.lastName.placeholder}
                                  required={schema.lastName.required}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Text onChange={handleChange}
                                  disabled={updating}
                                  errorMessage={errors['email']}
                                  id="email"
                                  initialValue={form.values.email.value}
                                  label={schema.email.label}
                                  placeholder={schema.email.placeholder}
                                  required={schema.email.required}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MaskedText onChange={handleChange}
                                        disabled={updating}
                                        errorMessage={errors['phoneNumber']}
                                        mask={schema.phoneNumber.mask}
                                        id="phoneNumber"
                                        initialValue={form.values.phoneNumber.value}
                                        label={schema.phoneNumber.label}
                                        placeholder={schema.phoneNumber.placeholder}
                                        required={schema.phoneNumber.required}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider variant="fullWidth" className={classes.divider}/>
                        </Grid>
                        <Grid item xs={12}>
                            <MaskedText onChange={handleChange}
                                        disabled={updating}
                                        errorMessage={errors['cardNumber']}
                                        id="cardNumber"
                                        label={schema.cardNumber.label}
                                        mask={schema.cardNumber.mask}
                                        hasIcon={true}
                                        iconPath={process.env.REACT_APP_URL + (errors['cardNumber'] ?
                                            '/icons/invalid-card-number.svg' : '/icons/credit-card.svg')}
                                        placeholder={schema.cardNumber.placeholder}
                                        required={schema.cardNumber.required}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <MaskedText onChange={handleChange}
                                        disabled={updating}
                                        errorMessage={errors['expirationDate']}
                                        id="expirationDate"
                                        label={schema.expirationDate.label}
                                        mask={schema.expirationDate.mask}
                                        placeholder={schema.expirationDate.placeholder}
                                        required={schema.expirationDate.required}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <MaskedText onChange={handleChange}
                                        disabled={updating}
                                        errorMessage={errors['cvv']}
                                        id="cvv"
                                        initialValue=""
                                        label={schema.cvv.label}
                                        mask={schema.cvv.mask}
                                        placeholder={schema.cvv.placeholder}
                                        hasIcon={true}
                                        iconPath={process.env.REACT_APP_URL +  (errors['cvv'] ?
                                            '/icons/invalid-card-cvc.svg' : '/icons/credit-card-cvc.svg')}
                                        required={schema.cvv.required}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Text onChange={handleChange}
                                  disabled={updating}
                                  errorMessage={errors['zip']}
                                  id="zip"
                                  initialValue=""
                                  label={schema.zip.label}
                                  placeholder={schema.zip.placeholder}
                                  required={schema.zip.required}/>
                        </Grid>
                        <Grid item xs={12}>
                            <StepControls
                                step={step}
                                processing={false}
                                disabled={updating}
                                blockButtonNext={blockButtonNext}
                                showSkipButton={isSkipButtonAvailable()}
                                onBack={onBack}
                                isMobileMood={isMobileMood(990)}
                                isBackButtonAvailable={isBackButtonAvailable()}
                                onNext={handleSubmit}
                                onSkipPayment={onSkipPayment}
                            />
                        </Grid>
                    </Grid>}
                </Grid>
                <Grid item xs={12} sm={4}>
                    {promo && <div className={classes.promocode}>
                        <Promo
                            value={promoCode}
                            setValue={setPromoCode}
                            disabled={updating}
                        />
                    </div>}

                    <Order
                        withTimer={false}
                        application={application}
                        appliedCode={appliedCode}
                    />
                </Grid>
            </Grid>
            <PromocodeDialog
                isShown={isPromoCodeModalShowing}
                setIsShown={(res) => {
                    setIsPromoCodeModalShowing(res);
                    setFormSubmitted(false);
                }}
                continueWithoutPromo={() => onSubmit(form)}
            />
        </Box>
    );
};

AuthorizeForm.propTypes = {

};

export default AuthorizeForm;
