import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment'
import moment from "moment";


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '10px',
        width: '100%',

        '& .MuiInputLabel-outlined': {
            lineHeight: 1.4,
            position: 'relative',
            marginLeft: '-13px;',
        },

        '& .Mui-focused': {
            '& .MuiInputBase-input': {
                borderColor: 'inherit',
                webkitBoxShadow: 'none',
                boxShadow: 'none'
            }
        },

        '& .MuiFormHelperText-contained': {
            marginLeft: 0,
            marginTop: '5px'
        },

        '& .MuiFormLabel-root': {
            fontSize: '1.2rem',

            '& .MuiFormLabel-asterisk': {
                color: theme.palette.error.dark,
                fontSize: '1.2rem',
            }
        },

        '& .MuiOutlinedInput-root': {
            marginTop: '-5px',
            background: theme.palette.background.paper,

            '& .MuiInputBase-input': {
                padding: '10.5px 14px',
            },

            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.23)',
            },

            '&.Mui-error': {
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.error.main
                }
            },

            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.main,
            },
            '&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.error.main
            },
        },
    }
}));

const DateSelect = ({ id, format, minDate, maxDate, label, required, disabled, onChange, initialValue, errorMessage, placeholder }) => {
    const classes = useStyles();
    const [dateValue, setDateValue] = useState(initialValue ? moment(initialValue) : null);

    const changeDateValue = (date) => {
        let selectedDate = date;
        if (minDate && date?.isValid() && minDate.isAfter(selectedDate)) {
            selectedDate = minDate;
        }

        if (maxDate && date?.isValid() && maxDate.isBefore(selectedDate)) {
            selectedDate = maxDate;
        }

        if (onChange) {
            onChange(id, selectedDate ? selectedDate.isValid() ? selectedDate.format('MM/DD/YYYY') : 'Invalid Date' : '');
        }

        setDateValue(selectedDate);
    }

    return <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
            classes={classes}
            id={id}
            label={label}
            variant="inline"
            inputVariant="outlined"
            placeholder={placeholder}
            format={format}
            initialFocusedDate={maxDate}
            autoOk={true}
            InputLabelProps={{
                shrink: true,
                required: true
            }}
            InputProps={{
                notched: false
            }}
            value={dateValue}
            minDate={minDate}
            maxDate={maxDate}
            disabled={disabled}
            error={!!errorMessage}
            helperText={!!errorMessage ? errorMessage : ''}
            onChange={changeDateValue}
            requred={required}
        />
    </MuiPickersUtilsProvider>;
};

export default DateSelect;