import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    column: {
        display: 'flex',
        flexDirection: 'column', 
    },
    row: {
        display: 'flex',
        flexDirection: 'row', 
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '300px',
        padding: '24px 0',

        '& .MuiInputBase-input': {
            borderRadius: '10px',
        },

        '& .MuiInputBase-root': {
            borderRadius: '10px',

            '& input': {
                padding: '17px 10px',
            }
        },
    },
    errorContainer: {
        marginBottom: '20px',
    },

    // style for PC
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',

        '& .left-block': {
            marginRight: '20px',
            flex: '2',
        },

        '& .right-block': {
            flex: '1',
            minWidth: '365px',
        },
    },
    formRow: {
        display: 'flex',
        flexDirection: 'row',

        '& div:first-child': {
            marginRight: '20px',
        },
    },
    line: {
        marginTop: '30px',
        borderTop: '0.5px solid #9E9E9E',
        height: '1px',
    },
    paymentFormContainer: {
        marginTop: '30px',
    },
    promoCodeContainer: {
        marginTop: '32px',
    },
    orderContainer: {
        marginTop: '25px',
    },

    // style for mobile
    mobileContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    mobileTimerContainer: {
        flexWrap: 'wrap',
        textAlign: 'center',
        justifyContent: 'center',
        padding: '20px 38px',
        background: '#FCE2C5',
        color: '#821111',

        '& span': {
            marginLeft: '5px',
        },
    },
    mobilePromoCodeContainer: {
        marginTop: '20px',
    },
    mobileOrderContainer: {
        marginTop: '20px',
    },
    mobileFormContainer: {
        marginTop: '40px',
    },
    mobileLine: {
        marginTop: '20px',
        borderTop: '0.5px solid #9E9E9E',
        height: '1px',
    },
    mobilePaymentFormContainer: {
        marginTop: '20px',
    },
    stepControlsContainer: {
        marginTop: '20px'
    }
}));

export default useStyles;
