import Axios from 'axios';

const api = Axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL + '/website/',
});

const getWebsiteData = async ({code}) =>  {
  return await api.get(`${code}`);
};

export const operations = {
  getWebsiteData,
};
