const schema = {
    firstName: {
        label: "First name",
        placeholder: "Please type your first name",
        required: "true",
        rules: "firstName",
    },
    lastName: {
        label: "Last name",
        placeholder: "Please type your last name",
        required: "true",
        rules: "lastName",
    },
    email: {
        label: "Email",
        placeholder: "Enter valid email address",
        required: "true",
        rules: "userEmail",
    },
    phoneNumber: {
        label: "Phone",
        placeholder: "(234) 567 8900",
        mask: "usPhone",
        required: "true",
        rules: "usPhone",
    },
    zip: {
        label: 'ZIP code',
        placeholder: 'Please type your ZIP code',
        required: 'true',
        rules: 'zip',
    },
    dateOfBirth: {
        label: 'Date of birth',
        required: 'true',
        format: 'MM/DD/YYYY',
        placeholder: 'Enter date of birth'
    },
    gender: {
        label: "Gender",
        initialValue: "",
        options: [
            "Male",
            "Female",
            "Other"
        ],
        required: "true"
    }
};

export default schema;