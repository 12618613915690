const schema = {
    cardNumber: {
        label: "Card number",
        placeholder: "**** **** **** ****",
        mask: "cardNumber",
        required: "false",
    },
    expirationDate: {
        label: "Expiration date",
        placeholder: "MM/YY",
        mask: "expirationDate",
        required: "false",
    },
    cvv: {
        label: "CVV",
        placeholder: "***",
        mask: "cvv",
        //rules: "cvv",
        required: "false",
    },
    zip: {
        label: "Postal code",
        placeholder: "Zip code",
        required: "false",
       // rules: "zip",
    },
    electronicSignature: {
        label: "I have agreed to submit this payment and application by electronic means. By signing this form electronically, I understand that an electronic signature has the same legal effect and can be enforced in the same way as a written signature.",
        placeholder: "",
        required: "true",
        options: [
            "By checking this box, I am electronically signing this Credit Card Authorization and application."
        ],
    },
};

export default schema;