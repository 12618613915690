import {call, put, takeLatest} from 'redux-saga/effects';

import {operations} from './operations';
import {actions} from './modules';

function* watchWebsiteActions() {
    yield takeLatest(actions.getWebsiteData, getWebsiteData);
}

function* getWebsiteData({payload}) {
    const {code} = payload;
    try {
        const response = yield call(operations.getWebsiteData, {code});

        yield put(actions.getWebsiteDataSuccess(response.data));
    } catch (error) {
        const response = {
            data: error.response.data,
            status: error.response.status,
            statusText: error.response.statusText,
        };
        yield put(actions.getWebsiteDataFail(response));
    }
}

export const sagas = watchWebsiteActions;