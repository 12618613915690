import React, {useEffect, useState, useRef} from 'react';

import SignatureCanvas from 'react-signature-canvas'
import {makeStyles} from "@material-ui/core/styles";
import {Box, FormControl, FormLabel, Grid} from "@material-ui/core";
import {useSelector, useDispatch} from "react-redux";

import {actions as filesActions} from "../../modules/files/duck";
import {selectors as filesSelectors} from "../../modules/files/duck";


const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: '0.9rem',
        lineHeight: '1.5em',
        marginBottom: theme.spacing(1),

        '& .MuiFormLabel-asterisk': {
            color: theme.palette.error.dark,
            fontSize: '0.9rem',
        }
    },

    canvas: {
        borderRadius: '5px',
        borderWidth: '2px',
        borderStyle: 'dashed',
        borderColor: '#C8C8C8',
        width: '100%',
        height: '200px'
    }
}));

const Signature = ({label, placeholder, id, required, errorMessage, onChange, disabled, initialValue, disableForm, enableForm}) => {
    const classes = useStyles();
    const [value, setValue] = useState('');
    const [signing, setSigning] = useState(false);
    const [idle, setIdle] = useState(false);

    const signingRef = useRef(signing);
    signingRef.current = signing;
    const [canvasRef, setCanvasRef] = useState('');

    const dispatch = useDispatch();
    const signatureUrl = useSelector(filesSelectors.signatureUrl);

    useEffect(() => {

    }, []);

    useEffect(() => {
        if (signatureUrl) {
            enableForm();
            setValue(signatureUrl);
            onChange(id, signatureUrl);
        }
    }, [signatureUrl]);

    useEffect(() => {
        dispatch(filesActions.clearSignature());
    }, [value]);

    const handleChange = () => {
        if (canvasRef) {
            canvasRef.getCanvas().toBlob(handleBlob);
        }
    };

    const handleEnd = () => {
        setSigning(false);
        const timeout = setTimeout(() => {
            if (!signingRef.current) handleChange();
        }, 2000);
        setIdle(timeout);
    };

    const handleBegin = () => {
        setSigning(true);
        clearTimeout(idle);
    };

    const handleBlob = blob => {
        let formData = new FormData();
        formData.append('file', blob, 'signature.png');

        disableForm();
        dispatch(filesActions.uploadSignatureFile({form: formData}));
    }


    const isRequired = required === "true";
    const isError = errorMessage !== '';

    return (
        <Box>
            <br/><br/>
            <FormControl fullWidth error={isError} component="fieldset" className={classes.root}>
                <FormLabel
                    required={isRequired}
                    className={classes.label}
                    focused={false}
                    component="legend"
                >{label}</FormLabel>
                <Box className={classes.canvas}>
                    <SignatureCanvas penColor='black'
                        canvasProps={{ className: 'sigCanvas', style: { width: '100%', height: '100%' }}}
                        onEnd={handleEnd}
                        onBegin={handleBegin}
                        ref={ref => {
                            setCanvasRef(ref)
                        }}
                    />
                </Box>
            </FormControl>
        </Box>
    );
};

Signature.propTypes = {};

export default Signature;