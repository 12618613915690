import React, {useState, useEffect} from 'react';

import {TextField, MenuItem} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {selectors as enabledDoctorSelectors} from "../../modules/enabled-doctors/duck";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '10px',

        '& .MuiInputLabel-outlined': {
            lineHeight: 1.4,
            fontSize: '1.2rem',
            position: 'relative',
            marginLeft: '-13px;',
        },

        '& .MuiFormHelperText-contained': {
            marginLeft: 0,
            marginTop: '5px'
        },

        '& .MuiFormLabel-root .MuiFormLabel-asterisk': {
            color: theme.palette.error.dark,
            fontSize: '1.2rem',
        },

        '& .MuiOutlinedInput-root': {
            marginTop: '-5px',
            background: theme.palette.background.paper,
            
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.23)',
            },

            '&.Mui-error': {
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.error.main
                }
            },

            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.main,
            },
            '&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.error.main
            },
        },
    },

}));

const DoctorSelect = ({label, id, onChange, required, errorMessage, disabled, initialValue}) => {
    const classes = useStyles();
    const [doctors, setDoctors] = useState(['Please choose...', 'Any doctor']);
    const [value, setValue] = useState(initialValue || doctors[0]);

    const enabledDoctors = useSelector(enabledDoctorSelectors.enabledDoctors);

    useEffect(() => {
        if (enabledDoctors?.length) {
            const options = enabledDoctors.map(enabledDoctor => enabledDoctor.fullName);
            setDoctors(['Please choose...', 'Any doctor', ...options]);
        }
    }, [enabledDoctors]);

    const handleChange = (event) => {
        const value = event.target.value;
        setValue(value);
        onChange(id, value);
    };

    const isRequired = required === "true";
    const isError = errorMessage !== '';

    const stringOption = (option, index) => (
        <MenuItem key={index} value={option} disabled={index === 0}>{option}</MenuItem>)

    return (
        <TextField select
                   classes={classes}
                   fullWidth
                   size="small"
                   id={id}
                   variant="outlined"
                   disabled={disabled}
                   value={value}
                   onChange={handleChange}
                   InputLabelProps={{shrink: true, required: isRequired}}
                   InputProps={{notched: false}}
                   error={isError}
                   helperText={isError ? errorMessage : ''}
                   label={label}>
            {doctors?.length && doctors.map((option, index) => stringOption(option, index))}
        </TextField>
    );
};

DoctorSelect.propTypes = {

};

export default DoctorSelect;