import React from 'react'
import {Box, Typography} from "@material-ui/core";
import {useStyles} from './styles'
import {useSelector} from "react-redux";
import {selectors as applicationSelectors} from "../application/duck";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const stripAllNonDigitsChars = (phone) => {
    return phone.replace(/\D/g, '')
}

export const ContactUs = () => {

    const classes = useStyles()

    const phone = useSelector(applicationSelectors.websitePhone);

    return (
        <Box>
            {
                phone && (
                    <Typography>
                        <div className={classes.wrapper}>
                            <ErrorOutlineIcon className={classes.attention}/>
                            <div>
                                <span>
                                    If you have any questions about the type of visit, please contact us at
                                    <a
                                        className={classes.phone}
                                        href={`tel:+${stripAllNonDigitsChars(phone)}`}>
                                        {phone}
                                    </a>
                                </span>
                            </div>
                        </div>
                    </Typography>
                )
            }
        </Box>
    )
}