export const firstName = 'First name should be minimum 2 and maximum 20 symbols';
export const lastName = 'Last name should be minimum 2 and maximum 20 symbols';
export const userEmail = 'Please enter a valid email';
export const usPhone = 'Please enter a correct phone number';
export const required = 'This field is required';
export const signature = 'This field is required';
export const signatureAcknowledgement = 'This field is required';
export const cardNumber = 'Incorrect number';
export const expirationDate = 'Your card\'s expiration date is incomplete.';
export const cvv = 'Your card\'s security code is incomplete.';
export const zip = 'Zip is not valid';
export const ssn = 'Wrong SSN';
export const cardNumberWithoutSpace = 'Your card number is incomplete.';
export const invalidDate = 'Please enter a correct Date of birth';
