import React, {useEffect, useState, useRef} from 'react'
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types'

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import StripeForm from "./stripe-form";

import {Box} from '@material-ui/core'

import {useSelector, useDispatch} from "react-redux"
import {selectors as applicationSelectors} from "../application/duck"
import {actions as applicationActions} from "../application/duck"

import {selectors as paymentSelectors} from "../payment/duck"
import {actions as paymentActions} from "../payment/duck"

import {CommonError, PaymentError} from "../../componets/errors"

const StripePayment = ({onNext, onBack, id, step, name, promo}) => {
    const application = useSelector(applicationSelectors.applicationItem);
    const applicationId = useSelector(applicationSelectors.applicationId);
    const applicationErrors = useSelector(applicationSelectors.applicationErrors);
    const configId = useSelector(applicationSelectors.configId);

    const paymentErrors = useSelector(paymentSelectors.errors);
    const clientSecret = useSelector(paymentSelectors.clientSecret);

    const dispatch = useDispatch();

    const initialMount = useRef(true);
    const [updating, setUpdating] = useState(false);
    const [form, setForm] = useState(null);
    const [stripeOptions, setStripeOptions] = useState(null);
    const [stripePromise, setStripePromise] = useState(null);

    useEffect(() => {
        const promise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
        setStripePromise(promise);
    }, []);

    useEffect(() => {

        return () => {
            dispatch(
                paymentActions.resetPaymentState()
            );
        };
    }, []);

    useEffect(() => {
        if (initialMount.current) {
            initialMount.current = false;
            dispatch(paymentActions.createIntent({
                applicationId,
            }));
        } else {
            setUpdating(false);
            onNext();
        }
    }, [application]);

    useEffect(() => {
        if (clientSecret) {
            const options = {
                clientSecret: clientSecret,
                locale: 'en-GB',
                loader: 'always',
                appearance: {
                    theme: 'stripe',
                    variables: {
                        colorText: '#666666'
                    }
                },
                fonts: [
                    {
                    family: 'Roboto',
                    weight: '400',
                }]
            }
            setStripeOptions(options);
        }
    }, [clientSecret]);

    useEffect(() => {
        if (applicationErrors.length > 0 || paymentErrors.length > 0) {
            setUpdating(false);
            window.scrollTo(0, 0);
        }
    }, [applicationErrors, paymentErrors]);

    useEffect(() => {
        if (form) {
            setUpdating(true);
            trackStep();
            dispatch(applicationActions.updateApplication({
                id: applicationId,
                configId: configId,
                data: {
                    value: {}
                },
                key: id,
                step: -1  // Last step
            }));
        }
    }, [form]);

    const handleSubmit = (submittedForm) => {
        setForm(submittedForm);
    };

    const trackStep = () => {
        /*global analytics, gtag*/
        if (typeof analytics !== 'undefined' && typeof gtag !== 'undefined' && process.env.REACT_APP_ENV === 'production') {
            analytics.track('Paid',
                {
                    Value: application.plan.price,
                    Service: application.service.name,
                    Plan: application.plan.title,
                    State: application.personalInfo.state.value
                }
            );
            gtag('event', 'Payment_selected', {'event_category': 'Payment_selected'});
        }
    };

    return (
        <Box>
            {applicationErrors.length > 0 && <CommonError/>}
            {paymentErrors.length > 0 && <PaymentError message={paymentErrors[0].message}/>}
            {application && application.plan && stripePromise && stripeOptions &&
                    <Elements stripe={stripePromise} options={stripeOptions}>
                        <StripeForm
                            promo={promo}
                            name={name}
                            onBack={onBack}
                            onSubmit={handleSubmit}
                            step={step}
                            disabled={updating}
                            application={application}
                        />
                    </Elements>}

            {application && !application.plan && <Redirect to='/select-plan'/>}
        </Box>
    );
};

StripePayment.propTypes = {
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
};

export default StripePayment;
