import React, {useEffect, useState} from 'react';
import {Switch, Route, Redirect} from "react-router-dom";

import {selectors as configSelectors} from "../config/duck";
import {selectors as applicationSelectors} from "../application/duck";
import {actions as applicationsActions} from "../application/duck";

import {
    Box,
    Step,
    StepLabel,
    Stepper,
    Hidden,
} from "@material-ui/core";

import FormStep from "../step"
import ThankYou from "../thank-you"

import {useDispatch, useSelector} from "react-redux";
import {createHashHistory} from 'history';
import {makeStyles} from "@material-ui/core/styles";
import analytics from '../analytics';

const history = createHashHistory({
    basename: '',
    hashType: 'noslash',
});

const useStyles = makeStyles((theme) => ({
    stepper: {
        background: 'transparent',
    }
}));

const Steps = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const config = useSelector(configSelectors.configItem);
    const currentStep = useSelector(applicationSelectors.applicationStep);
    const application = useSelector(applicationSelectors.applicationItem);

    const nextStep = application && application.nextStepId  ? application.nextStepId : 0;

    const [section, setSection] = useState(0);
    const [steps, setSteps] = useState([]);
    const [sections, setSections] = useState([]);

    useEffect(() => {
        if (config) {
            if (application?.extendServicePlanData && !application?.extendServicePlanData.successful) {
                history.push('/extend-service-plan');
            } else if (!config.editable && application && application.payment) {
                history.push('/thank-you');
            }

            let stepsArray = [];
            config.steps.forEach((stepItem) => {
                if (stepItem.id === 'service') {
                    if (props.predefinedService) {
                        const found = stepItem.field.options.find((item) => {
                            return item.code === props.predefinedService;
                        });
                        if (found) {
                            dispatch(applicationsActions.setPredefinedService({predefinedService: found}));
                            return;
                        }
                    }
                    if (stepItem.field.options.length === 1) {
                        dispatch(applicationsActions.setPredefinedService({predefinedService: stepItem.field.options[0]}));
                        return;
                    }
                }
                stepsArray.push(stepItem);
            });
            setSteps(stepsArray);
            setSections(config.sections);
        }
    }, [config]);
    
    useEffect(() => {
        findCurrentSection();
    }, [steps]);

    useEffect(() => {
        findCurrentSection();
    }, [currentStep]);

    const findCurrentSection = () => {
        if (config) {
            let currentSection;

            if (currentStep === -1) {
                currentSection = currentStep;
            } else {
                currentSection = sections.findIndex(item => item.id === steps[currentStep].sectionId);
            }
            setSection(currentSection);
        }
    }

    const handleBack = () => {
        const targetStep = currentStep - 1;
        history.push(steps[targetStep].url);
        window.scrollTo(0, 0);
    };

    const handleNext = (applicationId = null) => {
        const targetStep = currentStep + 1;

        if (targetStep < steps.length) {
            if (applicationId) {
                history.push(steps[targetStep].url);
            } else {
                history.push(steps[targetStep].url);
            }
        } else {
            dispatch(applicationsActions.setStep({index: -1}));
            history.push('thank-you');
        }
        window.scrollTo(0, 0);
    };
    return (
        <Box>
            {steps.length > 0 &&
            <Box>
                {!application?.extendServicePlanData && (
                    <Hidden xsDown>
                        <Stepper className={classes.stepper} activeStep={section} alternativeLabel>
                            {sections.map((sectionItem, index) => (
                                <Step key={index}>
                                    <StepLabel>{sectionItem.header}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Hidden>
                )}
                <Switch>
                    {nextStep < steps.length && !(!config.editable && application && application.payment) && <Redirect exact from="/" to={steps[nextStep].url}/>}
                    {steps.map((step, index) => (
                        <Route key={index} path={step.url}>
                            <FormStep step={step} index={index} onNext={handleNext} onBack={handleBack}/>
                        </Route>
                    ))}
                    <Route path="/thank-you">
                        <ThankYou/>
                    </Route>
                </Switch>
                <br/><br/>
            </Box>
            }
        </Box>
    );
};

Steps.propTypes = {};


export default Steps;