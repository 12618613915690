import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';

import { CommonError } from '../../componets/errors';
import PersonalForm from './personal-form';
import { Box } from '@material-ui/core';

import { selectors as applicationSelectors } from '../application/duck';
import { selectors as configSelectors } from '../config/duck';
import { actions as applicationActions } from '../application/duck';
import { actions as servicePlanActions } from '../service-plan/duck';

const Personal = ({ onNext, onBack, id, step }) => {
    const application = useSelector(applicationSelectors.applicationItem);
    const applicationId = useSelector(applicationSelectors.applicationId);
    const predefinedService = useSelector(applicationSelectors.predefinedService);
    const amoConsts = useSelector(configSelectors.amoConsts);
    const applicationErrors = useSelector(applicationSelectors.applicationErrors);
    const personalFormValidationErrors = useSelector(applicationSelectors.personalFormValidationErrors);
    const configId = useSelector(applicationSelectors.configId);
    const config = useSelector(configSelectors.configItem);

    const dispatch = useDispatch();

    const initialMount = useRef(true);
    const [updating, setUpdating] = useState(false);
    const [cookies, setCookie] = useCookies([
        'applicationId',
        'dctrApplication',
        'leadId',
        'contactId',
    ]);

    useEffect(() => {
        if (initialMount.current) {
            initialMount.current = false;
        } else {
            // https://app.asana.com/0/1203646198557494/1204594360516811
            // there is a conflict with API's events
            // trackFb(application);

            if (!applicationId) {
                setCookie(
                    'dctrApplication',
                    {
                        id: application._id,
                        service: predefinedService ? predefinedService.code : null,
                    },
                    { maxAge: 60 * 60 * 24 * 21 }
                ); // 21 days

                setCookie('leadId', application.leadId, { path: '/' });
                setCookie('contactId', application.contactId, { path: '/' });
                setCookie('surveySparrowFakeEmail', application.surveySparrowFakeEmail, { path: '/' });

                dispatch(applicationActions.setApplicationId({ id: application._id }));
                onNext(application._id);
            } else {
                onNext();
            }
            setUpdating(false);
        }
    }, [application]);

    useEffect(() => {
        if (applicationErrors.length|| personalFormValidationErrors.length) {
            setUpdating(false);
            window.scrollTo(0, 0);
        }
    }, [applicationErrors, personalFormValidationErrors]);

    // https://app.asana.com/0/1203646198557494/1204594360516811
    // there is a conflict with API's events
    // const trackFb = (application) => {
    //     /*global fbq*/
    //     if (typeof fbq !== 'undefined' && process.env.REACT_APP_ENV === 'production') {
    //         fbq(
    //             'track',
    //             'Lead',
    //             {
    //                 contents: [
    //                     {
    //                         id: application.leadId,
    //                         quantity: 1,
    //                     },
    //                 ],
    //                 content_type: 'product',
    //             },
    //             {
    //                 eventID: application.leadId,
    //             }
    //         );
    //     }
    // };

    const trackStep = (values, utmData) => {
        /*global analytics, gtag*/
        if (
            typeof analytics !== 'undefined' &&
            typeof gtag !== 'undefined' &&
            process.env.REACT_APP_ENV === 'production'
        ) {
            analytics.track('Lead', {
                State: null,
                Zip: values.zip.value,
                Sex: values.gender.value,
                // Name: values.firstName.value + ' ' + values.firstName.lastName,
                // Email: values.email.value,
                // Phone: values.phone.value,
                utmSource: utmData.source,
                utmMedium: utmData.utmMedium,
                utmCampaign: utmData.utmCampaign,
                utmContent: utmData.utmContent,
                gClientId: utmData.gClientId,
            });
            gtag('event', 'Lead', { event_category: 'Lead' });
        }
    };

    const onSubmit = (values, utmData, agreementStatus) => {
        setUpdating(true);
        trackStep(values, utmData);

        if (applicationId) {
            dispatch(
                applicationActions.updateApplication({
                    id: applicationId,
                    configId: configId,
                    data: {
                        values: values,
                        receiveCallsAndMessagesAgreementStatus: agreementStatus,
                        setOfAgreementsStatus: true
                    },
                    key: id,
                    step: step,
                })
            );
        } else {
            dispatch(
                applicationActions.createApplication({
                    data: {
                        configId: configId,
                        values: values,
                        amoConsts: amoConsts,
                        predefinedService: predefinedService || {},
                        utmData: utmData,
                        receiveCallsAndMessagesAgreementStatus: agreementStatus,
                        setOfAgreementsStatus: true
                    },
                    configId: configId,
                })
            );
        }
    };

    return (
        <Box>
            {applicationErrors.length > 0 && <CommonError/>}
            <PersonalForm
                onBack={onBack}
                step={step}
                onSubmit={onSubmit}
                disabled={updating}
                application={application}
                config={config}
                personalFormValidationErrors={personalFormValidationErrors}
            />
        </Box>
    );
};

Personal.propTypes = {
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
};

export default Personal;
