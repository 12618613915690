import React, {useEffect, useState} from 'react';

import {TextField} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '10px',

        '& .MuiInputLabel-outlined': {
            lineHeight: 1.4,
            position: 'relative',
            marginLeft: '-13px;',
        },

        '& .MuiFormHelperText-contained': {
            marginLeft: 0,
            marginTop: '5px'
        },

        '& .MuiFormLabel-root': {
            fontSize: '1.2rem',

            '& .MuiFormLabel-asterisk': {
                color: theme.palette.error.dark,
                fontSize: '1.2rem',
            }
        },

        '& .MuiOutlinedInput-root': {
            marginTop: '-5px',
            background: theme.palette.background.paper,

            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.23)',
            },

            '&.Mui-error': {
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.error.main
                }
            },

            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.main,
            },
            '&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.error.main
            },
        },
    }
}));

const Text = ({label, placeholder, id, required, errorMessage, onChange, disabled, initialValue, style, onFocus}) => {
    const classes = useStyles();
    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(initialValue);
        onChange(id, initialValue);
    }, [initialValue]);

    const handleChange = (event) => {
        const value = event.target.value;

        setValue(value);
        onChange(id, value);
    };

    const handleFieldFocus = () => {
        if (onFocus) {
            onFocus(id);
        }
    }

    const isRequired = required === "true";
    const isError = errorMessage !== '';

    return (
        <TextField classes={classes}
                   style={style}
                   fullWidth
                   error={isError}
                   size="small"
                   id={id}
                   disabled={disabled}
                   variant="outlined"
                   InputLabelProps={{shrink: true, required: isRequired}}
                   InputProps={{notched: false}}
                   label={label}
                   placeholder={placeholder}
                   value={value}
                   onFocus={handleFieldFocus}
                   helperText={isError ? errorMessage : ''}
                   onChange={handleChange}
        />
    );
};

Text.propTypes = {};

export default Text;