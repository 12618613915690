import React, {useEffect, useRef, useState} from 'react';

import {DropzoneArea} from 'material-ui-dropzone';
import {BadFileError, UnrecognizedError, TooBigFile} from '../errors';
import {makeStyles} from "@material-ui/core/styles";
import {Box, FormControl, FormLabel, Grid} from "@material-ui/core";
import {useSelector, useDispatch} from "react-redux";

import {actions as filesActions} from "../../modules/files/duck";
import {selectors as filesSelectors} from "../../modules/files/duck";

const useStyles = makeStyles((theme) => ({
    dropZone: {
        fontSize: '0.9rem',
        lineHeight: '1.5em',
        marginBottom: theme.spacing(1),
        borderRadius: '5px',
        borderWidth: '2px',
        minHeight: 'initial',

        '& .MuiFormLabel-asterisk': {
            color: theme.palette.error.dark,
            fontSize: '0.9rem',
        }
    }
}));

const File = ({label, placeholder, id, required, errorMessage, onChange, disabled, initialValue, disableForm, enableForm}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const initialMount = useRef(true);
    const fileUrl = useSelector(filesSelectors.fileUrl);
    const fileErrors = useSelector(filesSelectors.errors);
    const [badFileError, setBadFileError] = useState(false);
    const [commonError, setCommonError] = useState(false);
    const [tooBigFile, setTooBigFile] = useState(false);

    const [value, setValue] = useState('');

    useEffect(() => {
        dispatch(filesActions.clearErrors());
    }, []);

    useEffect(() => {
        if (initialMount.current) {
            initialMount.current = false;
        } else {
            if (fileUrl) {
                enableForm();
                setValue(fileUrl);
                onChange(id, fileUrl);
            }
        }


    }, [fileUrl]);

    const handleChange = (files) => {
        if (files.length > 0) {
            const file = files[0];
            let formData = new FormData();
            formData.append('file', file, file.name);
            disableForm();
            dispatch(filesActions.uploadFile({form: formData}));
        }
    };

    const onDropRejected = (event) => {
        setTooBigFile(true);
    }

    const onDrop = () => {
        setTooBigFile(false);
    }

    useEffect(() => {
        if (fileErrors.length) {
            if (fileErrors[0].status === 400) {
                setBadFileError(true);
            } else {
                setCommonError(true);
            }

            enableForm();
        } else {
            setBadFileError(false);
            setCommonError(false);
        }
    }, [fileErrors]);

    const isRequired = required === "true";
    const isError = errorMessage !== '';

    return (
        <Box>
            <Grid item xs={12} sm={7}>
                <FormControl fullWidth error={isError} component="fieldset" className={classes.root}>
                    <FormLabel required={isRequired} className={classes.dropZone} focused={false}
                               component="legend">{label}</FormLabel>
                    <DropzoneArea classes={classes}
                                  fullWidth
                                  filesLimit={1}
                                  maxFileSize={10000000}
                                  dropzoneText={placeholder}
                                  showFileNamesInPreview
                                  useChipsForPreview
                                  previewText={false}
                                  showAlerts={false}
                                  showPreviewsInDropzone={false}
                                  onDropRejected={onDropRejected}
                                  onDrop={onDrop}
                                  id={id}
                                  dropzoneProps={{
                                      disabled: disabled
                                  }}
                                  onChange={handleChange}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={7}>
                {badFileError && <BadFileError />}
                {commonError && <UnrecognizedError />}
                {tooBigFile && <TooBigFile />}
            </Grid>
        </Box>
    );
};

File.propTypes = {};

export default File;