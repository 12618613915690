import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import schema from './schema';
import useStyles from './styles';

import {
    actions as paymentActions,
    selectors as paymentSelectors
} from '../../duck';
import { selectors as applicationSelectors } from '../../../application/duck';

const SquareForm = ({ form, wallet = 'mango', submitClicks, isMobileMood, onDisabled, onPaymentProcessing }) => {
    const sqAppId = process.env['REACT_APP_SQUARE_APP_ID_' + wallet.toUpperCase()];

    const classes = useStyles();
    const dispatch = useDispatch();

    const applicationId = useSelector(applicationSelectors.applicationId);
    const appliedCode = useSelector(paymentSelectors.code);

    const [paymentForm, setPaymentForm] = useState(null);
    const [nonce, setNonce] = useState(null);

    const [squareErrors, setSquareErrors] = useState({});
    const squareErrorsRef = useRef(squareErrors);
    const setSquareErrorsWithRef = (object) => {
        squareErrorsRef.current = object;
        setSquareErrors(object);
    };

    const [emptyState, setEmptyState] = useState({
        cardNumber: true,
        expirationDate: true,
        cvv: true,
        postalCode: true,
    });
    const emptyStateRef = useRef(emptyState);
    const setEmptyStateWithRef = (object) => {
        emptyStateRef.current = object;
        setEmptyState(object);
    };

    // hook => set square-up-form, when the component is initialized
    useEffect(() => {
        /*global SqPaymentForm */
        const form = new SqPaymentForm({
            applicationId: sqAppId,
            inputClass: 'sq-input-new',
            inputStyles: [{
                fontSize: '16px',
                color: '#000',
                placeholderColor: '#a0a0a0',
                padding: '0px 0px',
                lineHeight: '20px',
                backgroundColor: 'transparent',
            }],
            cardNumber: {
                elementId: 'sq-card-number',
                placeholder: schema.cardNumber.placeholder
            },
            cvv: {
                elementId: 'sq-cvv',
                placeholder: schema.cvv.placeholder
            },
            expirationDate: {
                elementId: 'sq-expiration-date',
                placeholder: schema.expirationDate.placeholder
            },
            postalCode: {
                elementId: 'sq-postal-code',
                placeholder: schema.zip.placeholder
            },
            callbacks: {
                inputEventReceived: (inputEvent) => {
                    if (inputEvent.eventType === 'errorClassRemoved' && squareErrorsRef.current[inputEvent.field]) {
                        const object = Object.assign({}, squareErrorsRef.current);
                        delete object[inputEvent.field];

                        setSquareErrorsWithRef(object);
                        if (!Object.keys(object).length) {
                            onDisabled(false);
                        };
                    };

                    if (inputEvent.eventType === 'emptyStateChanged') {
                        const _emptyState = Object.assign({}, emptyStateRef.current);

                        _emptyState[inputEvent.field] = inputEvent.currentState.isEmpty;
                        setEmptyStateWithRef(_emptyState);
                    };
                },
                cardNonceResponseReceived: (errors, nonce) => {
                    if (nonce) {
                        setNonce(nonce);
                        setSquareErrorsWithRef({});
                    } else if (errors) {
                        const object = {};
                        errors.forEach((item) => {
                            object[item.field] = item.message;
                        });

                        onDisabled(true);
                        onPaymentProcessing(false);
                        setSquareErrorsWithRef(object);
                    };
                },
            }
        });

        setPaymentForm(form);
    }, []);

    // hook => build payment form, after create instance
    useEffect(() => {
        if (paymentForm) {
            paymentForm.build();
        };
    }, [paymentForm]);

    // hook => get nonce request, after click "Next" in payment wrapper component
    useEffect(() => {
        if (submitClicks) {
            onPaymentProcessing(true);
            paymentForm.requestCardNonce();
        }
    }, [submitClicks]);

    // hook => update payment wrapper state, after change emptyState
    useEffect(() => {
        onDisabled(
            Object
                .values(emptyState)
                .includes(true)
        );
    }, [emptyState]);

    // hook => payment request, after generate nonce in SquareUP API
    useEffect(() => {
        if (nonce) {
            dispatch(paymentActions.processPayment({
                paymentData: {
                    firstName: form.values.firstName.value,
                    lastName: form.values.lastName.value,
                    email: form.values.email.value,
                    phoneNumber: form.values.phoneNumber.value,
                },
                code: appliedCode ? appliedCode.code : null,
                applicationId: applicationId,
                nonce: nonce,
                wallet: wallet,
            }));
        }
    }, [nonce]);

    return (
        <div className={classes.container}>
            <div className={classes.inputBlock}>
                <div className={'cardNumberLabelContainer'}>
                    <label className={squareErrors?.cardNumber ? classes.error : null}>
                        {schema.cardNumber.label}
                        <i>*</i>
                    </label>
                    <div className={`${classes.iconContainer} ${isMobileMood ? classes.mobileIconContainer : ''}`}>
                        <img src={process.env.REACT_APP_URL + '/general-type-cards.svg'}></img>
                        <img src={process.env.REACT_APP_URL + '/jcb.svg'}></img>
                        <img src={process.env.REACT_APP_URL + '/discover.svg'}></img>
                        <img src={process.env.REACT_APP_URL + '/union-pay.svg'}></img>
                    </div>
                </div>
                <div id="sq-card-number" className='sq-input-new-loading'></div>
                {squareErrors?.cardNumber && <span>{squareErrors?.cardNumber}</span>}
            </div>
            {!isMobileMood && <div className={classes.row}>
                <div className={classes.inputBlock}>
                    <label className={squareErrors?.expirationDate ? classes.error : null}>
                        {schema.expirationDate.label}
                        <i>*</i>
                    </label>
                    <div id="sq-expiration-date" className='sq-input-new-loading'></div>
                    {squareErrors?.expirationDate && <span>{squareErrors?.expirationDate}</span>}
                </div>
                <div className={classes.inputBlock} style={{ margin: '0 20px' }}>
                    <label className={squareErrors?.cvv ? classes.error : null}>
                        {schema.cvv.label}
                        <i>*</i>
                    </label>
                    <div id="sq-cvv" className='sq-input-new-loading'></div>
                    {squareErrors?.cvv && <span>{squareErrors?.cvv}</span>}
                </div>
                <div className={classes.inputBlock}>
                    <label className={squareErrors?.postalCode ? classes.error : null}>
                        {schema.zip.label}
                        <i>*</i>
                    </label>
                    <div id="sq-postal-code" className='sq-input-new-loading'></div>
                    {squareErrors?.postalCode && <span>{squareErrors?.postalCode}</span>}
                </div>
            </div>}
            {isMobileMood && <>
                <div className={classes.rowMobile}>
                    <div className={classes.inputBlock}>
                        <label className={squareErrors?.expirationDate ? classes.error : null}>
                            {schema.expirationDate.label}
                            <i>*</i>
                        </label>
                        <div id="sq-expiration-date" className='sq-input-new-loading'></div>
                        {squareErrors?.expirationDate && <span>{squareErrors?.expirationDate}</span>}
                    </div>
                    <div className={classes.inputBlock} style={{ marginLeft: '18px' }}>
                        <label className={squareErrors?.cvv ? classes.error : null}>
                            {schema.cvv.label}
                            <i>*</i>
                        </label>
                        <div id="sq-cvv" className='sq-input-new-loading'></div>
                        {squareErrors?.cvv && <span>{squareErrors?.cvv}</span>}
                    </div>
                </div>
                <div className={classes.rowMobile}>
                    <div className={classes.inputBlock}>
                        <label className={squareErrors?.postalCode ? classes.error : null}>
                            {schema.zip.label}
                            <i>*</i>
                        </label>
                        <div id="sq-postal-code" className='sq-input-new-loading'></div>
                        {squareErrors?.postalCode && <span>{squareErrors?.postalCode}</span>}
                    </div>
                </div>
            </>}
        </div>
    );
};

SquareForm.propTypes = {
    form: PropTypes.object.isRequired,
    wallet: PropTypes.oneOf(['mango', 'ezcare', 'medvidi', 'ezcare_medvidi']).isRequired,
    submitClicks: PropTypes.number.isRequired,
    isMobileMood: PropTypes.bool.isRequired,
    onDisabled: PropTypes.func.isRequired,
    onPaymentProcessing: PropTypes.func.isRequired,
};

export default SquareForm;
