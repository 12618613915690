const applicationItem = ({application}) => application.item;
const applicationId = ({application}) => application.id;
const promoCode = ({application}) => application.promoCode;
const configId = ({application}) => application.configId;
const predefinedService = ({application}) => application.predefinedService;
const applicationStep = ({application}) => application.step;
const applicationErrors = ({application}) => application.errors;
const selectedService = ({application}) => application.item ? application.item.service : null;
const selectedServicePlan = ({application}) => application.item ? application.item.plan : null;
const state = ({application}) => application.item.personalInfo.state.value || null;
const websitePhone = ({ application }) => application?.item.website?.phone;
const leadId = ({application}) => application.item ? application.item.leadId : null;
const pharmacyItems = ({application}) => application.pharmacyItems;
const personalFormValidationErrors = ({application}) => application.personalFormValidationErrors ? application.personalFormValidationErrors : [];

export const selectors = {
    applicationItem,
    applicationId,
    promoCode,
    configId,
    applicationErrors,
    applicationStep,
    selectedService,
    selectedServicePlan,
    predefinedService,
    state,
    pharmacyItems,
    websitePhone,
    leadId,
    personalFormValidationErrors
};
