import React from 'react'
import {Alert, AlertTitle} from '@material-ui/lab'

const IntakeBeforePaymentError = () => (
    <Alert severity="error">
        <AlertTitle>Error. </AlertTitle>
        Intake form is not available while your subscription is not activated
    </Alert>
);

export default IntakeBeforePaymentError
