import Axios from 'axios';

const api = Axios.create({
    baseURL: process.env.REACT_APP_API_URL + '/applications/',
});

const createApplication = async ({data, configId}) => {
    return await api.post('/', data, {
        params: {
            configId: configId
        }
    });
};

const getApplication = async ({id}) => {
    return await api.get(`/${id}`);
};

const updateApplication = async ({id, configId, data, key, step}) => {
    return await api.put(`/${id}`, data, {
        params: {
            path: key,
            step: step,
            configId: configId
        }
    });
};

const updateApplicationData = async ({id, data, key, step}) => {
    return await api.put(`/${id}/data`, data, {
        params: {
            path: key,
            step: step,
        }
    });
};

const getPharmacyByLocationParams = async (payload) => {
    return await api.get(`${payload.applicationId}/pharmacy-search?zip=${payload.zip}`);
};

export const operations = {
    createApplication,
    getApplication,
    updateApplication,
    updateApplicationData,
    getPharmacyByLocationParams
};
