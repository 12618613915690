const text = `
<p>  
I understand that my treatment plan may or may not include a controlled medication that may assist in symptomatic relief.
</p>
<p>  
Controlled medicine can be dangerous and habit forming. These medicines must be taken only as prescribed by a doctor. Please read this consent and agreement thoroughly.
</p>
<p>  
If you are in agreement and fully understand the benefits and risks of the medications, sign and date below.
</p>
<ul>
<li>
I understand that in the event that the physician feels it is necessary for treatment of my condition, the medication I may be prescribed may cause addiction. Should after the physician has explained to me the potential risks, the potential short and long term side effects, the risk of drug interactions and over-stimulation, over-sedation, etc., the risk of misuse and overdose etc, and I still have unanswered questions I shall address my questions or concerns immediately.
</li>
<li>
I hereby accept any and all risks associated with the use of controlled substances.
</li>
<li>
I agree to take this medication only as prescribed by my physician.
</li>
<li>
I agree to attend all scheduled appointments with my physician, PA or Medical Assistant.
</li>
<li>
I understand that refills will not be given early.
</li>
<li>
I understand that Schedule II medications can not be refilled (Title 21 Code of Federal Regulations (C.F.R.) §§1308.11 through 1308.)
</li>
<li>
I will not obtain controlled substances from any other providers unless authorized by my primary prescriber, because it may be considered illegal to obtain controlled substances from multiple providers.
</li>
<li>
I understand that these medications are for my personal use only.
</li>
<li>
I understand that it is illegal, and can be reported to the police, to give or sell my medication to others.
</li>
<li>
I agree to not use any illegal substances, including but not limited to marijuana (except if legal in my home state and I am duly authorized to use it within my home state), cocaine, or any other "street drugs".
</li>
<li>
I understand that it is illegal for me to use medications that are not prescribed to me.
</li>
<li>
I understand that I am responsible for my own medication. Lost or stolen medication will not be Replaced.
</li>
<li>
I give up the right to privacy protections with regard to my prescription for controlled substances. The physician or staff may talk with other physicians, pharmacists or family members to confirm appropriate medication use.
</li>
<li>
Further, I give my permission to EZCare Medical Clinic staff to check the state’s PMP reporting program for my controlled substance prescription history.
</li>
<li>
I agree to submit to random drug screening tests when ordered by my physician, and I will be responsible for payment for the tests.
</li>
<li>
If requested of me, I agree to bring my medication bottle(s) to the office for the purpose of a pill count.
</li>
<li>
I understand that I may obtain my controlled substances from a pharmacy duly authorized to dispense medications, and I agree to update my physician’s office of any changes in the pharmacy I use.
</li>
<li>
I understand these medications may interfere with my ability to drive and/or operate heavy machinery.
</li>
</ul>
<p>
<b>Certification</b>
</p>
<p>
I have reviewed this Informed Consent and Treatment Agreement for Controlled Substances. I understand it and continue to agree to honor the Agreement. I understand that any failure to do so may result in my discharge from this medical practice.
</p>
<p>
    1. I understand that my health care provider, has recommended to me that I engage in a telehealth/telemedicine appointment 
    with this healthcare provider.
</p>
<p>    
    2. My health care provider has explained to me how the telehealth technology will be used to connect me with a provider. 
    Telehealth appointments may be conducted by videoconferencing, video images, still (high quality photo) images, or by 
    telephone conference. I understand that this appointment will not be the same as a direct patient/health care provider 
    visit due to the fact that I will not be in the same room as my health care provider. I understand that the health care 
    provider may use devices such as a stethoscope or otoscope or other peripheral devices to assist in the examination.
</p>
<p>     
    3. I understand there are potential risks to this technology, including interruptions, unauthorized access and technical 
    difficulties. I understand that my health care provider or I can discontinue the telehealth appointment if it is felt that 
    the videoconferencing connections are not adequate for the situation. I understand that I can discontinue the telehealth 
    appointment at any time
</p>
<p>     
    4. I understand that my healthcare information may be shared with other individuals for scheduling and billing purposes. 
    Others may also be present during the appointment other than my healthcare provider and specialty health care provider in 
    order to operate the equipment. The above mentioned people will all maintain confidentiality of the information obtained. 
    I further understand that I will be informed of their presence during the consultation and thus will have the right to 
    request the following: (1) omit specific details of my medical history/physical examination that are personally sensitive 
    to me; (2) ask non-medical personnel to leave the telehealth examination room; and/or (3) terminate the telehealth 
    appointment at any time.
</p>
<p>     
    5. I have had the alternatives to a telehealth appointment explained to me, and in choosing to participate in a telehealth 
    appointment, I understand that some parts of the exam involving physical tests may be conducted by individuals at my location 
    at the direction of the specialty health care provider or the primary care provider.
</p>
<p>     
    6. In an emergency situation, I understand that the responsibility of the telehealth specialist or provider may be to direct 
    me to emergency medical services, such as emergency room. Or the telehealth provider may discuss with and advise my local 
    provider. The telehealth specialist’s or provider’s responsibility will end upon the termination of the telehealth connection.
</p>
<p>     
    7. I understand that billing for the telehealth consultation may occur from 1) the primary care provider and 2) telehealth 
    provider, and 3) as a facility fee from the site from which I am presented. Billing is at the discretion of the provider.
    Billing procedures will be explained to me.
</p>
<p>     
    8. I have read this document carefully, and understand the risks and benefits of the telehealth appointment and have had 
    my questions regarding the procedure explained and I hereby consent to participate in a telehealth appointment visit under 
    the terms described herein.
</p>
`;

export default text;