import {combineActions, createActions, handleActions} from 'redux-actions';

const {
    setApplicationId,
    setPromoCode,
    setConfigId,
    setPredefinedService,
    reset,
    setStep,
    createApplication,
    createApplicationSuccess,
    createApplicationFail,
    getApplication,
    getApplicationSuccess,
    getApplicationFail,
    updateApplication,
    updateApplicationSuccess,
    updateApplicationFail,
    updateApplicationData,
    updateApplicationDataSuccess,
    updateApplicationDataFail,
    getPharmacyByLocationParams,
    setPharmacyItems,
    setPersonalFormValidationErrors
} = createActions(
    'SET_APPLICATION_ID',
    'SET_PROMO_CODE',
    'SET_CONFIG_ID',
    'SET_PREDEFINED_SERVICE',
    'RESET',
    'SET_STEP',
    'CREATE_APPLICATION',
    'CREATE_APPLICATION_SUCCESS',
    'CREATE_APPLICATION_FAIL',
    'GET_APPLICATION',
    'GET_APPLICATION_SUCCESS',
    'GET_APPLICATION_FAIL',
    'UPDATE_APPLICATION',
    'UPDATE_APPLICATION_SUCCESS',
    'UPDATE_APPLICATION_FAIL',
    'UPDATE_APPLICATION_DATA',
    'UPDATE_APPLICATION_DATA_SUCCESS',
    'UPDATE_APPLICATION_DATA_FAIL',
    'GET_PHARMACY_BY_LOCATION_PARAMS',
    'SET_PHARMACY_ITEMS',
    'SET_PERSONAL_FORM_VALIDATION_ERRORS'
);

const defaultState = {
    id: null,
    promoCode: null,
    configId: null,
    step: 0,
    item: null,
    predefinedService: null,
    pharmacyItems: [],
    errors: [],
    personalFormValidationErrors: []
};

export const reducer = handleActions(
    {
        [setApplicationId]: (state, action) => ({
            ...state,
            id: action.payload.id,
        }),
        [setPromoCode]: (state, action) => ({
            ...state,
            promoCode: action.payload.promoCode,
        }),
        [setConfigId]: (state, action) => ({
            ...state,
            configId: action.payload.configId,
        }),
        [setStep]: (state, action) => ({
            ...state,
            step: action.payload.index,
        }),
        [setPredefinedService]: (state, action) => ({
            ...state,
            predefinedService: action.payload.predefinedService,
        }),
        [setPharmacyItems]: (state, action) => ({
            ...state,
            pharmacyItems: action.payload
        }),
        [setPersonalFormValidationErrors]: (state, action) => ({
            ...state,
            personalFormValidationErrors: action.payload
        }),
        [reset]: (state, action) => ({
            ...state,
            id: null,
            configId: null,
            step: 0,
            item: null,
            predefinedService: null,
            errors: [],
        }),
        [combineActions(
            createApplication,
            getApplication,
            updateApplication,
            updateApplicationData)]: state => ({
            ...state,
            errors: [],
        }),
        [combineActions(
            createApplicationSuccess,
            getApplicationSuccess,
            updateApplicationSuccess,
            updateApplicationDataSuccess)]: (state, action) => ({
            ...state,
            item: action.payload
        }),
        [combineActions(
            createApplicationFail,
            getApplicationFail,
            updateApplicationFail,
            updateApplicationDataFail)]: (state, action) => ({
            ...state,
            errors: action.payload,
        }),
    },
    defaultState
);

export const actions = {
    setApplicationId,
    setPromoCode,
    setConfigId,
    setPredefinedService,
    reset,
    setStep,
    createApplication,
    createApplicationSuccess,
    createApplicationFail,
    getApplication,
    getApplicationSuccess,
    getApplicationFail,
    updateApplication,
    updateApplicationSuccess,
    updateApplicationFail,
    updateApplicationData,
    updateApplicationDataSuccess,
    updateApplicationDataFail,
    getPharmacyByLocationParams,
    setPharmacyItems,
    setPersonalFormValidationErrors
};
