const text = `
<p>
<b>Consent For Medical Care:</b> I consent to medical testing, care and treatment by MEDvidi Medical and its affiliated providers.
</p>
<p>
<b>Consent for Telemedicine:</b> I agree to care using Telemedicine. This is care done by sending video images or other transmitted information to a Provider who is in a different place from me. There are some advantages to care by Telemedicine. Advantages include being able to get faster care, and more immediate response by a Provider. There are also possible problems. These include interruptions, unauthorized access and technical difficulties. An exam, consultation or service by Telemedicine may miss information that a direct exam might provide. At any time, I may refuse or stop an exam, consultation or service done by Telemedicine. Technical assistants may also be present during the exam, consultation or service to help with the process. There may be charges for Providers and the facility for Telemedicine services.
</p>
<p>
<b>Assignment of Benefits:</b> I hereby irrevocably assign payment to MEDvidi Medical and its affiliated providers accepting this assignment. Where Medicare and Medicaid benefits are applicable, I certify that the information given by me in applying for payment, under Title XVIII or XIX of the Social Security Act, is correct and request that payment of authorized benefits are made to MHS on my behalf.
</p>
<p>
<b>Release of Information for Payment Purposes:</b> I hereby authorize and consent MEDvidi Medical and its affiliated providers' release of medical information to obtain payment as described in the MEDvidi Medical and its affiliated providers Privacy Notice. This consent includes, without limitation, present and future HIV test results and mental health records.
</p>
<p>
<b>Obligation for Payment:</b> I understand I am financially responsible to MHS and physicians for charges for all services provided by MEDvidi Medical and its affiliated providers to me, which my insurance carrier does not cover (which includes all commercial and government third-party payors). I understand that it is my responsibility to comply with all requirements for insurance out-of-network coverage. I further understand and agree that any credit balance resulting from payment may be applied to any other accounts owed by me to MEDvidi Medical and its affiliated providers. In the event that I fail to fulfill any of payment obligations in this section, I agree to pay any and all attorney fees and/or collection costs incurred by MEDvidi Medical and its affiliated providers in the enforcement of my payment obligations or any other obligations as specified herein.
</p>
<p>
<b>Release of Liability for Loss of Personal Property:</b> I fully understand that the staff of MEDvidi Medical and its affiliated providers cannot give attention to any item of personal property, regardless of value. In the event of the loss of such items, I will not hold MEDvidi Medical and its affiliated offices responsible, but will personally assume any cost and expense incurred because of such loss.
</p>
<p>
<b>Communications:</b> I authorize MEDvidi Medical and its affiliated providers to contact me by the use of any automatic dialing system or by pre-recorded forms of voice/messaging system. I also authorize MEDvidi Medical and its affiliated providers or other providers or their agents or affiliates to contact me on my home phone, cell phone (by either voice call or text message), and/or electronic mail owned or used by me. If I request medical or financial information be sent by e-mail, fax, or other electronic means I understand there is a risk of misdirection disclosure, or interception by unauthorized parties. If I make such a request, I assume that risk.  I further authorize MEDvidi Medical and entities contracted with MEDvidi to contact me for the purpose of inquiring about my patient experience at any MEDvidi facility.
</p>
<p>
<b>Professional Billing:</b> Charges for physicians who provided your care and interpreted your tests are not included in your hospital bill. You will receive separate bills from emergency room physician, radiologist, pathologist, anaesthesiologist, surgical assistants, specialty consults, and your attending physician outside of this medical group.
</p>
<p>
I acknowledge that I have the right to refuse opioid drugs and to consider alternatives.
</p>
`;

export default text;