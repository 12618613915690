import {call, put, takeLatest} from 'redux-saga/effects';

import {operations} from './operations';
import {actions} from './modules';

function* watchServicePlansActions() {
    yield takeLatest(actions.getServicePlans, getServicePlans);
}

function* getServicePlans({payload}) {
    const {stateCode, websiteCode} = payload;
    try {
        const response = yield call(operations.getServicePlans, {stateCode, websiteCode});

        if (response.data.items.length === 0) {
            yield put(actions.getServicePlansFail('service is not supported in your state'));
        } else {
            yield put(actions.getServicePlansSuccess(response.data.items));
        }


    } catch (error) {
        const response = {
            data: error.response.data,
            status: error.response.status,
            statusText: error.response.statusText,
        };
        yield put(actions.getServicePlansFail(response));
    }
}

export const sagas = watchServicePlansActions;