import {call, put, takeLatest} from 'redux-saga/effects';

import {operations} from './operations';
import {actions} from './modules';

function* watchConfigActions() {
    yield takeLatest(actions.getConfig, getConfig);
}

function* getConfig({payload}) {
    const {id} = payload;
    try {
        const response = yield call(operations.getConfig, {id});
        yield put(actions.getConfigSuccess(response.data.data));
    } catch (error) {
        const response = {
            data: error.response.data,
            status: error.response.status,
            statusText: error.response.statusText,
        };
        yield put(actions.getConfigFail(response));
    }
}

export const sagas = watchConfigActions;