import {call, put, takeLatest} from 'redux-saga/effects';

import {operations} from './operations';
import { actions } from './modules';
import analytics from "../../analytics";
import { actions as servicePlanActions } from '../../service-plan/duck'

function* watchApplicationsActions() {
    yield takeLatest(actions.createApplication, createApplication);
    yield takeLatest(actions.getApplication, getApplication);
    yield takeLatest(actions.updateApplication, updateApplication);
    yield takeLatest(actions.updateApplicationData, updateApplicationData);
    yield takeLatest(actions.getPharmacyByLocationParams, getPharmacyByLocationParams);
}

function* createApplication({payload}) {
    const {data, configId} = payload;

    try {
        const response = yield call(operations.createApplication, {data, configId});
        yield put(actions.createApplicationSuccess(response.data.data));


        analytics.sendCustomEvent(
            'Lead',
            'Created',
            response.data.data.leadId,
        );
        yield put(servicePlanActions.getServicePlans({
            stateCode: response.data.data.personalInfo.state.value.toLowerCase(),
            websiteCode: response.data.data.website.code,
        }));
    } catch (error) {
        const response = {
            data: error.response.data,
            status: error.response.status,
            statusText: error.response.statusText,
        };
        const isPersonalFormValidationError = response.data.errors[0]?.field;
        if (response.status === 400 && isPersonalFormValidationError) {
            yield put(actions.setPersonalFormValidationErrors(response.data.errors))
        } else {
            yield put(actions.updateApplicationFail(response.data.errors));
        }
    }
}

function* getApplication({payload}) {
    const {id} = payload;

    try {
        const response = yield call(operations.getApplication, {id});
        yield put(actions.getApplicationSuccess(response.data.data));
        yield put(actions.getPharmacyByLocationParams(response.data.data));
    } catch (error) {
        const response = {
            data: error.response.data,
            status: error.response.status,
            statusText: error.response.statusText,
        };
        yield put(actions.getApplicationFail(response.data.errors));
    }
}

function* updateApplication({payload}) {
    const {id, configId, data, key, step} = payload;

    try {
        const response = yield call(operations.updateApplication, {id, configId, data, key, step});
        yield put(actions.updateApplicationSuccess(response.data.data));
        yield put(servicePlanActions.getServicePlans({
            stateCode: response.data.data.personalInfo.state.value.toLowerCase(),
            websiteCode: response.data.data.website.code,
        }));
    } catch (error) {
        const response = {
            data: error.response.data,
            status: error.response.status,
            statusText: error.response.statusText,
        };
        const isPersonalFormValidationError = response.data.errors[0]?.field;
        if (response.status === 400 && isPersonalFormValidationError) {
            yield put(actions.setPersonalFormValidationErrors(response.data.errors))
        } else {
            yield put(actions.updateApplicationFail(response.data.errors));
        }
    }
}

function* updateApplicationData({payload}) {
    const {id, data, key, step} = payload;

    try {
        const response = yield call(operations.updateApplicationData, {id, data, key, step});
        yield put(actions.updateApplicationDataSuccess(response.data.data));
    } catch (error) {
        const response = {
            data: error.response.data,
            status: error.response.status,
            statusText: error.response.statusText,
        };
        yield put(actions.updateApplicationDataFail(response.data.errors));
    }
}

function* getPharmacyByLocationParams({payload}) {

    try {
        const response = yield call(operations.getPharmacyByLocationParams, {
            applicationId: payload.refId,
            zip: payload.personalInfo.zip.value
        });
        yield put(actions.setPharmacyItems(response.data.pharmacies));
    } catch (error) {
        yield put(actions.setPharmacyItems([]));
    }
}

export const sagas = watchApplicationsActions;