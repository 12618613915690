import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from "react-redux"

import { Text } from "../../componets/fields"
import { CommonError } from "../../componets/errors";
import { Box, Button, Chip, CircularProgress, Grid } from '@material-ui/core'

import useStyles from './styles'

import { selectors as applicationSelectors } from "../application/duck"
import { selectors as configSelectors } from "../config/duck"
import { actions as applicationActions } from "../application/duck"
import { actions as websiteActions } from "../website/duck"
import { selectors as websiteSelectors } from "../website/duck"

import text from "./text"
import { createHashHistory } from 'history';
import moment from "moment";

const history = createHashHistory({
    basename: '',
    hashType: 'noslash',
});

const ControlledSubstances = ({onNext, onBack, id, step, name}) => {
    const classes = useStyles();

    const config = useSelector(configSelectors.configItem);
    const application = useSelector(applicationSelectors.applicationItem);
    const applicationId = useSelector(applicationSelectors.applicationId);
    const applicationErrors = useSelector(applicationSelectors.applicationErrors);
    const websiteTerms = useSelector(websiteSelectors.terms);
    const dispatch = useDispatch();

    const initialMount = useRef(true);
    const [updating, setUpdating] = useState(false);

    useEffect(() => {
        const nextStep = application && application.nextStepId ? application.nextStepId : 0;
        if (nextStep < step) history.push(config.steps[nextStep].url);

        if (!websiteTerms) {
            dispatch(websiteActions.getWebsiteData({code: application.website.code}));
        }
    }, []);

    useEffect(() => {
        if (initialMount.current) {
            initialMount.current = false;
        } else {
            setUpdating(false);
            onNext();
        }
    }, [application]);

    const handleSubmit = () => {
        setUpdating(true);

        dispatch(applicationActions.updateApplicationData({
            id: applicationId,
            data: {
                value: {
                    name,
                    values: {
                        signatureAcknowledgement: {
                            value:  'I’ve read, understood, and given the controlled substance informed consent'
                        }
                    }
                }
            },
            key: id,
            step: step
        }));
    };

    return (
        <Box>
            {applicationErrors.length > 0 && <CommonError/>}

            {application && <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <Text onChange={() => {
                        }}
                              disabled={true}
                              errorMessage={''}
                              id="firstName"
                              initialValue={application.personalInfo?.firstName?.value + ' ' + application.personalInfo?.lastName?.value}
                              label={'Patient\'s Name'}
                              required={false}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Text onChange={() => {
                        }}
                              disabled={true}
                              errorMessage={''}
                              id="birthdate"
                              initialValue={application.data.generalInfo?.values.dateOfBirth.value}
                              label={'Birthdate'}
                              required={false}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Text onChange={() => {
                        }}
                              disabled={true}
                              errorMessage={''}
                              id="sex"
                              initialValue={application.personalInfo?.gender.value}
                              label={'Sex'}
                              required={false}/>
                    </Grid>
                </Grid>
            </Box>}
            <br/>
            <div className={classes.text} dangerouslySetInnerHTML={{__html: text}}>
            </div>
            <form>
                <Grid container spacing={6}>
                    <Grid item xs={12} sm={8} md={6}>
                        <Text
                            onChange={() => {
                            }}
                            disabled={true}
                            errorMessage={''}
                            id="date"
                            initialValue={moment().format('MM/DD/YYYY')}
                            label={'This agreement was entered on this date:'}
                            required={'false'}
                        />
                    </Grid>
                </Grid>
            </form>
            <br/>
            <Box className={classes.controls}>
                {step > 0 &&
                    <Button disabled={updating} className={`${classes.button} ${classes.backButton}`}
                            variant="outlined"
                            onClick={onBack}>
                        Back
                    </Button>}
                <Button disabled={updating} className={classes.button} variant="contained"
                        onClick={handleSubmit}
                        color="primary">
                    {updating ?
                        <>&nbsp;<CircularProgress color="inherit" size={16}/>&nbsp;&nbsp;Sending...</> :
                        <>AGREE & CONTINUE</>}
                </Button>
            </Box>
        </Box>
    );
};

ControlledSubstances.propTypes = {
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
};

export default ControlledSubstances;