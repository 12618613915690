import {combineActions, createActions, handleActions} from 'redux-actions';

const {
    dispatchData,
    dispatchDataSuccess,
    dispatchDataFail,
    createTransaction,
    createTransactionSuccess,
    createTransactionFail,
    processPayment,
    processPaymentSuccess,
    processPaymentFail,
    checkCode,
    checkCodeSuccess,
    checkCodeFail,
    codeReset,
    createIntent,
    createIntentSuccess,
    createIntentFail,
    confirmPayment,
    confirmPaymentSuccess,
    confirmPaymentFail,
    resetPaymentState,
    skipPayment
} = createActions(
    'DISPATCH_DATA',
    'DISPATCH_DATA_SUCCESS',
    'DISPATCH_DATA_FAIL',
    'CREATE_TRANSACTION',
    'CREATE_TRANSACTION_SUCCESS',
    'CREATE_TRANSACTION_FAIL',
    'PROCESS_PAYMENT',
    'PROCESS_PAYMENT_SUCCESS',
    'PROCESS_PAYMENT_FAIL',
    'CHECK_CODE',
    'CHECK_CODE_SUCCESS',
    'CHECK_CODE_FAIL',
    'CODE_RESET',
    'CREATE_INTENT',
    'CREATE_INTENT_SUCCESS',
    'CREATE_INTENT_FAIL',
    'CONFIRM_PAYMENT',
    'CONFIRM_PAYMENT_SUCCESS',
    'CONFIRM_PAYMENT_FAIL',
    'RESET_PAYMENT_STATE',
    'SKIP_PAYMENT'
);

const defaultState = {
    nonce: null,
    transactionId: null,
    paymentId: null,
    errors: [],
    codeErrors: [],
    code: null,
    clientSecret: null,
    paymentIntent: null,
    loaded: false,
};

export const reducer = handleActions(
    {
        [combineActions(
            dispatchData,
            createTransaction,
            checkCode,
            processPayment,
            createIntent,
            confirmPayment,
        )]: state => ({
            ...state,
            errors: [],
        }),
        [combineActions(
            confirmPayment,
        )]: state => ({
            ...state,
            loaded: true,
            errors: [],
        }),
        [dispatchDataSuccess]: (state, action) => ({
            ...state,
            nonce: action.payload
        }),
        [createTransactionSuccess]: (state, action) => ({
            ...state,
            transactionId: action.payload
        }),
        [processPaymentSuccess]: (state, action) => ({
            ...state,
            paymentId: action.payload
        }),
        [createIntentSuccess]: (state, action) => ({
            ...state,
            clientSecret: action.payload
        }),
        [confirmPaymentSuccess]: (state, action) => ({
            ...state,
            loaded: false,
            paymentIntent: action.payload
        }),
        [combineActions(
            dispatchDataFail,
            createTransactionFail,
            processPaymentFail,
            createIntentFail,
        )]: (state, action) => ({
            ...state,
            errors: action.payload,
        }),
        [combineActions(
            confirmPaymentFail
        )]: (state, action) => ({
            ...state,
            loaded: false,
            errors: action.payload,
        }),
        [checkCodeSuccess]: (state, action) => ({
            ...state,
            code: action.payload,
            codeErrors: [],
        }),
        [checkCodeFail]: (state, action) => ({
            ...state,
            code: {},
            codeErrors: [action.payload],
        }),
        [codeReset]: (state) => ({
            ...state,
            code: null,
            codeErrors: [],
        }),
        [combineActions(
            resetPaymentState,
        )]: state => ({
            ...defaultState,
        }),
    },
    defaultState
);

export const actions = {
    dispatchData,
    dispatchDataSuccess,
    dispatchDataFail,
    createTransaction,
    createTransactionSuccess,
    createTransactionFail,
    processPayment,
    processPaymentSuccess,
    processPaymentFail,
    checkCode,
    checkCodeSuccess,
    checkCodeFail,
    codeReset,
    createIntent,
    createIntentSuccess,
    createIntentFail,
    confirmPayment,
    confirmPaymentSuccess,
    confirmPaymentFail,
    resetPaymentState,
    skipPayment
};
