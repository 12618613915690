import Axios from 'axios';

const api = Axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL + '/service/',
});

const getServicePlans = async ({stateCode, websiteCode}) =>  {
  return await api.post(`/list`, {
        params: {
            stateCode: stateCode,
            websiteCode: websiteCode
        }
     }, {
        headers: {'dctrapp-api-privatetoken': process.env.REACT_APP_PUBLIC_API_TOKEN},
     }
  );
};

export const operations = {
  getServicePlans
};
