const nonce = ({payment}) => payment.nonce;
const transactionId = ({payment}) => payment.transactionId;
const paymentId = ({payment}) => payment.paymentId;
const errors = ({payment}) => payment.errors;
const code = ({payment}) => payment.code;
const codeErrors = ({payment}) => payment.codeErrors;
const clientSecret = ({payment}) => payment.clientSecret;
const paymentIntent = ({payment}) => payment.paymentIntent;
const paymentLoaded = ({payment}) => payment.loaded;

export const selectors = {
    nonce,
    errors,
    transactionId,
    paymentId,
    code,
    codeErrors,
    clientSecret,
    paymentIntent,
    paymentLoaded
};
