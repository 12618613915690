import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },

    row: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '25px',
    },

    rowMobile: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '15px',
    },

    error: {
        color: '#FF0000 !important',
    },

    inputBlock: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',

        '& label, span': {
            fontFamily: 'Montserrat',
            fontWeight: '300',
            fontSize: '16px',
            lineHeight: '20px',
        },

        '& label': {
            color: 'rgba(0,0,0,0.54)',
            marginBottom: '15px',
            whiteSpace: 'nowrap',
        },

        '& span': {
            marginTop: '15px',
            color: '#FF0000',
        },

        '& i': {
            fontSize: '12px',
            color: '#FF0000',
            marginLeft: '3px',
        },

        '& .cardNumberLabelContainer': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
        },
    },

    iconContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'end',
        flexWrap: 'wrap',
        marginBottom: '5px',

        '& img': {
            height: '30px',
        },

        '& :nth-child(n)': {
            marginLeft: '2px',
        },
    },
    mobileIconContainer: {
        '& img': {
            height: '25px',
        },
    },
}));

export default useStyles;
