import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        minHeight: '100px',
        display: 'flex',
        flexDirection: 'column',
    },
}));

export default useStyles;
