import React, {useEffect, useState} from 'react';
import {Checkbox, Text, MaskedText} from "../../../componets/fields";
import {
    Box,
    Grid,
    Divider,
    FormControl,
    InputLabel,
    FormHelperText,
} from '@material-ui/core';

import Promo from '../promo';
import Order from '../order';

import {useSelector, useDispatch} from "react-redux";

import * as rules from '../../../constants/rules';
import * as messages from '../../../constants/messages';

import {selectors as applicationSelectors} from "../../application/duck";
import {selectors as paymentSelectors} from "../../payment/duck";
import {selectors as websiteSelectors, actions as websiteActions} from "../../website/duck"

import schema from './schema'

import StepControls from "../../step-controls";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiInputLabel-animated': {
            fontSize: '1.2rem'
        },

        '& .MuiInputLabel-animated .MuiFormLabel-asterisk': {
            color: theme.palette.error.dark
        },

    },
    dateLabel: {
        width: '110%'
    },
    fullCheckbox: {
        width: '100%'
    }

}));

const SquareForm = ({squareErrors, disabled, onBack, onSubmit, step, name, promo}) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const application = useSelector(applicationSelectors.applicationItem);
    const appliedCode = useSelector(paymentSelectors.code);
    const websiteRefundPolicy = useSelector(websiteSelectors.refundPolicy);

    const [form, setForm] = useState(null);
    const [errors, setErrors] = useState(null);
    const [initiallySubmitted, setInitiallySubmitted] = useState(false);
    const [valid, setValid] = useState(false);

    useEffect(() => {
        let initialValues = {};
        let initialErrors = {};

        Object.keys(schema).forEach((key, index) => {
            initialValues[key] = {value: schema[key].initialValue || "", name: schema[key].label, index};
            initialErrors[key] = "";
        });

        setForm({
            name: name, values: {
                ...initialValues,
                email: {value: application.personalInfo.email.value, name: "Email"},
                firstName: {value: application.personalInfo.firstName.value, name: "First name"},
                lastName: {value: application.personalInfo.lastName.value, name: "Last name"},
                phoneNumber: {value: application.personalInfo.phoneNumber.value, name: "Phone"},
            }
        });

        setErrors(initialErrors);

        if (!websiteRefundPolicy) {
            dispatch(websiteActions.getWebsiteData({ code: application.website.code }));
        }
    }, []);

    useEffect(() => {
        let err = {};
        let noErrors = true;

        if (form) {
            for (let key in schema) {
                if (schema[key].required === "true" &&
                    ((typeof form.values[key].value === 'string' && form.values[key].value.trim() === "") || form.values[key].value.length === 0)) {
                    err[key] = messages['required'];
                    noErrors = false;
                } else if (schema[key].rules && !rules[schema[key].rules].isValidSync(form.values[key].value)) {
                    noErrors = false;
                    err[key] = messages[schema[key].rules];
                } else {
                    err[key] = "";
                }
            }
            setValid(noErrors);
        }

        if (initiallySubmitted) {
            setErrors({...errors, ...err});
        }
    }, [form, initiallySubmitted]);

    const handleSubmit = (event) => {
        if (!initiallySubmitted) setInitiallySubmitted(true);

        if (valid) {
            onSubmit(form);
        } else {
            window.scrollTo(0, 0);
        }
    };

    const handleChange = (key, value) => {
        setForm({...form, values: {...form.values, [key]: {...form.values[key], value: value}}});
    };

    return (
        <Box classes={classes}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>
                    {websiteRefundPolicy && (
                        <div
                            className={classes.refundPolicy}
                            dangerouslySetInnerHTML={{__html: websiteRefundPolicy}}
                        ></div>
                    )}
                    {form && <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Text onChange={handleChange}
                                  disabled={disabled}
                                  errorMessage={errors['firstName']}
                                  id="firstName"
                                  initialValue={form.values.firstName.value}
                                  label={schema.firstName.label}
                                  placeholder={schema.firstName.placeholder}
                                  required={schema.firstName.required}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Text onChange={handleChange}
                                  disabled={disabled}
                                  errorMessage={errors['lastName']}
                                  id="lastName"
                                  initialValue={form.values.lastName.value}
                                  label={schema.lastName.label}
                                  placeholder={schema.lastName.placeholder}
                                  required={schema.lastName.required}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Text onChange={handleChange}
                                  disabled={disabled}
                                  errorMessage={errors['email']}
                                  id="email"
                                  initialValue={form.values.email.value}
                                  label={schema.email.label}
                                  placeholder={schema.email.placeholder}
                                  required={schema.email.required}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MaskedText onChange={handleChange}
                                        disabled={disabled}
                                        errorMessage={errors['phoneNumber']}
                                        mask={schema.phoneNumber.mask}
                                        id="phoneNumber"
                                        initialValue={form.values.phoneNumber.value}
                                        label={schema.phoneNumber.label}
                                        placeholder={schema.phoneNumber.placeholder}
                                        required={schema.phoneNumber.required}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider variant="fullWidth" className={classes.divider}/>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl error={typeof squareErrors.cardNumber !== 'undefined'} fullWidth className={classes.input}>
                                <InputLabel shrink={true} required={true} htmlFor="sq-card-number">{schema.cardNumber.label}</InputLabel>
                                <div id="sq-card-number"></div>
                                {squareErrors.cardNumber && <FormHelperText error={true}>{squareErrors.cardNumber}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl error={typeof squareErrors.expirationDate !== 'undefined'} fullWidth className={classes.input}>
                                <InputLabel shrink={true} required={true} className={classes.dateLabel} htmlFor="sq-expiration-date">{schema.expirationDate.label}</InputLabel>
                                <div id="sq-expiration-date"></div>
                                {squareErrors.expirationDate && <FormHelperText error={true}>{squareErrors.expirationDate}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl error={typeof squareErrors.cvv !== 'undefined'} fullWidth className={classes.input}>
                                <InputLabel shrink={true} required={true} htmlFor="sq-cvv">{schema.cvv.label}</InputLabel>
                                <div id="sq-cvv"></div>
                                {squareErrors.cvv && <FormHelperText error={true}>{squareErrors.cvv}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl error={typeof squareErrors.postalCode !== 'undefined'} fullWidth className={classes.input}>
                                <InputLabel shrink={true} required={true} htmlFor="sq-postal-code">{schema.zip.label}</InputLabel>
                                <div id="sq-postal-code"></div>
                                {squareErrors.postalCode && <FormHelperText error={true}>{squareErrors.postalCode}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <h3>ELECTRONIC SIGNATURE:</h3>
                            <Checkbox
                                className={classes.fullCheckbox}
                                cols={1}
                                label={schema.electronicSignature.label}
                                options={schema.electronicSignature.options}
                                initialValue={form.values.electronicSignature.value}
                                onChange={handleChange}
                                disabled={disabled}
                                errorMessage={errors['electronicSignature']}
                                id="electronicSignature"
                                required={schema.electronicSignature.required}
                            />
                        </Grid>
                    </Grid>}
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Order application={application} appliedCode={appliedCode}/>
                    <br/><br/>
                    {promo && <Promo disabled={disabled}/>}
                </Grid>
            </Grid>

            <StepControls step={step} disabled={disabled} onBack={onBack} onNext={handleSubmit}/>
        </Box>
    );
};

SquareForm.propTypes = {

};

export default SquareForm;