import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CircularProgress, TextField} from '@material-ui/core';
import PropTypes from 'prop-types';

import useStyles from './styles'

import {actions as paymentActions, selectors as paymentSelectors} from '../../duck';
import {selectors as applicationSelectors} from '../../../application/duck';

const ERROR_MESSAGE = 'Promo does not exist';
const SUCCESSFUL_MESSAGE = 'Promo code applied successfully';

const Promo = ({ disabled, clientSecret, value, setValue, onPromoProcessing }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const appliedCode = useSelector(paymentSelectors.code);
    const codeErrors = useSelector(paymentSelectors.codeErrors);
    const applicationUuid = useSelector(applicationSelectors.applicationId);

    const [isCodeApplied, setIsCodeApplied] = useState(false)
    const [promocode, setPromocode] = useState(localStorage.getItem('PROMOCODE'));
    const [checking, setChecking] = useState(false);

    useEffect(() => {
        if (!promocode) return;

        if (clientSecret) {
            handleCodeSubmit();
        }
    }, [clientSecret]);

    useEffect(() => {
        if (onPromoProcessing) {
            onPromoProcessing(checking);
        }
    }, [checking]);

    useEffect(() => {
        setChecking(false);
        setIsCodeApplied(appliedCode && (appliedCode.code === value))
    }, [appliedCode]);

    useEffect(() => {
        setChecking(false);
    }, [codeErrors]);

    const handleCodeChange = ({ target }) => {
        setPromocode(target.value);

        if (setValue) setValue(target.value)
    };

    const handleCodeSubmit = () => {
        setChecking(true);
        dispatch(paymentActions.checkCode({
            clientSecret: clientSecret ?? undefined,
            promoCode: promocode,
            applicationUuid: applicationUuid,
        }));
    };

    return (
        <div className={classes.container}>
            <div className={'leftBlock'}>
                <TextField
                    fullWidth
                    onChange={handleCodeChange}
                    value={promocode}
                    placeholder="Have promocode?"
                    id="code-input"
                    variant="outlined"
                    color="secondary"
                    disabled={disabled}
                    error={!!codeErrors?.length}
                />
                {(!!codeErrors?.length) && <div className={'errorText'}>
                    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 8L5.5 13.5L11.5 2" stroke="#FF0000" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    &nbsp;
                    {ERROR_MESSAGE}
                </div>}
                {isCodeApplied && <div className={'successfulText'}>
                    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 8L5.5 13.5L11.5 2" stroke="#1AAC0D" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    &nbsp;
                    {SUCCESSFUL_MESSAGE}
                </div>}
            </div>
            <div className={'rightBlock'}>
                {(isCodeApplied || (!!codeErrors?.length)) ?
                    <button
                        onClick={handleCodeSubmit}
                        variant="outlined"
                    >
                        APPLY
                    </button> :
                    <button
                        onClick={handleCodeSubmit}
                        disabled={checking || disabled || !promocode}
                        variant="outlined"
                    >
                        {checking ? <CircularProgress color="inherit" size={24}/> : 'APPLY'}

                    </button>
                }
            </div>
        </div>
    );
};

Promo.propTypes = {
    disabled: PropTypes.bool.isRequired,
    clientSecret: PropTypes.string,
    onPromoProcessing: PropTypes.func,
};

export default Promo;
