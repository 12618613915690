/*global dataLayer*/

class Analytics {
    sendEvent(params) {
        console.log('==== SEND ANALYTIC BEGIN ====');
        console.log(params);
        console.log('==== SEND ANALYTIC END ====');
        if (!['production'].includes(process.env.REACT_APP_ENV)) {
            return;
        }

        if (!params) {
            return;
        }

        window.dataLayer = window.dataLayer || [];
        dataLayer.push(params);
    };

    sendCustomEvent(category, action, label) {
        if (!category || !action || !label) {
            return;
        }

        this.sendEvent({
            'event': 'custom-event',
            'ee-event-category': category,
            'ee-event-action': action,
            'ee-event-label': label,
        });
    };
}

export default new Analytics();
