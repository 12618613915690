import React from 'react'
import {Alert, AlertTitle} from '@material-ui/lab'

const CommonError = () => (
    <Alert severity="error">
        <AlertTitle>Payment error. </AlertTitle>
        Please check your card details.
    </Alert>
);

export default CommonError
