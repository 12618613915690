import {createActions, handleActions} from 'redux-actions';

const {
    getSchedule,
    scheduleReset,
    getScheduleSuccess,
    getScheduleFail,
    scheduleResetSuccess,
} = createActions(
    'GET_SCHEDULE',
    'SCHEDULE_RESET',
    'GET_SCHEDULE_SUCCESS',
    'GET_SCHEDULE_FAIL',
    'SCHEDULE_RESET_SUCCESS',
);

const defaultState = {
    schedule: null,
    errors: []
};

export const reducer = handleActions(
    {
        [getSchedule]: (state) => ({
            ...state,
            errors: []
        }),
        [scheduleReset]: (state) => ({
            ...state,
        }),
        [getScheduleSuccess]: (state, action) => ({
            ...state,
            schedule: action.payload.schedule,
        }),
        [getScheduleFail]: (state, action) => ({
            ...state,
            errors: [action.payload],
        }),
        [scheduleResetSuccess]: (state) => ({
            ...state,
            schedule: null,
            errors: [],
        }),
    },
    defaultState
);

export const actions = {
    getSchedule,
    scheduleReset,
    getScheduleSuccess,
    getScheduleFail,
    scheduleResetSuccess
};
