import React from 'react';
import PropTypes from 'prop-types';

import {Divider, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Paper,} from '@material-ui/core';

import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import WatchLaterIcon from '@material-ui/icons/WatchLater';

import moment from "moment";

const minAmount = 0.50;

const Order = ({application, appliedCode}) => {
    const calculatePrice = (price, code) => {
        let amount;
        if (code.type === 1) {
            amount = price - code.rate;
        } else if (code.type === 0){
            const priceInCents = price * 100;
            const priceWithPromo = Math.round(priceInCents - (code.rate / 100 * priceInCents))
            amount = priceWithPromo / 100;
        }

        return amount >= minAmount ? parseFloat(amount).toFixed(2) : minAmount;
    };

    return (
        <Paper>
            <List component="nav" subheader={
                <ListSubheader disableSticky component="div" id="nested-list-subheader">
                    Your order
                </ListSubheader>}>
                <ListItem>
                    <ListItemIcon>
                        <LocalHospitalIcon color="secondary"/>
                    </ListItemIcon>
                    <ListItemText primary="Service:" secondary={application.service.name}/>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <MonetizationOnIcon color="secondary"/>
                    </ListItemIcon>
                    <ListItemText primary="Plan:" secondary={application.plan.title}/>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <WatchLaterIcon color="secondary"/>
                    </ListItemIcon>
                    <ListItemText primary="Time:"
                                  secondary={moment(application.date).format('MM/DD/YYYY h:mm A')}/>
                </ListItem>
            </List>
            <Divider/>
            <List component="nav" aria-label="secondary mailbox folders">
                {appliedCode?.code &&
                    <ListItem>
                        <ListItemText
                            primary={'Original Price: $' + application.plan.price}/>
                    </ListItem> }
                {appliedCode?.code &&
                    <ListItem>
                        { appliedCode.type === 1 &&
                            <ListItemText
                                primary={'Coupon discount ('+ appliedCode.code +'): -$' + appliedCode.rate}/> }
                        { appliedCode.type === 0 &&
                            <ListItemText
                                primary={'Coupon discount ('+ appliedCode.code +'): -' + appliedCode.rate + '%'}/> }
                    </ListItem> }
                <ListItem>
                        <ListItemText primaryTypographyProps={{ style: {fontWeight: "bold"} }}
                                      primary={'Total Amount: $' + (appliedCode?.code ? calculatePrice(application.plan.price, appliedCode) : application.plan.price)}/>
                </ListItem>
            </List>
        </Paper>
    );
};

Order.propTypes = {
    application: PropTypes.object.isRequired,
    appliedCode: PropTypes.any
};

export default Order;
