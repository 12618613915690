import Axios from 'axios';
import moment from "moment-timezone"

const api = Axios.create({
    baseURL: process.env.REACT_APP_PUBLIC_API_URL + '/schedule',
});

const getSchedule = async ({serviceCode, stateCode, servicePlanId, expandDay, leadId}) => {
    return await api.post('/', {serviceCode, stateCode, servicePlanId, expandDay, timezone: moment.tz.guess(), leadId}, {
        headers: {
            'DctrApp-Api-PrivateToken': 'fee18378d1cf15c5c51f0c247eb06763'
        }
    })
};

export const operations = {
    getSchedule
};
