import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    confirmText: {
        fontSize: '14px',
        lineHeight: '130%',
        letterSpacing: '0.15px',
        color: '#222222',
        margin: 0
    },
    termsUrl: {
        color: theme.palette.primary.main,
        fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`
    }
}));

export default useStyles;
