import {combineActions, createActions, handleActions} from 'redux-actions';

const {
    uploadFile,
    uploadSignatureFile,
    uploadFileSuccess,
    uploadSignatureFileSuccess,
    uploadFileFail,
    uploadSignatureFileFail,
    clearSignature,
    clearErrors
} = createActions(
    'UPLOAD_FILE',
    'UPLOAD_SIGNATURE_FILE',
    'UPLOAD_FILE_SUCCESS',
    'UPLOAD_SIGNATURE_FILE_SUCCESS',
    'UPLOAD_FILE_FAIL',
    'UPLOAD_SIGNATURE_FILE_FAIL',
    'CLEAR_SIGNATURE',
    'CLEAR_ERRORS'
);

const initialState = {
    uploading: false,
    fileUrl: null,
    signatureUrl: null,
    errors: [],
};

export const reducer = handleActions(
    {
        [combineActions(uploadFile, uploadSignatureFile)]: state => ({
            ...state,
            errors: [],
            uploading: true,
        }),
        [uploadFileSuccess]: (state, action) => ({
            ...state,
            fileUrl: action.payload.data.data,
            uploading: false,
        }),
        [uploadSignatureFileSuccess]: (state, action) => ({
            ...state,
            signatureUrl: action.payload.data.data,
            uploading: false,
        }),
        [clearSignature]: (state) => ({
            ...state,
            signatureUrl: null
        }),
        [combineActions(uploadFileFail, uploadSignatureFileFail)]: (state, action) => ({
            ...state,
            errors: action.payload,
            uploading: false,
        }),
        [clearErrors]: (state) => ({
            ...state,
            errors: []
        })

    },
    initialState
);

export const actions = {
    uploadFile,
    uploadSignatureFile,
    uploadFileSuccess,
    uploadSignatureFileSuccess,
    uploadFileFail,
    uploadSignatureFileFail,
    clearSignature,
    clearErrors
};
