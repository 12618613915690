import React, {useEffect, useRef, useState} from 'react';
import {Checkbox, MaskedText, Text} from "../../../componets/fields";
import {Box, Divider, Grid,} from '@material-ui/core';

import Promo from '../promo';
import Order from '../order';

import {useDispatch, useSelector} from "react-redux";

import * as rules from '../../../constants/rules';
import * as messages from '../../../constants/messages';

import {selectors as applicationSelectors} from "../../application/duck";
import {actions as paymentActions, selectors as paymentSelectors} from "../../payment/duck";
import {actions as websiteActions, selectors as websiteSelectors} from "../../website/duck"

import schema from './schema'

import StepControls from "../../step-controls";
import {makeStyles} from "@material-ui/core/styles";

import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {PromocodeDialog} from "../promocode-dialog/PromocodeDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiInputLabel-animated': {
            fontSize: '1.2rem'
        },

        '& .MuiInputLabel-animated .MuiFormLabel-asterisk': {
            color: theme.palette.error.dark
        },
    },
    fullCheckbox: {
        width: '100%'
    },
}));

const StripeForm = ({disabled, onBack, onSubmit, step, name, promo}) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const application = useSelector(applicationSelectors.applicationItem);
    const applicationUuid = useSelector(applicationSelectors.applicationId);
    const appliedCode = useSelector(paymentSelectors.code);
    const websiteRefundPolicy = useSelector(websiteSelectors.refundPolicy);
    const paymentIntent = useSelector(paymentSelectors.paymentIntent);
    const paymentLoaded = useSelector(paymentSelectors.paymentLoaded);
    const clientSecret = useSelector(paymentSelectors.clientSecret)

    const stripe = useStripe();
    const elements = useElements();

    const [promoCode, setPromoCode] = useState('');
    const [form, setForm] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [errors, setErrors] = useState(null);
    const [initiallySubmitted, setInitiallySubmitted] = useState(false);
    const [valid, setValid] = useState(false);
    const [completeStripeForm, setCompleteStripeForm] = useState(false);
    const [isPromoCodeModalShowing, setIsPromoCodeModalShowing] = useState(false);

    useEffect(() => {
        let initialValues = {};
        let initialErrors = {};

        Object.keys(schema).forEach((key, index) => {
            initialValues[key] = {value: schema[key].initialValue || "", name: schema[key].label, index};
            initialErrors[key] = "";
        });

        setForm({
            name: name, values: {
                ...initialValues,
                email: {value: application.personalInfo.email.value, name: "Email"},
                firstName: {value: application.personalInfo.firstName.value, name: "First name"},
                lastName: {value: application.personalInfo.lastName.value, name: "Last name"},
                phoneNumber: {value: application.personalInfo.phoneNumber.value, name: "Phone"},
            }
        });

        setErrors(initialErrors);

        if (!websiteRefundPolicy) {
            dispatch(websiteActions.getWebsiteData({ code: application.website.code }));
        }
    }, []);

    useEffect(() => {
        let err = {};
        let noErrors = true;

        if (form) {
            for (let key in schema) {
                if (schema[key].required === "true" &&
                    ((typeof form.values[key].value === 'string' && form.values[key].value.trim() === "") || form.values[key].value.length === 0)) {
                    err[key] = messages['required'];
                    noErrors = false;
                } else if (schema[key].rules && !rules[schema[key].rules].isValidSync(form.values[key].value)) {
                    noErrors = false;
                    err[key] = messages[schema[key].rules];
                } else {
                    err[key] = "";
                }
            }
            setValid(noErrors);
        }

        if (initiallySubmitted) {
            setErrors({...errors, ...err});
        }
    }, [form, initiallySubmitted]);

    useEffect(() => {
        if (paymentIntent?.status === 'succeeded') {
            onSubmit(form);
            localStorage.removeItem('PROMOCODE');
        }
    }, [paymentIntent]);

    useEffect(() => {
        if (!formSubmitted) return;

        const isCodeApplied = !!appliedCode && (appliedCode.code === promoCode)

        if (!isCodeApplied) {
            setIsPromoCodeModalShowing(true)
            return
        }

        confirmPayment()
    }, [appliedCode])

    const handleSubmit = async (event) => {
        if (!initiallySubmitted) setInitiallySubmitted(true);

        setFormSubmitted(false);

        if (valid) {
            if (!stripe || !elements) return;
            setFormSubmitted(true);
            (promo && promoCode) ? checkPromo() : confirmPayment()
        } else {
            window.scrollTo(0, 0);
        }
    };

    const confirmPayment = () => {
        return dispatch(paymentActions.confirmPayment({stripe, elements}));
    }

    const checkPromo = () => {
        return dispatch(paymentActions.checkCode({
            clientSecret: clientSecret ?? undefined,
            promoCode: promoCode,
            applicationUuid: applicationUuid,
        }));
    };

    const onChangeStripeForm = (event) => {
        setCompleteStripeForm(event?.complete || false);
    };

    const handleChange = (key, value) => {
        setForm({...form, values: {...form.values, [key]: {...form.values[key], value: value}}});
    };

    return (
        <Box classes={classes}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>
                    { websiteRefundPolicy && (
                        <div
                            className={classes.refundPolicy}
                            dangerouslySetInnerHTML={{__html: websiteRefundPolicy}}
                        ></div>
                    )}
                    {form && <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Text onChange={handleChange}
                                  disabled={disabled}
                                  errorMessage={errors['firstName']}
                                  id="firstName"
                                  initialValue={form.values.firstName.value}
                                  label={schema.firstName.label}
                                  placeholder={schema.firstName.placeholder}
                                  required={schema.firstName.required}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Text onChange={handleChange}
                                  disabled={disabled}
                                  errorMessage={errors['lastName']}
                                  id="lastName"
                                  initialValue={form.values.lastName.value}
                                  label={schema.lastName.label}
                                  placeholder={schema.lastName.placeholder}
                                  required={schema.lastName.required}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Text onChange={handleChange}
                                  disabled={disabled}
                                  errorMessage={errors['email']}
                                  id="email"
                                  initialValue={form.values.email.value}
                                  label={schema.email.label}
                                  placeholder={schema.email.placeholder}
                                  required={schema.email.required}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MaskedText onChange={handleChange}
                                        disabled={disabled}
                                        errorMessage={errors['phoneNumber']}
                                        mask={schema.phoneNumber.mask}
                                        id="phoneNumber"
                                        initialValue={form.values.phoneNumber.value}
                                        label={schema.phoneNumber.label}
                                        placeholder={schema.phoneNumber.placeholder}
                                        required={schema.phoneNumber.required}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider variant="fullWidth" className={classes.divider}/>
                        </Grid>
                        <Grid item xs={12}>
                            <PaymentElement onChange={onChangeStripeForm} />
                        </Grid>
                        <Grid item xs={12}>
                            <h3>ELECTRONIC SIGNATURE:</h3>
                            <Checkbox
                                className={classes.fullCheckbox}
                                cols={1}
                                label={schema.electronicSignature.label}
                                options={schema.electronicSignature.options}
                                initialValue={form.values.electronicSignature.value}
                                onChange={handleChange}
                                disabled={disabled}
                                errorMessage={errors['electronicSignature']}
                                id="electronicSignature"
                                required={schema.electronicSignature.required}
                            />
                        </Grid>
                    </Grid>}
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Order application={application} appliedCode={appliedCode}/>
                    <br/><br/>
                    {
                        promo
                        && <Promo
                            value={promoCode}
                            setValue={setPromoCode}
                            disabled={disabled}
                        />
                    }
                </Grid>
            </Grid>
            <PromocodeDialog
                isShown={isPromoCodeModalShowing}
                setIsShown={(res) => {
                    setIsPromoCodeModalShowing(res);
                    setFormSubmitted(false);
                }}
                continueWithoutPromo={confirmPayment}
            />
            <StepControls step={step} disabled={disabled || paymentLoaded} blockButtonNext={!completeStripeForm} onBack={onBack} onNext={handleSubmit}/>
        </Box>
    );
};

StripeForm.propTypes = {

};

export default StripeForm;
